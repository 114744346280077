import { Switch, Input, Button, Alert } from 'antd'
import { observer } from 'mobx-react'
import React, { useRef, useEffect, useCallback, useState } from 'react'
import RenderOnQueries from '../../Pages/Layout/RenderOnQueries'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import TaskCollection from '../../State/Collections/TaskCollection'
import SessionStore from '../../State/SessionStore'
import {
    camelCaseToSentence,
    numberTimeStringToMinutes,
    numMinutesToTimeString,
} from '../../utils'
import { HoursInput } from '../../widgets'
import calendarState from './CalendarState'
import BudgetProgressBar from '../BudgetProgressBar'
import { endOfDay, endOfMonth, format, startOfMonth, subDays } from 'date-fns'
import RoleCollection from '../../State/Collections/RoleCollection'
import StaffCollection from '../../State/Collections/StaffCollection'
import {
    canEnterTimeAgainstPhase,
    canEnterTimeAgainstProject,
    canLogTimeAgainstTasks,
    canUseTimeEntryFlag,
    canViewFlexiRemote,
} from '../../State/Permissions/HasPermissions'
import { qf } from '../../Queries/queryFormatter'
import { queryClient } from '../../App'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import _ from 'lodash'
import sortPhases from '../../Utils/sortPhases'
import { PhaseSelector, ProjectSelector, TaskSelector } from '../Selector'

const { TextArea } = Input
export const budgetOptions = [
    'phase',
    'role',
    'staff',
    'monthlyPhase',
    'monthlyRole',
    'monthlyStaff',
]
export const budgetNames = {
    phase: 'Phase Budget for Team',
    role: 'Phase Budget for Role',
    staff: 'Phase Budget for Staff Member',
    monthlyPhase: 'Monthly Budget for Team',
    monthlyRole: 'Monthly Budget for Role',
    monthlyStaff: 'Monthly Budget for Staff Member',
}

export const CalendarSidebar = observer(() => {
    const settings = SessionStore.organisation.settings
    const disabled =
        calendarState.selectedItem &&
        !calendarState.selectedItem?.modelType
            .toLowerCase()
            .includes(calendarState.mode)
    if (!calendarState.selectedValues.projectId) return <div></div>
    return (
        <div
            className="p-4 flex flex-col h-full"
            key={calendarState.selectedValues}
        >
            {!disabled && calendarState.selectedItem && (
                <Alert message="Editing selected item." type="info" showIcon />
            )}
            <div className="py-6">
                <RenderOnQueries
                    queryIds={[
                        calendarState.selectedValues.projectId
                            ? {
                                  collection: 'phases',
                                  fields: [
                                      'jobNumber',
                                      'name',
                                      'projectId',
                                      'status',
                                      'isRootPhase',
                                  ],
                                  filters: [
                                      `projectId == "${calendarState.selectedValues.projectId}"`,
                                  ],
                              }
                            : null,
                    ].filter((v) => v)}
                >
                    <BudgetProgressBar
                        dropdownClassName="!text-xs"
                        key={
                            String(calendarState.selectedValues.projectId) +
                            String(calendarState.selectedValues.phaseId)
                        }
                        project={
                            ProjectCollection.projectsById[
                                calendarState.selectedValues.projectId
                            ]
                        }
                        phase={
                            PhaseCollection.phasesById[
                                calendarState.selectedValues.phaseId
                            ]
                        }
                        role={
                            RoleCollection.rolesById[
                                calendarState.selectedValues.roleId
                            ]
                        }
                        staff={
                            StaffCollection.staffsById[
                                calendarState.selectedValues.staffId
                            ]
                        }
                        startDate={startOfMonth(new Date())}
                        endDate={endOfDay(
                            subDays(
                                calendarState.selectedItem?.date || new Date(),
                                1
                            )
                        )}
                        dropdownAbove={true}
                        extraHours={_.sum(
                            (TimeEntryCollection.timeEntries || [])
                                .filter(
                                    (te) =>
                                        te.staffId ===
                                            calendarState.selectedValues
                                                .staffId &&
                                        te.phaseId ===
                                            calendarState.selectedValues
                                                .phaseId &&
                                        te.date.getTime() ===
                                            (
                                                calendarState.selectedItem
                                                    ?.date || new Date()
                                            ).getTime() &&
                                        !te.deletedAt
                                )
                                .map((te) => te.hours)
                        )}
                    />
                </RenderOnQueries>
            </div>
            <div className="flex flex-col gap-1">
                <ProjectSelector
                    selectedProject={
                        ProjectCollection.projectsById[
                            calendarState.selectedValues.projectId
                        ]
                    }
                    projectOptions={ProjectCollection.projects.filter((p) =>
                        canEnterTimeAgainstProject(SessionStore.user, p)
                    )}
                    onChange={(project) => {
                        const projectId = project?.id
                        queryClient.invalidateQueries([`phases-${projectId}`])
                        calendarState.updateSelectedValues({
                            projectId,
                        })
                    }}
                    variant="secondary"
                    style={{ width: '100%' }}
                />
                <RenderOnQueries
                    queryIds={[
                        calendarState.selectedValues.projectId
                            ? {
                                  id: `phases-${calendarState.selectedValues.projectId}`,
                                  collection: 'phases',
                                  fields: [
                                      'jobNumber',
                                      'name',
                                      'projectId',
                                      'status',
                                      'startDate',
                                      'endDate',
                                      'isRootPhase',
                                      [
                                          'budgetedStaffIds',
                                          'budgetedHours.staffIds',
                                      ],
                                      [
                                          'monthlyAllocatedStaffIds',
                                          'monthlyAllocations.staffIds',
                                      ],
                                  ],
                                  filters: [
                                      `projectId == "${calendarState.selectedValues.projectId}"`,
                                  ],
                                  subQueries: [
                                      {
                                          collection: 'budgetedHours',
                                          join: `id == budgetedHours.phaseId`,
                                          groupBy: ['phaseId'],
                                          fields: [
                                              ['staffIds', 'uniq(staffId)'],
                                          ],
                                          filters: [`staffId != null`],
                                      },
                                      {
                                          collection: 'monthlyAllocations',
                                          join: `id == monthlyAllocations.phaseId`,
                                          groupBy: ['phaseId'],
                                          fields: [
                                              ['staffIds', 'uniq(staffId)'],
                                          ],
                                          filters: [
                                              `staffId != null`,
                                              `month >= ${qf(
                                                  format(new Date(), 'yyyy-MM')
                                              )}`,
                                              `month <= ${qf(
                                                  format(new Date(), 'yyyy-MM')
                                              )}`,
                                          ],
                                      },
                                  ],
                              }
                            : null,
                        calendarState.selectedValues.projectId
                            ? {
                                  collection: 'tasks',
                                  test: 'test',
                                  fields: [
                                      'name',
                                      'projectId',
                                      'phaseId',
                                      'isDefault',
                                      'isBillable',
                                      'isVariation',
                                  ],
                                  filters: [
                                      `projectId == "${calendarState.selectedValues.projectId}"`,
                                  ],
                              }
                            : null,
                    ].filter((v) => v)}
                >
                    <PhaseAndTaskSelectors
                        setting={settings}
                        disabled={disabled}
                    />
                </RenderOnQueries>
            </div>
            <div className="flex justify-around text-center my-8 mx-0 text-sm text-[#666]">
                {canUseTimeEntryFlag('billable') ? (
                    <div className="flex flex-col">
                        <Switch
                            checked={calendarState.selectedValues.isBillable}
                            onChange={(isBillable) => {
                                calendarState.updateSelectedValues({
                                    isBillable,
                                })
                            }}
                            disabled={disabled}
                        />
                        <div>Billable</div>
                    </div>
                ) : null}
                {canUseTimeEntryFlag('variation') && (
                    <div className="flex flex-col">
                        <Switch
                            checked={calendarState.selectedValues.isVariation}
                            onChange={(isVariation) => {
                                calendarState.updateSelectedValues({
                                    isVariation,
                                })
                            }}
                            disabled={disabled}
                        />
                        <div>Variation</div>
                    </div>
                )}
                {canUseTimeEntryFlag('overtime') ? (
                    <div className="flex flex-col">
                        <Switch
                            checked={calendarState.selectedValues.isOvertime}
                            onChange={(isOvertime) => {
                                calendarState.updateSelectedValues({
                                    isOvertime,
                                })
                            }}
                            disabled={disabled}
                        />
                        <div>Overtime</div>
                    </div>
                ) : null}
                {canUseTimeEntryFlag('remote')
                    ? settings.timeEntryFlags.includes('remote') && (
                          <div>
                              <Switch
                                  checked={calendarState.selectedValues.remote}
                                  onChange={(remote) => {
                                      calendarState.updateSelectedValues({
                                          remote,
                                      })
                                  }}
                                  disabled={disabled}
                              />
                              <div>Remote</div>
                          </div>
                      )
                    : null}
                {canUseTimeEntryFlag('flexi')
                    ? settings.timeEntryFlags.includes('flexi') && (
                          <div>
                              <Switch
                                  checked={calendarState.selectedValues.flexi}
                                  onChange={(flexi) => {
                                      calendarState.updateSelectedValues({
                                          flexi,
                                      })
                                  }}
                                  disabled={disabled}
                              />
                              <div>Flexi</div>
                          </div>
                      )
                    : null}
            </div>
            {calendarState.selectedItem ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <div
                            type="text"
                            style={{
                                flex: '1 1 auto',
                                width: '70%',
                                margin: '0.25em',
                                padding: '0.25em',
                                textAlign: 'right',
                            }}
                        >
                            Hours:
                        </div>
                        <HoursInput
                            style={{
                                flex: '1 1 auto',
                                width: '30%',
                                margin: '0.25em',
                                textAlign: 'right',
                            }}
                            className="form-control input-lg login-widget__email-input input-base w-full focus:outline-none hover:border-[#618fb0]"
                            value={calendarState.selectedItem.numMinutes}
                            onChange={(numMins) =>
                                calendarState.editSelectedItem({
                                    numMinutes: numMins,
                                })
                            }
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <TimeInput
                            style={{
                                flex: '1 1 auto',
                                width: '50%',
                                margin: '0.25em',
                                textAlign: 'right',
                            }}
                            className="form-control input-lg login-widget__email-input input-base w-full focus:outline-none hover:border-[#618fb0]"
                            numMins={calendarState.selectedItem.startMinutes}
                            onChange={(mins) =>
                                calendarState.editSelectedItem({
                                    startMinutes: mins,
                                })
                            }
                        />
                        <TimeInput
                            style={{
                                flex: '1 1 auto',
                                width: '50%',
                                margin: '0.25em',
                                textAlign: 'right',
                            }}
                            className="form-control input-lg login-widget__email-input input-base w-fullfocus:outline-none hover:border-[#618fb0]"
                            numMins={calendarState.selectedItem.endMinutes}
                            onChange={(mins) =>
                                calendarState.editSelectedItem({
                                    numMinutes:
                                        mins -
                                        calendarState.selectedItem.startMinutes,
                                })
                            }
                        />
                    </div>
                </>
            ) : null}
            <TextArea
                value={calendarState.selectedValues.notes}
                onChange={(e) => {
                    calendarState.updateSelectedValues({
                        notes: e.target.value,
                    })
                }}
                className="text-sm"
                placeholder="Enter some notes..."
                autoSize={{ minRows: 5 }}
                allowClear
                disabled={disabled}
            />
            {!disabled && calendarState.selectedItem && (
                <Button
                    danger
                    type="primary"
                    style={{ marginTop: '2rem', width: '100%' }}
                    disabled={calendarState.deletingSelectedItem}
                    onClick={calendarState.deleteSelectedItem}
                >
                    {calendarState.deletingSelectedItem
                        ? `Deleting in ${calendarState.deleteCountdown}...`
                        : 'Delete'}
                </Button>
            )}
            {calendarState.deletingSelectedItem && (
                <Button
                    danger
                    style={{ marginTop: '1rem', width: '100%' }}
                    onClick={calendarState.undoDeleteSelectedItem}
                >
                    Undo
                </Button>
            )}
        </div>
    )
})

export const PhaseAndTaskSelectors = ({
    store = calendarState,
    disabled = false,
}) => {
    const projectId = store.selectedValues.projectId
    const project = ProjectCollection.projectsById[projectId]
    const phaseId = store.selectedValues.phaseId
    const phase =
        PhaseCollection[phaseId] ||
        (PhaseCollection.phasesByProjectId[projectId] || [])
            ?.sort(sortPhases)
            .filter((p) => canEnterTimeAgainstPhase(SessionStore.user, p))[0]
    const tasks = TaskCollection.tasksByPhaseId[phaseId] || []
    useEffect(() => {
        if (phase && !store.selectedValues.phaseId) {
            store.updateSelectedValues({
                phaseId: phase?.id,
            })
        }
        if (phase && !store.selectedValues.taskId) {
            store.updateSelectedValues({
                taskId: phase?.defaultTask?.id || phase?.tasks?.[0]?.id,
            })
        }
    }, [projectId, phase])
    return (
        <>
            <PhaseSelector
                key={`phase-selector-${store.selectedValues.phaseId || 'null'}`}
                selectedPhase={
                    PhaseCollection.phasesById[store.selectedValues.phaseId]
                }
                onChange={(phase) => {
                    const phaseId = phase?.id
                    store.updateSelectedValues({ phaseId })
                }}
                phaseOptions={
                    project?.phases?.filter((ph) =>
                        canEnterTimeAgainstPhase(SessionStore.user, ph)
                    ) || []
                }
                disabled={disabled}
                variant="secondary"
                style={{ width: '100%' }}
            />
            {canLogTimeAgainstTasks() ? (
                <TaskSelector
                    key={`task-selector-${
                        store.selectedValues.taskId || 'null'
                    }`}
                    selectedTask={
                        TaskCollection.tasksById[store.selectedValues.taskId]
                    }
                    onChange={(task) => {
                        const taskId = task?.id
                        store.updateSelectedValues({ taskId })
                    }}
                    taskOptions={tasks}
                    disabled={disabled}
                    variant="secondary"
                    style={{ width: '100%' }}
                />
            ) : null}
        </>
    )
}
export const TimeInput = observer(
    ({ numMins, style, onChange, disabled, ...props }) => {
        const [val, setVal] = useState(numMinutesToTimeString(numMins))
        useEffect(() => {
            setVal(numMinutesToTimeString(numMins))
        }, [numMins])
        return (
            <input
                type="text"
                style={style}
                value={val || ''}
                onChange={(e) => setVal(e.target.value)}
                onBlur={(e) =>
                    onChange(numberTimeStringToMinutes(e.target.value))
                }
                disabled={disabled}
                {...props}
            />
        )
    }
)
