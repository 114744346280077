import { createFileRoute } from '@tanstack/react-router'
import ProjectListPage from '../../Pages/ProjectListPage/ProjectListPage'
import ProjectListPageHeader from '../../Pages/ProjectListPage/ProjectListPageHeader'
import PageBody from '../../Pages/Layout/PageBody'
import SessionStore from '../../State/SessionStore'
import fetchData from '../../Queries/fetchData'
import { qf } from '../../Queries/queryFormatter'
import {
    canViewRoute,
    migratedToV2,
} from '../../State/Permissions/HasPermissions'
import PermissionDenied from '../../Pages/PermissionDenied'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import { ProjectReportTable } from '../../version2/pages/project-report/project-report-table'

export const Route = createFileRoute('/_private/projects/')({
    beforeLoad: async ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'projectList', params))
            throw new Error('Permission Denied')
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loader: async ({ location }) => await loader(location.search),
    loaderDeps: ({ search: { report } }) => ({
        report,
    }),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
})

const loader = async ({ report }) => {
    const reportId = report || SessionStore.organisation?.defaultProjectReportId
    const collections = [
        reportId
            ? {
                  collection: 'reports',
                  fields: [
                      'name',
                      'type',
                      'columns',
                      'filters',
                      'groupBy',
                      'sortBy',
                      'options',
                  ],
                  filters: [`id == ${qf(reportId)}`],
              }
            : null,
        {
            collection: 'reports',
            fields: ['name', 'type'],
        },
        {
            collection: 'contacts',
            fields: ['firstName', 'lastName', 'organisationName'],
        },
    ].filter(Boolean)
    return await Promise.all(collections.map(fetchData))
}

function Wrapper() {
    if (migratedToV2()) {
        return (
            <div>
                <div className="mb-16">
                    <ProjectListPage />
                </div>
                <div>
                    <ProjectReportTable />
                </div>
            </div>
        )
    }
    return (
        <>
            <ProjectListPageHeader
                {...Route.useParams()}
                {...Route.useSearch()}
            />
            <PageBody>
                <ProjectListPage
                    {...Route.useParams()}
                    {...Route.useSearch()}
                />
            </PageBody>
        </>
    )
}
