import React, { useState, useMemo, useEffect } from 'react'
import ContactCollection from '../State/Collections/ContactCollection'
import CostCentreCollection from '../State/Collections/CostCentreCollection'
import StaffCollection from '../State/Collections/StaffCollection'
import _ from 'lodash'
import PhaseCollection from '../State/Collections/PhaseCollection'
import RoleCollection from '../State/Collections/RoleCollection'
import { capitalCase } from 'change-case'
import ProjectCollection from '../State/Collections/ProjectCollection'
import { dateStringLabels, dateStringLookup } from './Filters'
import TaskCollection from '../State/Collections/TaskCollection'
import Inputs from './Inputs'
import { camelCaseToSentence } from '../utils'
import { Check, ChevronsUpDown } from 'lucide-react'
import { Button } from './ui/Button'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from './ui/Command'
import { Popover, PopoverContent, PopoverTrigger } from './ui/Popover'
import { format } from 'date-fns'
import DropdownButton from 'antd/lib/dropdown/dropdown-button'
import { Dropdown3, Dropdown3ListItem } from '../widgets'
import LayoutStore from '../State/LayoutStore'
import SupplierCollection from '../State/Collections/SupplierCollection'
import ProjectExpenseCollection from '../State/Collections/ProjectExpenseCollection'
import HeaderButton from './ui/HeaderButton'

export const Selector = ({
    selectedOption,
    value,
    options,
    onChange,
    disableOption,
    searchMatcher,
    optionLabel = (o) => o?.label || o,
    groupBy,
    sort,
    placeholder,
    isEditable = true,
    chevrons = true,
    style,
    ...props
}) => {
    const [open, setOpen] = useState(false)
    const [filter, setFilter] = useState('')
    selectedOption ??= value
    if (
        options?.[0]?.value &&
        options.find((o) => o?.value === selectedOption)
    ) {
        selectedOption = options.find((o) => o?.value === selectedOption)
    }

    if (sort) {
        options = options.sort((a, b) => {
            const keyA = sort(a)
            const keyB = sort(b)
            return String(keyA)?.localeCompare?.(String(keyB))
        })
    }

    if (searchMatcher && filter.length > 0) {
        options = options.filter((option) => {
            return searchMatcher(option, filter)
        })
    }
    // Group options and then sort items within each group
    const groupedAndSortedOptions = useMemo(() => {
        if (!groupBy) return null
        const groups = options.reduce((acc, option) => {
            const groupKey = groupBy(option)
            if (!acc[groupKey]) acc[groupKey] = []
            acc[groupKey].push(option)
            return acc
        }, {})
        return groups
    }, [options, groupBy])
    const oldOptionLabel = optionLabel
    optionLabel = (o) => {
        const label = oldOptionLabel(o)
        return label?.replace ? label.replace(/["\\]/g, '') : label
    }

    return (
        <Popover open={open} onOpenChange={isEditable ? setOpen : () => null}>
            <PopoverTrigger asChild>
                <HeaderButton
                    role="combobox"
                    aria-expanded={open}
                    label={
                        optionLabel(selectedOption) ||
                        selectedOption?.label ||
                        placeholder ||
                        'Select...'
                    }
                    rightIcon={
                        chevrons ? (
                            <ChevronsUpDown
                                style={{
                                    height: '16px',
                                    width: '16px',
                                    flexShrink: 0,
                                    opacity: 0.5,
                                    marginRight: '-0.8rem',
                                }}
                            />
                        ) : null
                    }
                    variant={props.variant}
                    style={style}
                    className={`gap-0 ${props.className}`}
                />
            </PopoverTrigger>
            <PopoverContent style={{ zIndex: 10004 }}>
                <Command style={{ width: '100%' }} shouldFilter={false}>
                    {searchMatcher && (
                        <CommandInput
                            placeholder={placeholder || 'Search...'}
                            value={filter}
                            onValueChange={(v) => setFilter(v)}
                        />
                    )}
                    <CommandList>
                        <CommandEmpty>Nothing found.</CommandEmpty>
                        <div style={{ overflowY: 'auto', maxHeight: '200px' }}>
                            {groupedAndSortedOptions
                                ? Object.entries(groupedAndSortedOptions).map(
                                      ([group, items], index) => (
                                          <div key={group + index}>
                                              <div
                                                  style={{
                                                      fontWeight: 'bold',
                                                      padding: '0 8px',
                                                  }}
                                              >
                                                  {group}
                                              </div>
                                              {items.map((option, index) => (
                                                  <CommandItem
                                                      key={
                                                          optionLabel(option) +
                                                          index
                                                      }
                                                      value={
                                                          option?.value ||
                                                          option
                                                      }
                                                      onSelect={() => {
                                                          onChange(
                                                              option?.value ||
                                                                  option
                                                          )
                                                          setOpen(false)
                                                      }}
                                                      style={{
                                                          display: 'flex',
                                                          alignItems: 'center',
                                                          padding: '0.5rem',
                                                          paddingRight:
                                                              '2.5rem',
                                                      }}
                                                  >
                                                      <Check
                                                          style={{
                                                              marginRight:
                                                                  '8px',
                                                              height: '16px',
                                                              width: '16px',
                                                              opacity:
                                                                  (option?.value ||
                                                                      option) ===
                                                                  selectedOption
                                                                      ? 1
                                                                      : 0,
                                                          }}
                                                      />
                                                      {optionLabel(option)}
                                                  </CommandItem>
                                              ))}
                                          </div>
                                      )
                                  )
                                : options.map((option, index) => (
                                      <CommandItem
                                          key={optionLabel(option) + index}
                                          value={option?.value || option}
                                          onSelect={() => {
                                              onChange(option?.value || option)
                                              setOpen(false)
                                          }}
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              padding: '0.5rem',
                                              paddingRight: '2.5rem',
                                          }}
                                      >
                                          <Check
                                              style={{
                                                  marginRight: '8px',
                                                  height: '16px',
                                                  width: '16px',
                                                  opacity:
                                                      (option?.value ||
                                                          option) ===
                                                      selectedOption
                                                          ? 1
                                                          : 0,
                                              }}
                                          />
                                          {optionLabel(option)}
                                      </CommandItem>
                                  ))}
                        </div>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}

export const BooleanSelector = ({
    selectedOption,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = [true, false]
    if (nullable) {
        options = [null, ...options]
    }
    return (
        <Selector
            selectedOption={selectedOption}
            onChange={onChange}
            options={options}
            sort={(c) => c}
            optionLabel={(c) => (c ? 'Yes' : 'No')}
            searchMatcher={(c, text) =>
                (c ? 'yes' : 'no').match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const ContactSelector = ({
    selectedContact,
    contactOptions,
    disabledContacts,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = contactOptions || ContactCollection.contacts
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (contact) =>
        disabledContacts ? disabledContacts.includes(contact) : false
    const [ImportModal, setImportModal] = useState(null)
    const [CreateModal, setCreateModal] = useState(null)

    useEffect(() => {
        const importComponent = async () => {
            const importModule = await import(
                '../Pages/ContactListPage/ImportOneContactModal'
            )
            const ImportOneContactModal = importModule.default
            setImportModal(ImportOneContactModal)
            const createModule = await import(
                '../Pages/ContactListPage/CreateContactModal'
            )
            const CreateContactModal = createModule.default
            setCreateModal(CreateContactModal)
        }
        importComponent()
    }, [])
    if (!ImportModal || !CreateModal) return
    return (
        <div style={{ display: 'flex', width: 'calc(100% - 2.5em)' }}>
            <Dropdown3
                toggleElement={
                    <button className="btn btn-default">
                        <i
                            style={{ margin: 0 }}
                            className="fa fa-plus text-xs"
                        />
                    </button>
                }
                contentStyle={{
                    width: '8em',
                    marginTop: '0.5em',
                    color: '#444',
                }}
                containerStyle={{ float: 'right' }}
            >
                <Dropdown3ListItem
                    style={{
                        padding: '0.5em 1em',
                        height: '2.5em',
                    }}
                    onClick={() =>
                        LayoutStore.openModal(
                            <CreateModal
                                modalId={'create-contact'}
                                onSave={(contact) => {
                                    onChange(contact)
                                }}
                            />
                        )
                    }
                >
                    Create
                </Dropdown3ListItem>
                <Dropdown3ListItem
                    style={{
                        padding: '0.5em 1em',
                        height: '2.5em',
                    }}
                    onClick={() =>
                        LayoutStore.openModal(
                            <ImportModal
                                modalId={'import-one-contact'}
                                onSave={(contact) => {
                                    onChange(contact)
                                }}
                            />
                        )
                    }
                >
                    Import
                </Dropdown3ListItem>
            </Dropdown3>
            <Selector
                selectedOption={selectedContact}
                onChange={onChange}
                options={options}
                isOptionDisabled={isOptionDisabled}
                sort={(c) => c?.label || -Infinity}
                optionLabel={(c) =>
                    c?.label || placeholderLabel || 'Select Contact...'
                }
                searchMatcher={(c, text) =>
                    c?.label?.toLowerCase?.()?.match?.(text.toLowerCase())
                }
                {...props}
                style={{
                    display: 'inline-flex',
                    ...props.style,
                }}
            />
        </div>
    )
}

export const CostCentreSelector = ({
    selectedCostCentre,
    costCentreOptions,
    disabledCostCentres,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = costCentreOptions || CostCentreCollection.costCentres
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (costCentre) =>
        disabledCostCentres ? disabledCostCentres.includes(costCentre) : false
    return (
        <Selector
            selectedOption={selectedCostCentre}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(cc) => cc?.name || -Infinity}
            optionLabel={(cc) =>
                cc?.name || placeholderLabel || 'Select Cost Centre...'
            }
            searchMatcher={(cc, text) =>
                cc?.name?.toLowerCase?.()?.match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const StaffSelector = ({
    selectedStaffMember,
    staffOptions,
    disabledStaff,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = staffOptions || StaffCollection.staffs
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (staff) =>
        disabledStaff ? disabledStaff.includes(staff) : false
    return (
        <Selector
            selectedOption={selectedStaffMember}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(sm) => (sm.isArchived ? 9 : 1) + (sm?.fullName || -Infinity)}
            optionLabel={(sm) =>
                sm?.fullName || placeholderLabel || 'Select Staff...'
            }
            searchMatcher={(sm, text) =>
                sm?.fullName?.toLowerCase?.()?.match?.(text.toLowerCase())
            }
            groupBy={
                options[nullable ? 1 : 0]?.isArchived !== undefined
                    ? (o) => {
                          return o.isArchived
                              ? 'Archived Staff'
                              : 'Active Staff'
                      }
                    : null
            }
            {...props}
        />
    )
}

export const RoleSelector = ({
    selectedRole,
    roleOptions,
    disabledRoles,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = roleOptions || RoleCollection.roles
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (role) =>
        disabledRoles ? disabledRoles.includes(role) : false
    return (
        <Selector
            selectedOption={selectedRole}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(sm) => sm?.name || -Infinity}
            optionLabel={(sm) =>
                sm?.name || placeholderLabel || 'Select Role...'
            }
            searchMatcher={(sm, text) =>
                sm?.name?.toLowerCase?.()?.match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const ProjectSelector = ({
    selectedProject,
    projectOptions,
    disabledProjects,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = projectOptions || ProjectCollection.projects
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (project) =>
        disabledProjects ? disabledProjects.includes(project) : false
    return (
        <Selector
            selectedOption={selectedProject}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(ph) =>
                ph?.title ? statuses.indexOf(ph?.status) + ph?.title : -Infinity
            }
            optionLabel={(sm) =>
                sm?.title || placeholderLabel || 'Select Project...'
            }
            searchMatcher={(sm, text) =>
                sm?.title?.toLowerCase?.()?.match?.(text.toLowerCase())
            }
            groupBy={
                options[nullable ? 1 : 0]?.status
                    ? (o) => {
                          return camelCaseToSentence(
                              (o?.status || 'active') + 'Projects'
                          )
                      }
                    : null
            }
            {...props}
        />
    )
}

export const PhaseSelector = ({
    selectedPhase,
    phaseOptions,
    disabledPhases,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = phaseOptions || PhaseCollection.phases
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (phase) =>
        disabledPhases ? disabledPhases.includes(phase) : false
    return (
        <Selector
            selectedOption={selectedPhase}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(ph) => {
                // Check if startDate exists and format it as a string, else use a high-value string to push it to the end
                const datePart =
                    ph?.startDate instanceof Date && !isNaN(ph?.startDate)
                        ? new Date(ph.startDate).toISOString()
                        : 'ZZZZZ'
                // Concatenate datePart with title, or use a placeholder if title is missing
                return `${statuses.indexOf(ph?.status)}-${datePart}-${
                    ph.title || ''
                }`
            }}
            optionLabel={(sm) =>
                sm?.title || placeholderLabel || 'Select Phase...'
            }
            searchMatcher={(sm, text) =>
                sm.title.toLowerCase().match(text.toLowerCase())
            }
            groupBy={
                options[nullable ? 1 : 0]?.status
                    ? (o) => {
                          return camelCaseToSentence(
                              (o?.status || 'active') + 'Phases'
                          )
                      }
                    : null
            }
            {...props}
        />
    )
}

export const TaskSelector = ({
    selectedTask,
    taskOptions,
    disabledTasks,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = taskOptions || TaskCollection.tasks
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (task) =>
        disabledTasks ? disabledTasks.includes(task) : false
    return (
        <Selector
            selectedOption={selectedTask}
            onChange={onChange}
            options={options.sort((a, b) => a.name?.localeCompare?.(b.name))}
            isOptionDisabled={isOptionDisabled}
            sort={(ph) => ph?.name || -Infinity}
            optionLabel={(sm) =>
                sm?.name || placeholderLabel || 'Select Task...'
            }
            searchMatcher={(sm, text) =>
                sm.name.toLowerCase().match(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const SupplierSelector = ({
    selectedSupplier,
    supplierOptions,
    disabledSuppliers,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = supplierOptions || SupplierCollection.suppliers
    const noSupplier = { name: null, id: null }
    if (nullable) {
        options = [noSupplier, ...options]
    }
    const isOptionDisabled = (task) =>
        disabledSuppliers ? disabledSuppliers?.includes?.(task) : false
    return (
        <Selector
            selectedOption={selectedSupplier || noSupplier}
            onChange={onChange}
            options={options.sort((a, b) => a.name?.localeCompare?.(b?.name))}
            isOptionDisabled={isOptionDisabled}
            sort={(s) => s?.name || -Infinity}
            optionLabel={(s) =>
                s?.name || placeholderLabel || 'Select Supplier...'
            }
            searchMatcher={(s, text) =>
                s.name?.toLowerCase?.()?.match?.(text?.toLowerCase?.())
            }
            style={{
                maxWidth: 'max-content',
            }}
            {...props}
        />
    )
}

export const ProjectExpenseSelector = ({
    selectedProjectExpense,
    projectExpenseOptions,
    disabledProjectExpenses,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options =
        projectExpenseOptions || ProjectExpenseCollection.projectExpenses
    const noProjectExpense = { name: null, id: null }
    if (nullable) {
        options = [noProjectExpense, ...options]
    }
    const isOptionDisabled = (task) =>
        disabledProjectExpenses
            ? disabledProjectExpenses?.includes?.(task)
            : false
    return (
        <Selector
            selectedOption={selectedProjectExpense || noProjectExpense}
            onChange={onChange}
            options={options.sort((a, b) => a.name?.localeCompare?.(b?.name))}
            isOptionDisabled={isOptionDisabled}
            sort={(s) => s?.name || -Infinity}
            optionLabel={(s) =>
                s?.name || placeholderLabel || 'Select Project Expense...'
            }
            searchMatcher={(s, text) =>
                s.name?.toLowerCase?.()?.match?.(text?.toLowerCase?.())
            }
            {...props}
        />
    )
}

export const StaffOrRoleSelector = ({
    selectedStaffOrRole,
    staffOrRoleOptions,
    disabledStaffOrRoles,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = staffOrRoleOptions || [
        ...RoleCollection.roles,
        ...StaffCollection.staffs,
    ]
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (sor) =>
        disabledStaffOrRoles ? disabledStaffOrRoles.includes(sor) : false
    return (
        <Selector
            selectedOption={selectedStaffOrRole}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(sm) =>
                (sm.isArchived ? '9' : '1') +
                (sm.name ? '1' : '9') +
                (sm.name || sm.role?.name || '') +
                (sm?.fullName || '')
            }
            optionLabel={(sor) =>
                sor?.fullName ||
                sor?.name ||
                placeholderLabel ||
                'Select Staff or Role...'
            }
            searchMatcher={(sor, text) =>
                (sor?.fullName || sor?.name)
                    ?.toLowerCase?.()
                    ?.match?.(text.toLowerCase())
            }
            groupBy={
                options[nullable ? 1 : 0]?.isArchived !== undefined
                    ? (o) => {
                          return o.isArchived ? 'Archived' : 'Active'
                      }
                    : null
            }
            {...props}
        />
    )
}

export const CostCentreOrProjectSelector = ({
    selectedCostCentreOrProject,
    costCentreOrProjectOptions,
    disabledCostCentresOrProjects,
    onChange,
    placeholderLabel,
    nullable,
    ...props
}) => {
    let options = costCentreOrProjectOptions || [
        ...CostCentreCollection.costCentres,
        ...ProjectCollection.projects,
    ]
    if (nullable) {
        options = [null, ...options]
    }
    const isOptionDisabled = (ccop) =>
        disabledCostCentresOrProjects
            ? disabledCostCentresOrProjects.includes(ccop)
            : false
    return (
        <Selector
            selectedOption={selectedCostCentreOrProject}
            onChange={onChange}
            options={options}
            isOptionDisabled={isOptionDisabled}
            sort={(ccop) => ccop?.name || ccop?.title || -Infinity}
            optionLabel={(ccop) =>
                ccop?.name ||
                ccop?.title ||
                placeholderLabel ||
                'Select Cost Centre or Project...'
            }
            searchMatcher={(ccop, text) =>
                (ccop.name || ccop.title)
                    ?.toLowerCase?.()
                    ?.match?.(text.toLowerCase())
            }
            {...props}
        />
    )
}

export const StatusSelector = ({
    selectedStatus,
    onChange,
    placeholderLabel,
    ...props
}) => {
    return (
        <Selector
            selectedOption={selectedStatus}
            onChange={onChange}
            options={['active', 'onHold', 'prospective', 'archived']}
            // sort={(c) => c.label}
            optionLabel={(s) => capitalCase(s)}
            // searchMatcher={(c, text) =>
            //     c.label.toLowerCase().match(text.toLowerCase())
            // }
            {...props}
        />
    )
}

export const RelativeDateSelector = ({
    selectedDateRange,
    onChange,
    fortnightType = 'a',
    onChangeFortnightType = () => null,
    ...props
}) => {
    const optionsKeys = Object.keys(dateStringLabels)
    const selectedOption = optionsKeys.includes(selectedDateRange)
        ? selectedDateRange
        : 'custom'
    const dates =
        selectedOption !== 'custom'
            ? dateStringLookup[selectedOption](fortnightType)
            : selectedDateRange
    return (
        <div style={{ display: 'flex' }}>
            <Selector
                selectedOption={selectedOption}
                onChange={(opt) =>
                    onChange(opt === 'custom' ? [new Date(), new Date()] : opt)
                }
                options={optionsKeys}
                // sort={(c) => c.label}
                optionLabel={(s) => dateStringLabels[s]}
                searchMatcher={(c, text) =>
                    dateStringLabels[c].toLowerCase().match(text.toLowerCase())
                }
                {...props}
            />
            {selectedOption.includes('Fortnight') ? (
                <div
                    className="flex items-center"
                    style={{ marginRight: '1em' }}
                >
                    <button
                        className={
                            fortnightType === 'a'
                                ? 'btn-primary'
                                : 'btn-default'
                        }
                        onClick={() => onChangeFortnightType('a')}
                    >
                        A
                    </button>
                    <button
                        className={
                            fortnightType === 'b'
                                ? 'btn-primary'
                                : 'btn-default'
                        }
                        onClick={() => onChangeFortnightType('b')}
                    >
                        B
                    </button>
                </div>
            ) : null}
            {selectedOption === 'custom' ? (
                <div className="flex items-center">
                    {Inputs.date({
                        style: { width: '10em' },
                        value: selectedDateRange?.[0],
                        editable: true,
                        onChange: (val) =>
                            onChange([val, selectedDateRange?.[1]]),
                        className: 'input-base',
                    })}
                    <div
                        className="text-white"
                        style={{ margin: '0 0.5em' }}
                        id="to"
                    >
                        to
                    </div>
                    {Inputs.date({
                        style: { width: '10em' },
                        value: selectedDateRange?.[1],
                        editable: true,
                        onChange: (val) =>
                            onChange([selectedDateRange?.[0], val]),
                        className: 'input-base',
                    })}
                </div>
            ) : selectedOption !== 'allTime' ? (
                <div style={{ color: 'white' }}>
                    {`(${format(dates[0], 'dd MMM yyyy')} - ${format(
                        dates[1],
                        'dd MMM yyyy'
                    )})`}
                </div>
            ) : null}
        </div>
    )
}

const statuses = ['active', 'onHold', 'prospective', 'archived']
