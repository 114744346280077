import classNames from 'classnames'
import {
    addDays,
    addWeeks,
    endOfDay,
    endOfWeek,
    format,
    parse,
    startOfMonth,
    startOfWeek,
    subDays,
    subWeeks,
} from 'date-fns'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import SessionStore from '../../State/SessionStore'
import { formatMinutes, isNumber } from '../../utils'
import _ from 'lodash'
import LayoutStore from '../../State/LayoutStore'
import EditExpenseModal from './EditExpenseModal'
import DataStore from '../../State/DataStore'
import Textarea from 'react-textarea-autosize'
import RenderOnQueries from '../Layout/RenderOnQueries'
import LoadingSpinner from '../../Components/LoadingSpinner'
import { qf } from '../../Queries/queryFormatter'
import { canEditStaffExpenses } from '../../State/Permissions/HasPermissions'
import tuple from 'immutable-tuple'
import TrackExpensesStore from './TrackExpensesStore'
import Inputs from '../../Components/Inputs'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import { useNavigate } from '@tanstack/react-router'

export default observer(({ date, staffId }) => {
    const [store, setStore] = useState(TrackExpensesStore)
    useEffect(() => {
        store.setSearchParams({ date, staffId })
    }, [date, staffId])
    useEffect(() => {
        DataStore.setAutosave(true)
        return () => DataStore.setAutosave(false)
    }, [])
    return (
        <div className="max-w-[1040px] mx-auto mb-auto w-[1040px]">
            <DailyNav
                monday={store.startOfWeek}
                dayTotals={store.dailyTotals}
                selectedDate={store.date}
                store={store}
            />
            <RenderOnQueries
                queryIds={[
                    {
                        collection: 'projectExpenseItems',
                        staleTime: 0,
                        fields: [
                            'projectId',
                            'phaseId',
                            'staffId',
                            'expenseId',
                            'cost',
                            'date',
                            'description',
                            'isLocked',
                            'deletedAt',
                            'createdAt',
                        ],
                        filters: [
                            `staffId == "${staffId || SessionStore.userId}"`,
                            `date >= ${qf(
                                startOfWeek(store.date, { weekStartsOn: 1 })
                            )}`,
                            `date <= ${qf(
                                endOfWeek(store.date, { weekStartsOn: 1 })
                            )}`,
                        ],
                    },
                    {
                        collection: 'projects',
                        fields: [
                            'jobNumber',
                            'name',
                            [
                                'expenseItemCount',
                                'projectProjectExpenseItems.count',
                            ],
                        ],
                        subQueries: [
                            {
                                label: 'projectProjectExpenseItems',
                                collection: 'projectExpenseItems',
                                join: 'id == projectProjectExpenseItems.projectId',
                                groupBy: ['projectId'],
                                fields: [['count', 'count(id)']],
                                filters: [
                                    `date >= ${qf(
                                        startOfWeek(store.date, {
                                            weekStartsOn: 1,
                                        })
                                    )}`,
                                    `date <= ${qf(
                                        endOfWeek(store.date, {
                                            weekStartsOn: 1,
                                        })
                                    )}`,
                                ],
                            },
                        ],
                        filters: ['projectProjectExpenseItems.count > 0'],
                    },
                    {
                        collection: 'phases',
                        fields: [
                            'jobNumber',
                            'name',
                            'projectId',
                            'isRootPhase',
                            [
                                'expenseItemCount',
                                'phaseProjectExpenseItems.count',
                            ],
                        ],
                        subQueries: [
                            {
                                label: 'phaseProjectExpenseItems',
                                collection: 'projectExpenseItems',
                                join: 'id == phaseProjectExpenseItems.phaseId',
                                groupBy: ['phaseId'],
                                fields: [['count', 'count(id)']],
                                filters: [
                                    `date >= ${qf(
                                        startOfWeek(store.date, {
                                            weekStartsOn: 1,
                                        })
                                    )}`,
                                    `date <= ${qf(
                                        endOfWeek(store.date, {
                                            weekStartsOn: 1,
                                        })
                                    )}`,
                                ],
                            },
                        ],
                        filters: ['phaseProjectExpenseItems.count > 0'],
                    },
                    {
                        collection: 'projectExpenses',
                        fields: [
                            'name',
                            'phaseId',
                            'projectId',
                            [
                                'expenseItemCount',
                                'expenseProjectExpenseItems.count',
                            ],
                        ],
                        subQueries: [
                            {
                                label: 'expenseProjectExpenseItems',
                                collection: 'projectExpenseItems',
                                join: 'id == expenseProjectExpenseItems.expenseId',
                                groupBy: ['expenseId'],
                                fields: [['count', 'count(id)']],
                                filters: [
                                    `date >= ${qf(
                                        startOfWeek(store.date, {
                                            weekStartsOn: 1,
                                        })
                                    )}`,
                                    `date <= ${qf(
                                        endOfWeek(store.date, {
                                            weekStartsOn: 1,
                                        })
                                    )}`,
                                ],
                            },
                        ],
                        filters: ['expenseProjectExpenseItems.count > 0'],
                    },
                ].filter((q) => q)}
                loading={
                    <div style={{ padding: '8em' }}>
                        <LoadingSpinner />
                    </div>
                }
            >
                <ExpenseRows store={store} />
            </RenderOnQueries>
        </div>
    )
})

const ExpenseRows = observer(({ store }) => {
    return (
        <div className="mt-24">
            <div style={{ height: '2em' }}></div>
            {store.costCentres
                .sort((a, b) => {
                    return a.name?.localeCompare?.(b.name)
                })
                .map((cc) => {
                    return (
                        <>
                            <ExpenseCostCentre costCentre={cc} />
                            {(store.projectsByCostCentreId[cc.id] || [])
                                .sort((a, b) => {
                                    const aName = a.title || ''
                                    const bName = b.title || ''
                                    return aName.localeCompare(bName)
                                })
                                .map((pr) => {
                                    return (
                                        <>
                                            <ExpenseProject project={pr} />
                                            {(
                                                store.phasesByProjectId[
                                                    pr.id
                                                ] || []
                                            )
                                                .sort((a, b) => {
                                                    const aName = a.title || ''
                                                    const bName = b.title || ''
                                                    return aName.localeCompare(
                                                        bName
                                                    )
                                                })
                                                .map((ph) => {
                                                    return (
                                                        <div className="phase-block timesheet-widget__row">
                                                            <div className="timesheet-widget__phase-block timesheet-widget__row__inner">
                                                                <ExpensePhase
                                                                    project={pr}
                                                                    phase={ph}
                                                                    store={
                                                                        store
                                                                    }
                                                                />
                                                                {(
                                                                    store
                                                                        .rowsByPhaseId[
                                                                        tuple(
                                                                            pr.id,
                                                                            ph?.id
                                                                        )
                                                                    ] || []
                                                                )
                                                                    .sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) => {
                                                                            const aName =
                                                                                a
                                                                                    .task
                                                                                    ?.name ||
                                                                                ''
                                                                            const bName =
                                                                                b
                                                                                    .task
                                                                                    ?.name ||
                                                                                ''
                                                                            return aName.localeCompare(
                                                                                bName
                                                                            )
                                                                        }
                                                                    )
                                                                    .map(
                                                                        (
                                                                            row
                                                                        ) => {
                                                                            return (
                                                                                <ProjectExpenseRow
                                                                                    store={
                                                                                        store
                                                                                    }
                                                                                    row={
                                                                                        row
                                                                                    }
                                                                                />
                                                                            )
                                                                        }
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                        </>
                                    )
                                })}
                        </>
                    )
                })}
            {!store.costCentres.length && (
                <div>
                    <div>
                        <div className="text-[#666666] text-3xl pb-5 -mt-4">
                            No Expenses
                        </div>
                        <div className="text-[#666666] leading-[2em]">
                            There are no expenses for this day.
                            <br />
                            Please add an entry by clicking the button below.
                        </div>
                    </div>
                </div>
            )}
            <button
                className="btn btn-lg btn-default add-timesheet-entry-button plus-btn border-[#ccc]"
                onClick={() =>
                    LayoutStore.openModal(
                        <EditExpenseModal
                            modalId={'addExpense'}
                            dates={[store.date]}
                            staff={store.staff}
                            store={store}
                        />
                    )
                }
            >
                + Add Expense
            </button>
        </div>
    )
})

const ExpenseCostCentre = observer(({ costCentre }) => {
    return (
        <div
            style={{
                fontSize: '1.7em',
                padding: '0.5em 0 0.5em 0',
                textAlign: 'left',
                borderBottom: 'solid 1px #aaa',
                marginBottom: '0.8em',
            }}
        >
            {costCentre.name}
        </div>
    )
})

const ExpenseProject = observer(({ project }) => {
    return (
        <div
            className="project-title"
            style={{
                fontSize: '1.4em',
                margin: '1.2em 0 0.2em 0.6em',
                fontWeight: 600,
                width: '50%',
            }}
        >
            {project.title}
        </div>
    )
})

const ExpensePhase = observer(({ project, phase, store }) => {
    return (
        <div
            className="timesheet-widget__phase-row"
            style={{ display: 'flex', flexDirection: 'column' }}
        >
            <div
                className="timesheet-widget__phase-title"
                style={{ width: '100%' }}
            >
                <div
                    className="inline-flexbox-container"
                    style={{
                        width: '100%',
                        paddingBottom: '0.1em',
                        alignItems: 'flex-end',
                    }}
                >
                    <div
                        className="phase-block__title flex-1-1-auto"
                        style={{ minWidth: 0, flex: '0 0 auto', width: '24%' }}
                    >
                        <div className="text-base">
                            {phase?.title || 'No Phase'}
                        </div>
                    </div>
                    {/* <BudgetProgressBar
                        project={project}
                        phase={phase}
                        role={undefined}
                        staff={store.staff}
                        startDate={startOfMonth(store.date)}
                        endDate={endOfDay(subDays(store.date, 1))}
                        containerStyles={{
                            flex: '1 1 auto',
                            margin: '0 3em',
                            position: 'relative',
                            width: '70%',
                        }}
                        textStyles={{ fontSize: '0.75em', marginLeft: '6em' }}
                        dropdownStyles={{
                            right: '1em',
                            bottom: '0.75rem',
                            height: '2.25rem',
                            width: '45%',
                            fontSize: '1rem',
                            position: 'absolute',
                        }}
                        dropdownAbove={true}
                        extraHours={_.sum(
                            store.timeEntries
                                .filter(
                                    (te) =>
                                        te.phaseId === phase?.id &&
                                        te.staffId == store.staff.id
                                )
                                .map((te) => te.hours)
                        )}
                    /> */}
                </div>
            </div>
            <div
                className="timesheet-widget__progress-bar timesheet-widget__middle-section"
                style={{ verticalAlign: 'bottom' }}
            >
                {/* {?.phase != null ? (
                    <PhaseProgressBar
                        projectPhaseGroup={.projectPhaseGroup}
                        phase={.phase}
                        staffMember={.staffMember}
                        monthIndex={
                            .rows.getIn([0, 'entries', 0]).monthIndex
                        }
                        loading={.budgetState !== 'loaded'}
                    />
                ) : null} */}
            </div>
        </div>
    )
})

const ProjectExpenseRow = observer(({ store, row, mode }) => {
    const isHighlighted = false
    return (
        <div
            className={classNames('timesheet-widget__task-row', {
                'timesheet-widget__task-row--highlighted': isHighlighted,
            })}
        >
            <div className="timesheet-widget__task-title timesheet-widget__left-section">
                {canEditStaffExpenses(SessionStore.user) && !row.isLocked ? (
                    <button
                        className="timesheet-widget__task-lock lock-button"
                        onClick={() => {
                            row.update({ isLocked: true })
                        }}
                    >
                        <i className="fa fa-lock fa-fw" />
                    </button>
                ) : canEditStaffExpenses(SessionStore.user) ? (
                    <button
                        className="timesheet-widget__task-lock lock-button"
                        onClick={() => {
                            row.update({ isLocked: false })
                        }}
                    >
                        <i className="fa fa-unlock fa-fw" />
                    </button>
                ) : null}
                <div className="text-[0.85em]">
                    <span style={{ marginLeft: '0.5em' }}>
                        {row?.expense != null
                            ? row.expense.name
                            : '(General Expense)'}
                    </span>
                </div>
            </div>
            <div className="timesheet-widget__task-entries timesheet-widget__middle-section">
                <DailyExpense expense={row} store={store} />
            </div>
        </div>
    )
})

const DailyExpense = observer(({ expense, store }) => {
    return (
        <div className="time-entry time-entry--daily">
            <Inputs.currency
                style={{ width: '8em', fontSize: '0.8em' }}
                value={expense.cost}
                onChange={(cost) => {
                    expense.update({ cost })
                    expense.attach()
                }}
                className="time-entry__input"
                isEditable={!expense.isLocked}
            />
            <div className="time-entry--daily__note">
                <Textarea
                    value={expense.description || ''}
                    onChange={(e) => {
                        expense.update({ description: e.target.value })
                        expense.attach()
                    }}
                    className="time-entry__note-input"
                    placeholder="(No Description)"
                    disabled={expense.isLocked}
                />
            </div>
        </div>
    )
})

const DailyNav = observer(
    ({ mode, monday, dayTotals, selectedDate, store }) => {
        const navigate = useNavigate()

        const formatTotal = (n, type) => {
            return isNumber(n) ? (
                FormatCurrency(n)
            ) : n === 'loading' ? (
                <i className="fa fa-spinner fa-spin" style={{ margin: 0 }} />
            ) : (
                <i
                    className="fa fa-exclamation-triangle error-highlight-color"
                    style={{ margin: 0 }}
                    title={`Some entries for this ${type} have errors.`}
                />
            )
        }
        return (
            <div className="max-w-[1040px] bg-[#fafafa] z-[1000] pt-8 fixed  w-[1040px] -mt-32">
                <div className="border-b border-[#aaa]">
                    <div>
                        <div
                            className="timesheet-widget__row min-w-[36em] mb-2.5 text-center"
                            style={{ fontSize: '1em' }}
                        >
                            <div className="timesheet-widget__row__inner min-[1310px]:w-[92%] w-full mx-auto">
                                <div
                                    className="flex items-start"
                                    // to align buttons
                                    style={
                                        {
                                            // marginTop: '-2.4em',
                                        }
                                    }
                                >
                                    <div className="timesheet-widget__left-section min-[1381px]:w-[30%] w-1/4">
                                        <div
                                            className="timesheets__navigation flex items-center"
                                            style={{ flex: '0 0 auto' }}
                                        >
                                            <div
                                                className="flex w-full text-center"
                                                style={{ textAlign: 'center' }}
                                            >
                                                <button
                                                    onClick={
                                                        store.shiftThisWeek
                                                    }
                                                    className="btn btn-default w-full py-1.5 px-3 text-sm text-[#333] bg-white border border-[#ccc] text-center whitespace-nowrap rounded hover:bg-[#e0e0e0]"
                                                >
                                                    Today
                                                </button>
                                            </div>
                                            <div
                                                className=""
                                                style={{ flex: '0 0 auto' }}
                                            >
                                                <button
                                                    onClick={
                                                        store.shiftPrevWeek
                                                    }
                                                    className="btn btn-default previous-week-button ml-3 py-1.5 px-3 text-[#333] bg-white border border-[#ccc] text-sm rounded"
                                                    style={
                                                        {
                                                            // marginLeft: '1em',
                                                        }
                                                    }
                                                    title="Previous week"
                                                >
                                                    <i className="fa fa-chevron-left m-0" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="timesheet-widget__middle-section min-[1381px]:w-[65%] w-3/4 -mt-4"
                                        // style={{
                                        //     flex: '0 0 auto',
                                        //     paddingTop: '1.4em',
                                        // }}
                                    >
                                        <div className="timesheets__day-nav flex justify-around w-full">
                                            {dayTotals.map(
                                                function (dayTotal, dayIndex) {
                                                    let d1 = addDays(
                                                        monday,
                                                        dayIndex
                                                    )

                                                    let inner = (
                                                        <div className="timesheet__nav__day__date">
                                                            <div className="text-[10px] leading-[14px]">
                                                                {format(
                                                                    d1,
                                                                    'EEE'
                                                                )}
                                                            </div>
                                                            <div className="text-xl">
                                                                {format(
                                                                    d1,
                                                                    'dd'
                                                                )}
                                                            </div>
                                                        </div>
                                                    )

                                                    return (
                                                        <div
                                                            key={dayIndex}
                                                            className="flex-0-0-auto timesheet__nav__day text-center"
                                                            data-date={format(
                                                                d1,
                                                                'yyyy-MM-dd'
                                                            )}
                                                            style={{
                                                                fontSize:
                                                                    '1.1em',
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            <button
                                                                className={classNames(
                                                                    'btn btn-default timesheets__day-nav-button border hover:m-0 hover:border-2 hover:border-gold text-sm text-[#333] bg-white rounded py-1.5 px-3 whitespace-nowrap',
                                                                    d1?.getTime?.() ===
                                                                        selectedDate?.getTime?.()
                                                                        ? 'border-2 border-gold m-0'
                                                                        : 'border-[#ccc] m-px'
                                                                )}
                                                                style={{
                                                                    fontSize:
                                                                        '1.1em',
                                                                    textAlign:
                                                                        'center',
                                                                }}
                                                                onClick={() => {
                                                                    navigate({
                                                                        search: (
                                                                            prev
                                                                        ) => ({
                                                                            ...prev,
                                                                            date: format(
                                                                                d1,
                                                                                'yyyy-MM-dd'
                                                                            ),
                                                                        }),
                                                                    })
                                                                }}
                                                            >
                                                                {inner}
                                                            </button>
                                                            <div className="timesheet-widget__hours-cell timesheet__nav__total text-xs text-[#666666] font-semibold mt-[2px]">
                                                                {formatTotal(
                                                                    dayTotal,
                                                                    'day'
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="timesheet-widget__right-section"
                                        style={{ flex: '0 0 auto' }}
                                    >
                                        <button
                                            onClick={store.shiftNextWeek}
                                            className="btn btn-default next-week-button py-1.5 px-3 text-[#333] bg-white border border-[#ccc] text-sm rounded"
                                            title="Next week"
                                        >
                                            <i className="fa fa-chevron-right m-0" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
)
