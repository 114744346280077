import { Switch, Input, Button } from 'antd'
import { observer } from 'mobx-react'
import React, { useRef, useEffect, useCallback, useState } from 'react'
import timerStore from './TimerStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPauseCircle,
    faPlayCircle,
} from '@fortawesome/free-regular-svg-icons'
import useOutsideClick from '@rooks/use-outside-click'
import { formatNumber2 } from '../../utils'
import SessionStore from '../../State/SessionStore'
import BudgetProgressBar from '../BudgetProgressBar'
import {
    endOfDay,
    endOfMonth,
    format,
    startOfDay,
    startOfMonth,
    subDays,
} from 'date-fns'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import StaffCollection from '../../State/Collections/StaffCollection'
import RenderOnQueries from '../../Pages/Layout/RenderOnQueries'
import TaskCollection from '../../State/Collections/TaskCollection'
import {
    canEnterTimeAgainstProject,
    canViewFlexiRemote,
} from '../../State/Permissions/HasPermissions'
import { qf } from '../../Queries/queryFormatter'
import { queryClient } from '../../App'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import { tuple } from 'immutable-tuple'
import _ from 'lodash'
import { ProjectSelector } from '../Selector'
import { PhaseAndTaskSelectors } from '../Calendar/CalendarSidebar'

const { TextArea } = Input

export default observer(() => {
    const [renderTime] = useState(new Date())
    const settings = SessionStore.organisation.settings
    const disabled = timerStore.saveState === 'pending'
    let progressGradient = { '0%': '#108ee9' }
    progressGradient[
        `${Math.round((0.5 / timerStore.budget.percent) * 100)}%`
    ] = '#87d068'
    if (timerStore.budget.percent > 0.8) {
        progressGradient[
            `${Math.round((0.8 / timerStore.budget.percent) * 100)}%`
        ] = '#ffc200'
    }
    if (timerStore.budget.percent > 1) {
        progressGradient[
            `${Math.round((1 / timerStore.budget.percent) * 100)}%`
        ] = '#ff5800'
    }

    const panelRef = useRef()

    function outsideClick(e) {
        const panel = panelRef.current.getBoundingClientRect()
        const x = e.clientX
        const y = e.clientY

        if (
            new Date() - renderTime < 100 || // registers the open event as a click outside
            (x >= panel.left &&
                x <= panel.right &&
                y >= panel.top &&
                y <= panel.bottom)
        ) {
            // console.log('Clicked inside panel')
        } else {
            // console.log('Clicked outside panel', new Date() - renderTime)
            timerStore.toggleOpen(false)
        }
    }
    useOutsideClick(panelRef, outsideClick)
    const project =
        ProjectCollection.projectsById[timerStore.selectedValues.projectId]
    const phase =
        PhaseCollection.phasesById[timerStore.selectedValues.phaseId] ||
        PhaseCollection.phasesByProjectId[project?.id]?.[0]
    const task =
        TaskCollection.tasksById[timerStore.selectedValues.taskId] ||
        TaskCollection.tasksByPhaseId[phase?.id]?.[0]
    const staff =
        StaffCollection.staffsById[timerStore.selectedValues.staffId] ||
        SessionStore.user
    const role = staff?.role
    return (
        <div
            className="w-[20em] absolute top-[2.5em] right-0 z-[1001] shadow-[6px_6px_16px_-1px_#00000033]"
            ref={panelRef}
        >
            <div className="bg-white p-3.5 z-10">
                <div className="text-center flex items-center justify-center text-[45px] pt-5 pb-2.5">
                    {timerStore.timeDisplay}
                    {timerStore.timerState === 'idle' ? (
                        <span
                            className="ml-3 cursor-pointer text-[40px]"
                            onClick={
                                timerStore.interfaceDisabled
                                    ? () => null
                                    : timerStore.startTimer
                            }
                        >
                            <FontAwesomeIcon icon={faPlayCircle} />
                        </span>
                    ) : (
                        <span
                            className="ml-3 cursor-pointer text-[40px]"
                            onClick={
                                timerStore.interfaceDisabled
                                    ? () => null
                                    : timerStore.pauseTimer
                            }
                        >
                            <FontAwesomeIcon icon={faPauseCircle} />
                        </span>
                    )}
                </div>
                <div className="py-6">
                    <RenderOnQueries
                        queryIds={[
                            project?.id
                                ? {
                                      collection: 'phases',
                                      fields: [
                                          'jobNumber',
                                          'name',
                                          'projectId',
                                          'status',
                                          'isRootPhase',
                                      ],
                                      filters: [
                                          `projectId == "${project?.id}"`,
                                      ],
                                  }
                                : null,
                        ].filter((v) => v)}
                    >
                        <BudgetProgressBar
                            dropdownClassName="!text-xs"
                            key={String(project?.id) + String(phase?.id)}
                            project={project}
                            phase={phase}
                            role={role}
                            staff={staff}
                            startDate={startOfMonth(new Date())}
                            endDate={endOfDay(subDays(new Date(), 1))}
                            extraHours={_.sum(
                                (TimeEntryCollection.timeEntries || [])
                                    .filter(
                                        (te) =>
                                            te.staffId === staff?.id &&
                                            te.phaseId === phase?.id &&
                                            te.date.getTime() ===
                                                new Date().getTime() &&
                                            !te.deletedAt
                                    )
                                    .map((te) => te.hours)
                            )}
                            variant="secondary"
                        />
                    </RenderOnQueries>
                </div>
                <div className="flex flex-col gap-1">
                    <ProjectSelector
                        selectedProject={project}
                        projectOptions={ProjectCollection.projects.filter((p) =>
                            canEnterTimeAgainstProject(SessionStore.user, p)
                        )}
                        onChange={(project) => {
                            const projectId = project?.id
                            queryClient.invalidateQueries([
                                `phases-${projectId}`,
                            ])
                            timerStore.updateSelectedValues({
                                projectId,
                            })
                        }}
                        disabled={timerStore.interfaceDisabled}
                        variant="secondary"
                        style={{ width: '100%' }}
                    />
                    <RenderOnQueries
                        queryIds={[
                            project?.id
                                ? {
                                      id: `phases-${project.id}`,
                                      collection: 'phases',
                                      fields: [
                                          'jobNumber',
                                          'name',
                                          'projectId',
                                          'status',
                                          'startDate',
                                          'endDate',
                                          'isRootPhase',
                                          [
                                              'budgetedStaffIds',
                                              'budgetedHours.staffIds',
                                          ],
                                          [
                                              'monthlyAllocatedStaffIds',
                                              'monthlyAllocations.staffIds',
                                          ],
                                      ],
                                      filters: [
                                          `projectId == "${project?.id}"`,
                                      ],
                                      subQueries: [
                                          {
                                              collection: 'budgetedHours',
                                              join: `id == budgetedHours.phaseId`,
                                              groupBy: ['phaseId'],
                                              fields: [
                                                  ['staffIds', 'uniq(staffId)'],
                                              ],
                                              filters: [`staffId != null`],
                                          },
                                          {
                                              collection: 'monthlyAllocations',
                                              join: `id == monthlyAllocations.phaseId`,
                                              groupBy: ['phaseId'],
                                              fields: [
                                                  ['staffIds', 'uniq(staffId)'],
                                              ],
                                              filters: [
                                                  `staffId != null`,
                                                  `month >= ${qf(
                                                      format(
                                                          new Date(),
                                                          'yyyy-MM'
                                                      )
                                                  )}`,
                                                  `month <= ${qf(
                                                      format(
                                                          new Date(),
                                                          'yyyy-MM'
                                                      )
                                                  )}`,
                                              ],
                                          },
                                      ],
                                  }
                                : null,
                            project?.id
                                ? {
                                      collection: 'tasks',
                                      fields: [
                                          'name',
                                          'projectId',
                                          'phaseId',
                                          'isDefault',
                                          'isBillable',
                                          'isVariation',
                                      ],
                                      filters: [
                                          `projectId == "${project?.id}"`,
                                      ],
                                  }
                                : null,
                        ].filter((v) => v)}
                    >
                        <PhaseAndTaskSelectors
                            store={timerStore}
                            disabled={disabled}
                            variant="secondary"
                        />
                    </RenderOnQueries>
                </div>
                <div className="flex justify-around text-center my-8 text-sm text-[#666]">
                    {settings.timeEntryFlags.includes('billable') && (
                        <div className="flex flex-col">
                            <Switch
                                checked={
                                    timerStore.selectedValues.isBillable ||
                                    task?.isBillable
                                }
                                onChange={(isBillable) => {
                                    timerStore.updateSelectedValues({
                                        isBillable,
                                    })
                                }}
                                disabled={timerStore.interfaceDisabled}
                            />
                            <div>Billable</div>
                        </div>
                    )}
                    {settings.timeEntryFlags.includes('variation') && (
                        <div className="flex flex-col">
                            <Switch
                                checked={
                                    timerStore.selectedValues.isVariation ||
                                    task?.isVariation
                                }
                                onChange={(isVariation) => {
                                    timerStore.updateSelectedValues({
                                        isVariation,
                                    })
                                }}
                                disabled={timerStore.interfaceDisabled}
                            />
                            <div>Variation</div>
                        </div>
                    )}
                    {settings.timeEntryFlags.includes('overtime') && (
                        <div className="flex flex-col">
                            <Switch
                                checked={
                                    timerStore.selectedValues.isOvertime ||
                                    task?.isOvertime
                                }
                                onChange={(isOvertime) => {
                                    timerStore.updateSelectedValues({
                                        isOvertime,
                                    })
                                }}
                                disabled={timerStore.interfaceDisabled}
                            />
                            <div>Overtime</div>
                        </div>
                    )}
                    {canViewFlexiRemote() &&
                        settings.timeEntryFlags.includes('remote') && (
                            <div>
                                <Switch
                                    checked={timerStore.selectedValues.remote}
                                    onChange={(remote) => {
                                        timerStore.updateSelectedValues({
                                            remote,
                                        })
                                    }}
                                    disabled={timerStore.interfaceDisabled}
                                />
                                <div>Remote</div>
                            </div>
                        )}
                    {canViewFlexiRemote() &&
                        settings.timeEntryFlags.includes('flexi') && (
                            <div>
                                <Switch
                                    checked={
                                        timerStore.selectedValues.flexi ||
                                        task?.flexi
                                    }
                                    onChange={(flexi) => {
                                        timerStore.updateSelectedValues({
                                            flexi,
                                        })
                                    }}
                                    disabled={timerStore.interfaceDisabled}
                                />
                                <div>Flexi</div>
                            </div>
                        )}
                </div>
                <TextArea
                    value={timerStore.selectedValues.notes}
                    onChange={(e) => {
                        timerStore.updateSelectedValues({
                            notes: e.target.value,
                        })
                    }}
                    style={{ fontSize: '1.2rem' }}
                    placeholder="Enter some notes..."
                    autoSize={{ minRows: 5 }}
                    allowClear
                    disabled={timerStore.interfaceDisabled}
                />
                <Button
                    danger
                    style={{ marginTop: '1rem', width: '100%' }}
                    onClick={timerStore.resetTimer}
                    disabled={timerStore.interfaceDisabled}
                >
                    Cancel Timer
                </Button>
                <Button
                    style={{ marginTop: '1rem', width: '100%' }}
                    onClick={timerStore.saveTimeEntry}
                    disabled={timerStore.interfaceDisabled}
                >
                    {timerStore.saveState === 'saved'
                        ? 'Saved!'
                        : timerStore.saveState === 'saving'
                          ? 'Saving...'
                          : 'Save Time Entry'}
                </Button>
            </div>
            {timerStore.timeEntries.length ? (
                <div
                    style={{
                        background: '#f5f5f5',
                        padding: '1em',
                        boxShadow: '3px 3px 12px -1px inset #00000011',
                        position: 'relative',
                        borderTop: 'solid 1px #ddd',
                        maxHeight: '7em',
                        overflow: 'auto',
                    }}
                >
                    {[...timerStore.timeEntries]
                        .sort((a, b) => b.startMinutes - a.startMinutes)
                        .map((te) => {
                            return (
                                <div
                                    style={{
                                        padding: '1em',
                                        fontSize: '0.8em',
                                        lineHeight: '1.2em',
                                        borderBottom: 'solid 1px #ddd',
                                    }}
                                >
                                    <div style={{ display: 'flex' }}>
                                        <div
                                            style={{
                                                flex: '1 1 auto',
                                                textAlign: 'left',
                                            }}
                                        >{`${formatNumber2(
                                            te.numMinutes / 60
                                        )} hours`}</div>
                                        <div
                                            style={{ flex: '0 0 auto' }}
                                        >{`${te.project.title}`}</div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div
                                            style={{
                                                flex: '1 1 auto',
                                                textAlign: 'left',
                                            }}
                                        >{`${formatTime(
                                            te.startMinutes / 60
                                        )}-${formatTime(
                                            te.endMinutes / 60
                                        )}`}</div>
                                        <div
                                            style={{ flex: '0 0 auto' }}
                                        >{`${te.phase?.title}`}</div>
                                    </div>
                                </div>
                            )
                        })}
                </div>
            ) : null}
        </div>
    )
})

const formatTime = (hours) => {
    let wholeHours = Math.floor(hours)
    let mins = Math.round((hours - wholeHours) * 60)
    let ampm = 'am'
    if (wholeHours > 12) {
        wholeHours -= 12
        ampm = 'pm'
    }
    return `${wholeHours}:${mins < 10 ? '0' : ''}${mins}${ampm}`
}
