import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect } from 'react'
import PageHeader from '../Layout/PageHeader'
import ContactCollection from '../../State/Collections/ContactCollection'
import { canEditContacts } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'

export default observer(({ id, extraContent }) => {
    const contact = ContactCollection.contactsById[id]
    if (!contact) return null
    return (
        <PageHeader
            heading={
                <div>
                    <div>{contact?.label || 'New Contact'}</div>
                </div>
            }
            rightButtons={
                <>
                    {canEditContacts(SessionStore.user) ? (
                        <button
                            className="btn btn-default edit-controls__delete-button"
                            onClick={() => null}
                        >
                            <i className="fa fa-trash mr-1" />
                            Delete
                        </button>
                    ) : null}
                </>
            }
            extraContent={extraContent}
        />
    )
})
