import * as React from 'react'
import { type DataTableFilterField } from '@2/types'
import ProjectListStore from '@/Pages/ProjectListPage/ProjectListStore'

import { useDataTable } from '@2/hooks/use-data-table'
import { DataTableAdvancedToolbar } from '@2/components/data-table/advanced/data-table-advanced-toolbar'
import { DataTable } from '@2/components/data-table/data-table'
import { DataTableToolbar } from '@2/components/data-table/data-table-toolbar'

import { getColumns } from './project-report-columns'
// import { TasksTableFloatingBar } from "./tasks-table-floating-bar"
// import { useTasksTable } from "./tasks-table-provider"
// import { TasksTableToolbarActions } from "./tasks-table-toolbar-actions"

export function ProjectReportTable() {
    // Feature flags for showcasing some additional features. Feel free to remove them.
    // const { featureFlags } = useTasksTable()

    // const { data, pageCount } = React.use(tasksPromise)

    // Memoize the columns so they don't re-render on every render
    // const columns = React.useMemo(() => getColumns(), [])

    /**
     * This component can render either a faceted filter or a search filter based on the `options` prop.
     *
     * @prop options - An array of objects, each representing a filter option. If provided, a faceted filter is rendered. If not, a search filter is rendered.
     *
     * Each `option` object has the following properties:
     * @prop {string} label - The label for the filter option.
     * @prop {string} value - The value for the filter option.
     * @prop {React.ReactNode} [icon] - An optional icon to display next to the label.
     * @prop {boolean} [withCount] - An optional boolean to display the count of the filter option.
     */
    // const filterFields: DataTableFilterField<Task>[] = [
    //     {
    //         label: 'Title',
    //         value: 'title',
    //         placeholder: 'Filter titles...',
    //     },
    //     {
    //         label: 'Status',
    //         value: 'status',
    //         options: tasks.status.enumValues.map((status) => ({
    //             label: status[0]?.toUpperCase() + status.slice(1),
    //             value: status,
    //             icon: getStatusIcon(status),
    //             withCount: true,
    //         })),
    //     },
    //     {
    //         label: 'Priority',
    //         value: 'priority',
    //         options: tasks.priority.enumValues.map((priority) => ({
    //             label: priority[0]?.toUpperCase() + priority.slice(1),
    //             value: priority,
    //             icon: getPriorityIcon(priority),
    //             withCount: true,
    //         })),
    //     },
    // ]

    const data = ProjectListStore.queryData || []

    const columns = getColumns()

    const { table } = useDataTable({
        data,
        columns,
        // pageCount,
        // optional props
        // filterFields,
        enableAdvancedFilter: true,
        defaultPerPage: 100000,
        defaultSort: 'createdAt.desc',
    })

    return (
        <DataTable
            table={table}
            // floatingBar={
            //     featureFlags.includes('floatingBar') ? (
            //         <TasksTableFloatingBar table={table} />
            //     ) : null
            // }
        >
            {/* <DataTableAdvancedToolbar table={table}>
                <TasksTableToolbarActions table={table} />
            </DataTableAdvancedToolbar> */}
        </DataTable>
    )
}
