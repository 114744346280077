import { Link } from '@tanstack/react-router'
import { type ColumnDef } from '@tanstack/react-table'
import Formatter from '@/Components/Formatter'
import SessionStore from '@/State/SessionStore'
import {
    canViewProjectInvoices,
    canViewProjectStaffCost,
} from '@/State/Permissions/HasPermissions'
import ProgressBar from '@/Components/Widgets/ProgresBar2'

const renderValue = (
    value,
    type,
    permissions,
    row,
    format = Formatter.number
) => {
    switch (type) {
        case 'currency':
            return Formatter.currency(value)
        case 'percent':
            return Formatter.percent(value)
        case 'progressBar':
            return (
                <ProgressBar
                    width="100%"
                    height="1.5em"
                    numerator={value?.numerator}
                    denominator={value?.denominator}
                    formatNumber={format}
                />
            )
        default:
            return String(value)
    }
}

export function getColumns(): ColumnDef<any>[] {
    return [
        {
            accessorKey: 'name',
            header: 'Name',
            cell: ({ getValue, row }) => {
                const value = getValue()
                if (row.original.group === 'totals') return 'Total'
                return (
                    <div
                        style={{
                            paddingLeft: `${1 * row.original.groupLevel}rem`,
                        }}
                    >
                        {row.original.children?.length ? (
                            <Link to={`/projects/${row.original.projectId}`}>
                                {value}
                            </Link>
                        ) : (
                            value
                        )}
                    </div>
                )
            },
            size: 260,
            meta: {
                type: 'text',
            },
        },
        {
            accessorKey: 'revenue',
            header: 'Revenue',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 100,
            meta: {
                type: 'currency',
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        row?.project || row
                    ),
            },
        },
        {
            accessorKey: 'expenses',
            header: 'Expenses (cost)',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 100,
            meta: {
                type: 'currency',
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        row?.project || row
                    ),
            },
        },
        {
            accessorKey: 'profit',
            header: 'Profit (cost + project expenses)',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 100,
            meta: {
                type: 'currency',
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        row?.project || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        row?.project || row
                    ),
            },
        },
        {
            accessorKey: 'profitMargin',
            header: 'Profit margin (cost + project expenses)',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 100,
            meta: {
                type: 'percent',
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        row?.project || row
                    ) &&
                    canViewProjectStaffCost(
                        SessionStore.user,
                        row?.project || row
                    ),
            },
        },
        {
            accessorKey: 'actualVsBudgetedHours',
            header: 'Actual / Budgeted Hours',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 240,
            meta: {
                type: 'progressBar',
            },
        },
        {
            accessorKey: 'actualVsBudgetedExpenses',
            header: 'Actual (Cost) / Budgeted Expenses',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 240,
            meta: {
                type: 'progressBar',
                format: (v) => Formatter.currency(v, { decimals: 0 }),
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        row?.project || row
                    ),
            },
        },
        {
            accessorKey: 'revenueVsFee',
            header: 'Revenue / Fee',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 240,
            meta: {
                type: 'progressBar',
                format: (v) => Formatter.currency(v, { decimals: 0 }),
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        row?.project || row
                    ),
            },
        },
        {
            accessorKey: 'actualVsBudgetedHours',
            header: 'Actual / Budgeted Hours',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 240,
            meta: {
                type: 'progressBar',
            },
        },
        {
            accessorKey: 'actualVsBudgetedExpenses',
            header: 'Actual (Cost) / Budgeted Expenses',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 240,
            meta: {
                type: 'progressBar',
                format: (v) => Formatter.currency(v, { decimals: 0 }),
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        row?.project || row
                    ),
            },
        },
        {
            accessorKey: 'revenueVsFee',
            header: 'Revenue / Fee',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 240,
            meta: {
                type: 'progressBar',
                format: (v) => Formatter.currency(v, { decimals: 0 }),
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        row?.project || row
                    ),
            },
        },
        {
            accessorKey: 'actualVsBudgetedHours',
            header: 'Actual / Budgeted Hours',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 240,
            meta: {
                type: 'progressBar',
            },
        },
        {
            accessorKey: 'actualVsBudgetedExpenses',
            header: 'Actual (Cost) / Budgeted Expenses',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 240,
            meta: {
                type: 'progressBar',
                format: (v) => Formatter.currency(v, { decimals: 0 }),
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        row?.project || row
                    ),
            },
        },
        {
            accessorKey: 'revenueVsFee',
            header: 'Revenue / Fee',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 240,
            meta: {
                type: 'progressBar',
                format: (v) => Formatter.currency(v, { decimals: 0 }),
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        row?.project || row
                    ),
            },
        },
        {
            accessorKey: 'actualVsBudgetedHours',
            header: 'Actual / Budgeted Hours',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 240,
            meta: {
                type: 'progressBar',
            },
        },
        {
            accessorKey: 'actualVsBudgetedExpenses',
            header: 'Actual (Cost) / Budgeted Expenses',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 240,
            meta: {
                type: 'progressBar',
                format: (v) => Formatter.currency(v, { decimals: 0 }),
                permissions: (row) =>
                    canViewProjectStaffCost(
                        SessionStore.user,
                        row?.project || row
                    ),
            },
        },
        {
            accessorKey: 'revenueVsFee',
            header: 'Revenue / Fee',
            cell: ({ getValue, row, column }) =>
                renderValue(
                    getValue(),
                    column.columnDef?.meta?.type,
                    column.columnDef?.meta?.permissions,
                    row.original
                ),
            size: 240,
            meta: {
                type: 'progressBar',
                format: (v) => Formatter.currency(v, { decimals: 0 }),
                permissions: (row) =>
                    canViewProjectInvoices(
                        SessionStore.user,
                        row?.project || row
                    ),
            },
        },
    ]
}
