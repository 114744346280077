import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Modal from '../../Components/Modal'
import SessionStore from '../../State/SessionStore'
import DataStore from '../../State/DataStore'
import TextValue from '../../Components/Widgets/TextValue'
import { StaffPermissionOptions } from '../StaffPage/StaffPermissionsTab'
import { Radio, Switch, Select, Button } from 'antd'
import { capitalCase } from 'change-case'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import BillingPageStore from './BillingPageStore'

export default observer(({ staff, fee, modalId }) => {
    return (
        <Modal
            modalId={modalId}
            heading={`${staff.fullName}'s Permissions`}
            saveLabel={'Save Email'}
            onSave={() => {
                DataStore.saveModel(staff)
            }}
        >
            <div
                style={{
                    padding: '3em 4em 4em 4em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h4 style={{ margin: 0 }}>{`Billing Level: ${capitalCase(
                    staff.billingLevel
                )} - ${FormatCurrency(
                    BillingPageStore.getFeeAtLevel(staff.billingLevel),
                    { decimal: 2 }
                )}`}</h4>
                <Switch
                    checked={!staff.isArchived}
                    onChange={(v) => {
                        staff.update({ isArchived: !v })
                    }}
                    checkedChildren="Is Active"
                    unCheckedChildren="Is Archived"
                />
                <Switch
                    checked={staff.hasLogin && !staff.isArchived}
                    onChange={(v) => {
                        staff.update({
                            hasLogin: v,
                            isArchived: staff.isArchived
                                ? !v
                                : staff.isArchived,
                        })
                    }}
                    checkedChildren="Has Login"
                    unCheckedChildren="Can't Login"
                />
            </div>

            <StaffPermissionOptions staff={staff} />
        </Modal>
    )
})
