import _ from 'lodash'
import React from 'react'
import { queryFilters } from '../../Queries/queryFilters'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import ResourceScheduleStore from '../../Pages/ResourceSchedulePage/ResourceScheduleStore'
import { canEditStaffAllocations } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import CellComponent from '../../Components/CellComponent'
import { FormatPercent } from '../../Utils/Localisation/PercentFormatter'
import { getGradient } from '../../Components/gradient'
import { FormatNumber } from '../../Utils/Localisation/NumberFormatter'
import { ProjectSidebarStore } from '../../Pages/ResourceSchedulePage/ResourceScheduleProjectSidebar'

const cellStyles = (row, stores, periodStart, isPastPeriod, keys) => {
    const period = format(periodStart, ResourceScheduleStore.periodFormat())
    let progress = getProgressValue(ResourceScheduleStore, period, row)
    if (
        ResourceScheduleStore.report.filters.percentData.includes('remaining')
    ) {
        progress = Math.max(0.1, 1 - progress)
    }
    return {
        ...(isPastPeriod
            ? {
                  backgroundImage: `url(${new URL(
                      '~/public/diag.png',
                      import.meta.url
                  )})`,
              }
            : {}),
        ...((row?.projectId || row?.phaseId) &&
        (row?.startDate || row?._startDate) &&
        (row?.endDate || row?._endDate) &&
        periodStart >= ResourceScheduleStore.startOfPeriod(row.startDate) &&
        periodStart <= ResourceScheduleStore.endOfPeriod(row.endDate)
            ? { borderBottom: '3px #3dacafaa solid' }
            : {}),
        ...(stores?.row && progress ? getGradient(progress || 0) : {}),
    }
}

export const ResourceScheduleReportAllocationColumns = (
    store,
    keys,
    projectForecastsStore
) => ({
    title: (
        level,
        periodStartDate,
        groupBy = [],
        filters = [],
        { status, projectId } = {}
    ) => ({
        id: 'title',
        label: 'Title',
        type: 'text',
        width: 18,
        editable: (row) => false,
        value: (row, stores) => {
            return row.title
        },
        component: ({ value, group, stores }) => {
            const { row, table } = stores
            if (row.group === 'totals') return 'Total'
            return (
                <div style={{ paddingLeft: `${1 * row.groupLevel}rem` }}>
                    {value}
                </div>
            )
        },
    }),
    project: (
        periodStartDate,
        groupBy = [],
        filters = [],
        { status, projectId } = {}
    ) => ({
        id: 'project',
        label: 'Project',
        type: 'project',
        width: 18,
        value: (row) => row.project,
        queryFilters: ({ operator, value }) => {
            return queryFilters.singleId[operator]('projectId', value)
        },
        queries: () => [
            {
                collection: 'phases',
                groupBy,
                fields: ['projectId'],
                filters: [...filters],
            },
        ],
    }),
    phase: (
        periodStartDate,
        groupBy = [],
        filters = [],
        { status, projectId } = {}
    ) => ({
        id: 'phase',
        label: 'Phase',
        type: 'phase',
        width: 18,
        value: (row) => {
            return row
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.text[operator]('name', value)
        },
        queries: () => [
            {
                collection: 'phases',
                groupBy,
                fields: ['name'],
                filters: [...filters],
            },
        ],
    }),
    status: (
        periodStartDate,
        groupBy = [],
        filters = [],
        { status, projectId } = {}
    ) => ({
        id: 'status',
        label: 'Status',
        type: 'status',
        width: 18,
        value: (row) => {
            return row.status || row._status
        },
        queryFilters: ({ operator, value }) => {
            return queryFilters.text[operator]('status', value)
        },
        queries: () => [
            {
                collection: 'phases',
                groupBy,
                fields: ['status'],
                filters: [...filters],
            },
        ],
    }),
    hours: (periodStartDate, level, filters) => {
        const groupsAtLevel = store.report.filters.groups.slice(0, level + 1)
        const groupsBeforeLevel = store.report.filters.groups.slice(0, level)
        const group = store.report.filters.groups[level]
        const cellLabel = format(
            periodStartDate,
            ResourceScheduleStore.periodFormat()
        )
        const currentPeriodStart = ResourceScheduleStore.startOfPeriod(
            new Date()
        )
        const periodStart = ResourceScheduleStore.startOfPeriod(periodStartDate)
        const periodEnd = ResourceScheduleStore.endOfPeriod(periodStart)
        const periodType =
            periodStart.getTime() === currentPeriodStart.getTime()
                ? 'current'
                : periodStart.getTime() > currentPeriodStart.getTime()
                  ? 'future'
                  : 'past'
        const isPastPeriod = periodType === 'past'
        const isFuturePeriod = periodType === 'future'
        const isCurrentPeriod = periodType === 'current'
        let queryId = 'hours'
        if (groupsBeforeLevel.includes('status'))
            queryId += String(filters.status)
        if (groupsBeforeLevel.includes('project'))
            queryId += String(filters.projectId)
        if (groupsBeforeLevel.includes('phase'))
            queryId += String(filters.phaseId)
        if (groupsBeforeLevel.includes('role'))
            queryId += String(filters.staffRoleId)
        if (groupsBeforeLevel.includes('staff'))
            queryId += String(filters.staffId)

        return {
            label: format(
                periodStartDate,
                ResourceScheduleStore.periodDisplayFormat()
            ),
            type: 'number',
            width: 8,
            format: (v) => FormatNumber(v, { decimals: 0 }),
            value: (row) => {
                return ResourceScheduleStore.getRowHoursInPeriod(
                    row,
                    format(
                        periodStartDate,
                        ResourceScheduleStore.periodFormat()
                    )
                )
            },
            component: (props) =>
                ResourceCellComponent(
                    props,
                    store,
                    format(
                        periodStartDate,
                        ResourceScheduleStore.periodFormat()
                    ),
                    [...keys, props.stores.row.rowObject.key]
                ),
            editable: () =>
                (isFuturePeriod ||
                    isCurrentPeriod ||
                    store.report.filters.hoursData === 'projected') &&
                ['projected', 'actualsProjected'].includes(
                    store.report.filters.hoursData
                ) &&
                canEditStaffAllocations(SessionStore.user) &&
                groupsAtLevel.includes('project'),
            style: (row, stores) => {
                return cellStyles(row, stores, periodStart, isPastPeriod)
            },
            aggregate: 'sum',
            onClick: (r) => () => {
                store.selectCell(periodStart, [...keys, r.key], r)
            },
            selected: (r) => {
                return (
                    store.selectedObject === r &&
                    format(
                        store.selectedPeriod,
                        ResourceScheduleStore.periodFormat()
                    ) ===
                        format(
                            periodStart,
                            ResourceScheduleStore.periodFormat()
                        )
                )
            },
            onChange: (r) => (v) => {
                v = v || 0
                store.setHours(
                    r,
                    format(
                        periodStartDate,
                        ResourceScheduleStore.periodFormat()
                    ),
                    v,
                    (period, newHours, projectId, phaseId, roleId, staffId) => {
                        ProjectSidebarStore.setHoursInPeriod(
                            format(
                                periodStartDate,
                                ResourceScheduleStore.periodFormat()
                            ),
                            projectId,
                            phaseId || null,
                            roleId || null,
                            staffId || null,
                            newHours,
                            ResourceScheduleStore.selectedPhaseIds,
                            ResourceScheduleStore.selectedStaffIds,
                            ResourceScheduleStore.periodType
                        )
                    }
                )
            },
        }
    },
    budgetUse: (level, filters) => {
        const colType = {
            budgetUse: 'progress',
            remainingBudget: 'number',
            averageUtilisation: 'percent',
        }
        const colVal = {
            budgetUse: (row) => {
                return {
                    numerator: store.getRowTotalHours(row) || 0,
                    denominator: store.getRowBudget(row) || 0,
                }
            },
            remainingBudget: (row) => {
                return (
                    (store.getRowBudget(row) || 0) -
                        store.getRowTotalHours(row) || 0
                )
            },
            averageUtilisation: (row) => {
                return _.mean(
                    [...Array(12)].map((v, i) => {
                        const period = format(
                            store.addPeriods(
                                store.startOfPeriod(store.startDate),
                                i
                            ),
                            store.periodFormat()
                        )
                        return (
                            store.getRowHoursInPeriod(row, period) /
                            store.getRowAvailabilityInPeriod(row, period)
                        )
                    })
                )
            },
        }
        return {
            id: 'budgetUse',
            label: (
                <span>
                    Total
                    <span
                        style={{ fontSize: '1.5em', float: 'right' }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <i
                            className={`fa fa-caret-left`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => store.shiftDates(-1)}
                        />
                        <i
                            className={`fa fa-caret-right`}
                            style={{ marginRight: 0, cursor: 'pointer' }}
                            onClick={() => store.shiftDates(1)}
                        />
                    </span>
                </span>
            ),
            type: colType[store.report.filters.totalData],
            width: 18,
            editable: (row) => false,
            value: (row) => {
                return colVal[store.report.filters.totalData](row)
            },
        }
    },
})

const ResourceCellComponent = (props, store, period, keys = []) => {
    const resourceRow = props.stores.row.rowObject
    const isTotalRow = props?.stores?.row?.id === 'totals'
    let progress = getProgressValue(store, period, resourceRow)
    let value = props.formattedValue
    const keyString = keys.join(',')
    if (
        ResourceScheduleStore.report.filters.hoursData === 'actualsVsProjected'
    ) {
        const actualHoursInPeriod = store.getRowHoursInPeriod(
            resourceRow,
            period,
            'actuals'
        )
        const projectedHoursInPeriod = store.getRowHoursInPeriod(
            resourceRow,
            period,
            'projected'
        )
        value = `${FormatNumber(actualHoursInPeriod, {
            decimals: 0,
        })} / ${FormatNumber(projectedHoursInPeriod, { decimals: 0 })}`
    }
    if (isFinite(progress)) {
        value += ` (${FormatPercent(progress || 0, { decimals: 0 })})`
    }
    return (
        <div {...{ ...props, onChange: null }}>
            {props.selected && props.editable
                ? CellComponent['number']({
                      ...props,
                      key: 'edit',
                  })
                : CellComponent['text']({
                      ...props,
                      key: 'display',
                      style: {
                          textAlign: 'right',
                      },
                      value: value,
                      formattedValue: value,
                      editable: false,
                      onChange: null,
                  })}
        </div>
    )
}

const getProgressValue = (store, period, row) => {
    let progress, allocatedHours, recordedHours
    switch (ResourceScheduleStore.report.filters.percentData) {
        case 'budgetUse':
            progress =
                store.getRowHoursToDateInPeriod(row, period) /
                store.getRowBudget(row)
            break
        case 'monthlyBudgetUse':
            progress =
                store.getRowHoursInPeriod(row, period) / store.getRowBudget(row)
            break
        case 'remainingMonthlyBudgetUse':
            allocatedHours = store.getRowHoursInPeriod(row, period, 'projected')
            recordedHours = store.getRowHoursInPeriod(row, period, 'actuals')
            progress = (allocatedHours - recordedHours) / allocatedHours
            break
        case 'remainingMonthlyBudgetUseCapped':
            allocatedHours = store.getRowHoursInPeriod(row, period, 'projected')
            recordedHours = store.getRowHoursInPeriod(row, period, 'actuals')
            progress =
                Math.max(allocatedHours - recordedHours, 0) / allocatedHours
            break
        case 'utilisation':
        default:
            progress =
                store.getRowHoursInPeriod(row, period) /
                store.getRowAvailabilityInPeriod(row, period)
            break
    }
    return progress
}
