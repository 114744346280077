import { observer } from 'mobx-react'
import React from 'react'
import Table from '../../Components/Table'
import ContactCollection from '../../State/Collections/ContactCollection'
import ContactStore from './ContactStore'
import { Link } from '@tanstack/react-router'

const columns = [
    {
        id: 'label',
        label: 'Label',
        type: 'text',
        width: 25,
        value: (row) => {
            return row.label
        },
        component: ({ value, stores }) => {
            const { row } = stores
            return (
                <div>
                    <Link to={`/contacts/${row.rowObject.id}`}>{value}</Link>
                </div>
            )
        },
    },
    {
        id: 'email',
        label: 'Email',
        type: 'text',
        width: 25,
        value: (row) => {
            return row.email
        },
    },
    {
        id: 'phone',
        label: 'Phone',
        type: 'text',
        width: 25,
        value: (row) => {
            return row.phone
        },
    },
    {
        id: 'address',
        label: 'Address',
        type: 'text',
        width: 25,
        value: (row) => {
            return row.address
        },
    },
]

export default observer(() => {
    return (
        <>
            <Table
                columns={columns}
                rows={ContactCollection.contacts.filter(
                    (c) =>
                        (ContactStore.searchFilter?.length > 0
                            ? c.label
                                  .toLowerCase()
                                  .includes(
                                      ContactStore.searchFilter.toLowerCase()
                                  )
                            : true) && !c.deletedAt
                )}
            />
        </>
    )
})
