import { observable, computed, action, makeObservable } from 'mobx'
import React from 'react'
import _ from 'lodash'
import TableColumnStore from './TableColumnStore'
import TableRowStore from './TableRowStore'
import tuple from 'immutable-tuple'
import TableCellStore from './TableCellStore'
import bind from 'bind-decorator'

class TableStore {
    @observable showHeader = true
    @observable showTotals = false
    @observable _rows = new Set()
    @observable _rowsByObject = new Map()
    @observable _columns = new Set()
    @observable _columnsById = new Map()
    @observable expandedRows = new Set()
    @observable sortBy = []
    @observable groups = []
    @observable expandedGroups = []
    @observable filters = []
    @observable reverseSort = false
    @observable newRowDirection = 'desc'
    @observable expandAll = false
    onExpand = () => null
    onCollapse = () => null

    constructor() {
        makeObservable(this)
    }

    @action.bound
    update({
        columns,
        rows,
        getChildComponent,
        showHeader,
        showTotals,
        sortBy,
        groupBy,
        filters,
        newRowDirection,
        expandedGroups,
        onExpand,
        onCollapse,
        expandAll,
    } = {}) {
        this.getChildComponent = getChildComponent ?? this.getChildComponent
        this.setupColumns(columns ?? this.columns)
        this.groups = groupBy ?? this.groups
        this.setupRows(rows)
        this.showHeader = showHeader ?? this.showHeader
        this.showTotals = showTotals ?? this.showTotals
        this.sortBy = sortBy ?? this.sortBy
        this.filters = filters ?? this.filters
        this.newRowDirection = newRowDirection ?? this.newRowDirection
        this.expandedGroups = expandedGroups ?? this.expandedGroups
        this.onExpand = onExpand ?? this.onExpand
        this.onCollapse = onCollapse ?? this.onCollapse
        if (expandAll) {
            this.rows.forEach((r) => {
                if (this.getChildComponent) {
                    this.expandedRows.add(r)
                }
            })
            this.expandAll = true
        } else if (!expandAll) {
            if (this.expandAll) {
                this.expandedRows.clear()
            }
            this.expandAll = false
        }
    }
    @action.bound
    updateRows(rows) {
        this.setupRows(rows)
    }
    @action.bound
    updateColumns(columns) {
        this.setupColumns(columns)
    }
    @action.bound
    updateGroups(groups) {
        this.groups = groups
    }
    @action.bound
    updateColumn(column) {
        this._columnsById.set(
            column.id || column,
            new TableColumnStore({ ...column, tableStore: this })
        )
        this._rows.forEach((r) => r.updateColumn(column))
    }
    @bind
    getOrCreateColumn(columnDef) {
        if (!this._columnsById.get(columnDef.id || columnDef)) {
            this._columnsById.set(
                columnDef.id || columnDef,
                new TableColumnStore({ ...columnDef, tableStore: this })
            )
        } else {
            this._columnsById.get(columnDef.id || columnDef).update(columnDef)
        }
        return this._columnsById.get(columnDef.id || columnDef)
    }
    @action
    setupColumns(columns) {
        this._columns = new Set(columns.map((c) => this.getOrCreateColumn(c)))
        this._columns.add(
            this.getOrCreateColumn({
                id: 'position',
                tableStore: this,
                label: '',
                width: 3,
                type: 'number',
                editable: (r) => false,
                visible: false,
                value: (r) => r.position,
            })
        )
        if (this.getChildComponent)
            this._columns = new Set([
                this.getOrCreateColumn({
                    id: 'expand',
                    tableStore: this,
                    label: '',
                    width: 3,
                    type: 'button',
                    print: false,
                    editable: (model, { row } = {}) => {
                        return row.id !== 'totals'
                    },
                    value: (model, { row } = {}) => {
                        if (row.id === 'totals') return null
                        return (
                            <i
                                className={
                                    'fa fa-chevron-' +
                                    (row.expanded ? 'down' : 'right')
                                }
                                style={{ marginRight: 0 }}
                            />
                        )
                    },
                    onClick: (rowObj, stores) => () => {
                        this.toggleRowExpand(stores.row)
                    },
                }),
                ...this._columns,
            ])
    }
    @bind
    getOrCreateRow(rowObject, { id, group } = {}) {
        if (!this._rowsByObject.get(id || rowObject?.id || rowObject)) {
            this._rowsByObject.set(
                id || rowObject?.id || rowObject,
                new TableRowStore({
                    id: id || rowObject?.id || rowObject,
                    rowObject: rowObject,
                    table: this,
                    childComponent: this.getChildComponent?.(rowObject),
                    group: group,
                    childRows: new Set(),
                })
            )
        }
        return this._rowsByObject.get(id || rowObject?.id || rowObject)
    }
    @action.bound
    setupRows(rowsData = []) {
        const noGroupRows = rowsData.map((r) => {
            return this.getOrCreateRow(r)
        })
        this._rows = new Set([...noGroupRows])
        let groupRows = {}
        noGroupRows.forEach((r) => {
            let prevGroupRow = null
            this.groups.forEach((gId, i) => {
                const groupId = tuple(
                    ...this.groups.map((gId2, i2) => {
                        return i2 <= i
                            ? r.cells[gId2]?.equalityComparatorValue
                            : undefined
                    })
                )
                const groupObject = r.cells[gId].value
                let groupRow = groupRows[groupId]
                if (!groupRow) {
                    groupRow = groupRows[groupId] = this.getOrCreateRow(
                        groupObject,
                        { id: groupId, group: gId }
                    )
                    groupRow = groupRows[groupId]
                    if (prevGroupRow) prevGroupRow._childRows.add(groupRow)
                    this._rows.add(groupRow)
                }
                if (i === this.groups.length - 1) {
                    groupRow._childRows.add(r)
                }
                prevGroupRow = groupRow
            })
        })
    }
    @computed
    get columnsById() {
        const columnsById = {}
        this._columns.forEach((c) => {
            columnsById[c.id] = c
        })
        return columnsById
    }
    @computed
    get columns() {
        return [...this._columns].filter((c) => c.visible)
    }
    @computed
    get rowsByGroup() {
        const rowsByGroup = {}
        this._rows.forEach((r) => {
            rowsByGroup[r.group] ??= []
            rowsByGroup[r.group].push(r)
        })
        return rowsByGroup
    }
    @computed
    get rows() {
        const newSort = (r) => (r.rowObject.isNew ? 0 : 1) // Adjusted to prioritize null values
        const newDir = this.newRowDirection
        const createdSort = (r) =>
            r.rowObject.initiatedAt
                ? new Date(r.rowObject.initiatedAt)
                : Infinity
        const createdDir = this.newRowDirection === 'asc' ? 'desc' : 'asc'
        const columnSort = this.sortBy.map(([prop, direction]) => (r) => {
            let val = null
            if (typeof prop === 'string' && r.cells[prop]) {
                val = r.cells[prop].sortComparatorValue ?? Infinity
            } else if (typeof prop === 'function') {
                val = prop(r.rowObject) ?? Infinity
            }
            return val
        })
        const columnDir = this.sortBy.map(([prop, direction]) => direction)

        return _.orderBy(
            this.rowsByGroup[this.groups?.[0]]?.filter((r) => r.visible),
            [...columnSort],
            [...columnDir]
        )
    }
    @action.bound
    toggleRowExpand(row) {
        if (this.expandedRows.has(row)) {
            this.expandedRows.delete(row)
            this.onCollapse(row.rowObject)
        } else {
            this.expandedRows.add(row)
            this.onExpand(row.rowObject)
        }
    }
    @computed
    get headerLabels() {
        return this.columns.map((c) => c.label)
    }
    @computed
    get totals() {
        return this.columns.map((c) => c.label)
    }
    @computed
    get width() {
        return _.sum(this.columns.map((c) => c.width))
    }
    @computed
    get printingWidth() {
        return _.sum(this.columns.filter((c) => c.print).map((c) => c.width))
    }
    @action.bound
    updateSort(column, direction) {
        this.sortBy = [[column, direction]]
    }
    @computed
    get csvData() {
        const columns = this.columns.filter(
            (c) => c.visible && c.id !== 'expand'
        )
        return [
            columns.map((c) => c.label),
            ...this.rows.map((r) =>
                columns.map((c) => {
                    return r.cells[c.id].formattedCSVValue
                })
            ),
        ]
    }
    @action.bound
    getCsvData({
        columns = this.columns.filter((c) => c.visible && c.id !== 'expand'),
        rows = this.rows,
        includeHeadings = true,
    } = {}) {
        const csv = []
        if (includeHeadings) csv.push(columns.map((c) => c.label))
        csv.push(
            ...rows.map((r) =>
                columns.map((c) => {
                    return r.cells[c.id].formattedCSVValue
                })
            )
        )
        return csv
    }
    @computed
    get totalsRow() {
        return new TableRowStore({
            id: 'totals',
            group: 'totals',
            childRows: new Set(this.rowsByGroup[this.groups?.[0]]),
            table: this,
        })
    }
}

export default TableStore
