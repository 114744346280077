import { createFileRoute, redirect } from '@tanstack/react-router'
import SessionStore from '../State/SessionStore'
import LoadingSpinner from '../Components/LoadingSpinner'

export const Route = createFileRoute('/')({
    beforeLoad: async () => {
        if (!SessionStore.initialized) await SessionStore.initialize()
        if (SessionStore.initialized && !SessionStore.authenticated) {
            throw redirect({ to: '/login' })
        } else {
            throw redirect({ to: '/projects' })
        }
    },
    pendingComponent: LoadingSpinner,
    component: LayoutComponent,
})

function LayoutComponent() {
    return LoadingSpinner
}
