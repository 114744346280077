import { observable, computed, action, makeObservable } from 'mobx'
import { router } from '../App.js'

class LayoutStore {
    @observable modals = new Map()
    @observable showSidebar = false
    constructor(props) {
        makeObservable(this)
    }
    @action.bound
    toggleSidebar(setSidebar) {
        this.showSidebar = setSidebar ?? !this.showSidebar
    }
    @computed
    get displayedModals() {
        return this.modals.values()
    }
    openModal(modal) {
        if (!this.modals.has(modal.props.modalId)) {
            this.modals.set(modal.props.modalId, modal)
        }
    }
    closeModal(modalId) {
        this.modals.delete(modalId)
        router.navigate({
            search: (prev) => {
                delete prev.modal
                return prev
            },
        })
    }
}

export default new LayoutStore()
