'use client'

import * as React from 'react'
import { useSearch, useNavigate } from '@tanstack/react-router'
import type { DataTableFilterField } from '@2/types'
import {
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
    type ColumnDef,
    type ColumnFiltersState,
    type PaginationState,
    type SortingState,
    type VisibilityState,
} from '@tanstack/react-table'
import { z } from 'zod'

import { useDebounce } from '@2/hooks/use-debounce'

interface UseDataTableProps<TData, TValue> {
    data: TData[]
    columns: ColumnDef<TData, TValue>[]
    pageCount: number
    defaultPerPage?: number
    defaultSort?: `${Extract<keyof TData, string | number>}.${'asc' | 'desc'}`
    filterFields?: DataTableFilterField<TData>[]
    enableAdvancedFilter?: boolean
}

const schema = z.object({
    page: z.coerce.number().default(1),
    per_page: z.coerce.number().optional(),
    sort: z.string().optional(),
})

export function useDataTable<TData, TValue>({
    data,
    columns,
    pageCount,
    defaultPerPage = 10,
    defaultSort,
    filterFields = [],
    enableAdvancedFilter = false,
}: UseDataTableProps<TData, TValue>) {
    const navigate = useNavigate()
    const search = useSearch({ strict: false })

    // Search params
    const parsedSearch = schema.parse(search)
    const page = parsedSearch.page
    const perPage = parsedSearch.per_page ?? defaultPerPage
    const sort = parsedSearch.sort ?? defaultSort
    const [column, order] = sort?.split('.') ?? []

    // Memoize computation of searchableColumns and filterableColumns
    const { searchableColumns, filterableColumns } = React.useMemo(() => {
        return {
            searchableColumns: filterFields.filter((field) => !field.options),
            filterableColumns: filterFields.filter((field) => field.options),
        }
    }, [filterFields])

    // Create query string
    const createQueryString = React.useCallback(
        (params: Record<string, string | number | null>) => {
            const newSearch = { ...search }

            for (const [key, value] of Object.entries(params)) {
                if (value === null) {
                    delete newSearch[key]
                } else {
                    newSearch[key] = String(value)
                }
            }

            return newSearch
        },
        [search]
    )

    // Initial column filters
    const initialColumnFilters: ColumnFiltersState = React.useMemo(() => {
        return Object.entries(search).reduce<ColumnFiltersState>(
            (filters, [key, value]) => {
                const filterableColumn = filterableColumns.find(
                    (column) => column.value === key
                )
                const searchableColumn = searchableColumns.find(
                    (column) => column.value === key
                )

                if (filterableColumn) {
                    filters.push({
                        id: key,
                        value: value.split('.'),
                    })
                } else if (searchableColumn) {
                    filters.push({
                        id: key,
                        value: [value],
                    })
                }

                return filters
            },
            []
        )
    }, [filterableColumns, searchableColumns, search])

    // Table states
    const [rowSelection, setRowSelection] = React.useState({})
    const [columnVisibility, setColumnVisibility] =
        React.useState<VisibilityState>({})
    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>(initialColumnFilters)

    // Handle server-side pagination
    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: page - 1,
            pageSize: perPage,
        })

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    // Handle server-side sorting
    const [sorting, setSorting] = React.useState<SortingState>([
        {
            id: column ?? '',
            desc: order === 'desc',
        },
    ])

    React.useEffect(() => {
        navigate({
            search: createQueryString({
                page: pageIndex + 1,
                per_page: pageSize,
                sort: sorting[0]?.id
                    ? `${sorting[0]?.id}.${sorting[0]?.desc ? 'desc' : 'asc'}`
                    : null,
            }),
            replace: true,
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, pageSize, sorting])

    // Handle server-side filtering
    const debouncedSearchableColumnFilters = JSON.parse(
        useDebounce(
            JSON.stringify(
                columnFilters.filter((filter) => {
                    return searchableColumns.find(
                        (column) => column.value === filter.id
                    )
                })
            ),
            500
        )
    ) as ColumnFiltersState

    const filterableColumnFilters = columnFilters.filter((filter) => {
        return filterableColumns.find((column) => column.value === filter.id)
    })

    const [mounted, setMounted] = React.useState(false)

    React.useEffect(() => {
        // Opt out when advanced filter is enabled, because it contains additional params
        if (enableAdvancedFilter) return

        // Prevent resetting the page on initial render
        if (!mounted) {
            setMounted(true)
            return
        }

        // Initialize new params
        const newParamsObject = {
            page: 1,
        }

        // Handle debounced searchable column filters
        for (const column of debouncedSearchableColumnFilters) {
            if (typeof column.value === 'string') {
                Object.assign(newParamsObject, {
                    [column.id]:
                        typeof column.value === 'string' ? column.value : null,
                })
            }
        }

        // Handle filterable column filters
        for (const column of filterableColumnFilters) {
            if (
                typeof column.value === 'object' &&
                Array.isArray(column.value)
            ) {
                Object.assign(newParamsObject, {
                    [column.id]: column.value.join('.'),
                })
            }
        }

        // Remove deleted values
        for (const key in search) {
            if (
                (searchableColumns.find((column) => column.value === key) &&
                    !debouncedSearchableColumnFilters.find(
                        (column) => column.id === key
                    )) ||
                (filterableColumns.find((column) => column.value === key) &&
                    !filterableColumnFilters.find(
                        (column) => column.id === key
                    ))
            ) {
                Object.assign(newParamsObject, { [key]: null })
            }
        }

        // After cumulating all the changes, push new params
        navigate({
            search: createQueryString(newParamsObject),
            replace: true,
        })

        table.setPageIndex(0)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(debouncedSearchableColumnFilters),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(filterableColumnFilters),
    ])

    const table = useReactTable({
        data,
        columns,
        pageCount: pageCount ?? -1,
        state: {
            pagination,
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        manualPagination: true,
        manualSorting: true,
        manualFiltering: true,
    })

    return { table }
}
