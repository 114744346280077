import { observable, computed, action, makeObservable, toJS } from 'mobx'
import React from 'react'
import _ from 'lodash'
import {
    addMonths,
    endOfMonth,
    format,
    parse,
    startOfMonth,
    subMonths,
    addWeeks,
    endOfWeek,
    startOfWeek,
    subWeeks,
    startOfISOWeek,
    endOfISOWeek,
} from 'date-fns'
import { editAllocationsInDateRange } from '../../../Utils/allocationHelpers'
import ProjectExpenseAllocationCollection from '../../../State/Collections/ProjectExpenseAllocationCollection'
import { editRevenueTargetsInDateRange } from '../../../Utils/revenueTargetHelpers'
import LayoutStore from '../../../State/LayoutStore'
import {
    canEditProjectExpenses,
    canEditRevenueTargets,
    canEditStaffAllocations,
    canViewProjectExpenses,
    canViewProjectFees,
    canViewProjectStaffCost,
    canViewRevenueTargets,
    canViewStaffAllocations,
    canViewStaffCostRate,
    shouldUpdateHoursFromRevenue,
    shouldUpdateRevenueFromHours,
} from '../../../State/Permissions/HasPermissions'
import SessionStore from '../../../State/SessionStore'
import Formatter from '../../../Components/Formatter'
import ResourceRowModel from '../../../State/Models/ResourceRowModel'
import ExpenseRowModel from '../../../State/Models/ExpenseRowModel'
import RevenueRowModel from '../../../State/Models/RevenueRowModel'
import ProjectCollection from '../../../State/Collections/ProjectCollection'
import { computedFn } from 'mobx-utils'
import sortPhases from '../../../Utils/sortPhases'
import RoleCollection from '../../../State/Collections/RoleCollection'
import StaffCollection from '../../../State/Collections/StaffCollection'
import { makeRequest } from '../../../Queries/makeRequest'
import getCombinedRateInDateRange from '../../../Utils/getCombinedRateInDateRange'
import PhaseCollection from '../../../State/Collections/PhaseCollection'
import { bind, get } from 'underscore'
import cuid from 'cuid'
import { FormatNumber } from '../../../Utils/Localisation/NumberFormatter'
import { FormatCurrency } from '../../../Utils/Localisation/CurrencyFormatter'

const spacerColumn = {
    label: '',
    width: 3,
    type: 'text',
    value: (r) => '',
    onChange: (r) => (v) => null,
}

const noRole = { id: 'norole', title: '(No Role)', rates: [] }
const noStaff = { id: 'nostaff', title: '(No Staff)', rates: [] }

const dateFuncs = {
    month: {
        add: addMonths,
        sub: subMonths,
        start: startOfMonth,
        end: endOfMonth,
        formatString: 'yyyy-MM',
        displayFormatString: 'MMM yy',
    },
    week: {
        add: addWeeks,
        sub: subWeeks,
        start: startOfISOWeek,
        end: endOfISOWeek,
        formatString: 'RRRR-II',
        displayFormatString: 'dd MMM yy',
    },
}

class ProjectForecastStore {
    @observable filterByPhase = null
    @observable graphType = 'hoursBudget'
    @observable graphData = 'actualsProjected'
    @observable startDate = startOfMonth(subMonths(new Date(), 3))
    @observable endDate = endOfMonth(addMonths(new Date(), 8))
    @observable selectedTable = null
    @observable selectedObject = null
    @observable selectedProjectOrPhase = null
    @observable selectedPeriod = null
    @observable updateRevenueWithExpenses =
        shouldUpdateRevenueFromHours() ?? false
    @observable updateExpensesWithRevenue =
        shouldUpdateHoursFromRevenue() ?? false
    @observable queryData = null
    @observable phaseRoleRowsByPhaseId = {}
    @observable phaseStaffRowsByPhaseRoleId = {}
    @observable editedRevenueTargets = {}
    @observable editedAllocations = {}
    @observable editedExpenses = {}
    @observable project = null
    @observable projectStaff = new Set()
    @observable staffByPhase = {}
    @observable saveState = 'idle'
    @observable uuid = cuid()
    @observable savingIds = new Set()
    @observable periodType = 'month'

    constructor() {
        makeObservable(this)
    }

    setPeriodType = (type) => {
        this.periodType = type
        this.editedRevenueTargets[this.project?.id][this.periodType] ??= {}
        this.editedAllocations[this.project?.id][this.periodType] ??= {}
        this.editedExpenses[this.project?.id][this.periodType] ??= {}
    }

    addPeriods = (...params) => {
        return dateFuncs[this.periodType].add(...params)
    }
    subPeriods = (...params) => {
        return dateFuncs[this.periodType].sub(...params)
    }
    startOfPeriod = (...params) => {
        return dateFuncs[this.periodType].start(...params)
    }
    endOfPeriod = (...params) => {
        return dateFuncs[this.periodType].end(...params)
    }
    periodFormat = () => {
        return dateFuncs[this.periodType].formatString
    }

    periodDisplayFormat = () => {
        return dateFuncs[this.periodType].displayFormatString
    }

    init(queryData, period, selectedProjectOrPhase, periodType) {
        this.queryData = queryData
        const project = ProjectCollection.projectsById[queryData.projects[0].id]
        const isNewProject = this.project?.id !== project.id
        this.project = project
        this.graphType =
            canViewRevenueTargets(SessionStore.user, project) &&
            canViewProjectExpenses(SessionStore.user, project) &&
            canViewProjectFees(SessionStore.user, project) &&
            canViewProjectStaffCost(SessionStore.user, project)
                ? 'revenueVsExpenses'
                : 'hoursBudget'
        this.graphData = 'actualsProjected'
        this.startDate = this.startOfPeriod(this.subPeriods(new Date(), 3))
        this.endDate = this.endOfPeriod(this.addPeriods(new Date(), 8))
        this.selectedTable = null
        this.selectedObject = null
        this.selectedPeriod = null
        this.editedRevenueTargets[this.project?.id] ??= {}
        this.editedAllocations[this.project?.id] ??= {}
        this.editedExpenses[this.project?.id] ??= {}
        this.generatePhaseStaffAndRoleRows()
        if (periodType) this.setPeriodType(periodType)
        if (period) {
            this.selectedPeriod = period
        }
        if (selectedProjectOrPhase) {
            this.selectedProjectOrPhase = selectedProjectOrPhase
        }
        this.editedRevenueTargets[this.project?.id][this.periodType] ??= {}
        this.editedAllocations[this.project?.id][this.periodType] ??= {}
        this.editedExpenses[this.project?.id][this.periodType] ??= {}
        this.uuid = cuid()
    }

    @action.bound
    reset() {
        this.graphType = 'revenueVsExpenses'
        this.graphData = 'actualsProjected'
        this.startDate = this.startOfPeriod(this.subPeriods(new Date(), 3))
        this.endDate = this.endOfPeriod(this.addPeriods(new Date(), 8))
        this.selectedTable = null
        this.selectedObject = null
        this.selectedPeriod = null
        this.updateRevenueWithExpenses = shouldUpdateRevenueFromHours()
        this.updateExpensesWithRevenue = shouldUpdateHoursFromRevenue()
        this.editedRevenueTargets = {}
        this.editedAllocations = {}
        this.editedExpenses = {}
    }

    @action.bound
    setFilterByPhase(phase) {
        this.filterByPhase = phase
    }

    @action.bound
    setUpdateRevenueWithExpenses(value) {
        this.updateRevenueWithExpenses = value
    }

    @action.bound
    setUpdateExpensesWithRevenue(value) {
        this.updateExpensesWithRevenue = value
    }

    @action.bound
    generatePhaseStaffAndRoleRows() {
        const phaseRoleRowsByPhaseId = {}
        const phaseStaffRowsByPhaseRoleId = {}
        ;[
            ...this.queryData.timeEntries,
            ...this.queryData.allocations,
            ...this.queryData.projects[0].phases.flatMap((ph) => ph.budgets),
        ].forEach((item) => {
            item.roleId ??= 'norole'
            item.staffId ??= 'nostaff'
            const staff = StaffCollection.staffById[item.staffId] || noStaff
            const role =
                staff?.role || RoleCollection.rolesById[item.roleId] || noRole
            phaseRoleRowsByPhaseId[item.phaseId] ??= new Set()
            phaseRoleRowsByPhaseId[item.phaseId].add(role || '(No Role)')
            if (staff) {
                phaseStaffRowsByPhaseRoleId[item.phaseId + role?.id] ??=
                    new Set()
                phaseStaffRowsByPhaseRoleId[item.phaseId + role?.id].add(staff)
            }
            this.projectStaff.add(staff)
            this.staffByPhase[item.phaseId] ??= new Set()
            this.staffByPhase[item.phaseId].add(staff)
        })
        this.phaseRoleRowsByPhaseId = Object.fromEntries(
            Object.entries(phaseRoleRowsByPhaseId).map(([k, v]) => [
                k,
                Array.from(v),
            ])
        )
        this.phaseStaffRowsByPhaseRoleId = Object.fromEntries(
            Object.entries(phaseStaffRowsByPhaseRoleId).map(([k, v]) => [
                k,
                Array.from(v),
            ])
        )
    }

    @computed
    get periods() {
        const periods = new Set([
            ...this.queryData.timeEntries.map((i) => i[this.periodType]),
            ...this.queryData.invoices.map((i) => i[this.periodType]),
            ...this.queryData.allocations.map((e) => e[this.periodType]),
            ...this.queryData.expenseAllocations.map((e) => e[this.periodType]),
            ...this.queryData.changeLog.map((r) => r[this.periodType]),
            ...this.queryData.revenueTargets.map((rt) => rt[this.periodType]),
            ...Object.values(
                this.editedAllocations[this.project?.id][this.periodType]
            ).map((a) => a[this.periodType]),
            ...Object.values(
                this.editedRevenueTargets[this.project?.id][this.periodType]
            ).map((rt) => rt[this.periodType]),
            ...Object.values(
                this.editedExpenses[this.project?.id][this.periodType]
            ).map((rt) => rt[this.periodType]),
        ])
        return Array.from(periods).sort()
    }

    @computed
    get maxPeriod() {
        return this.periods[this.periods.length - 1]
    }

    @computed
    get minPeriod() {
        return this.periods[0]
    }

    getInvoiceRevenueInPeriod = computedFn((period, phaseId) => {
        return _.sum(
            this.queryData.invoices
                .filter(
                    (inv) =>
                        (!phaseId || inv.phaseId === phaseId) &&
                        inv[this.periodType] === period
                )
                .map((inv) => inv.amount)
        )
    })

    getChangeLogRevenueInPeriod = computedFn((period, phaseId) => {
        return _.sum(
            this.queryData.changeLog
                .filter(
                    (cli) =>
                        (!phaseId || cli.phaseId === phaseId) &&
                        cli[this.periodType] === period
                )
                .map((cli) => cli.revenue)
        )
    })

    getRevenueTargetsInPeriod = computedFn((period, phaseId) => {
        return _.sum(
            this.queryData.revenueTargets
                .filter(
                    (rt) =>
                        (!phaseId || rt.phaseId === phaseId) &&
                        rt[this.periodType] === period
                )
                .map((r) => r.revenue)
        )
    })

    getEditedRevenueInPeriod = computedFn((period, phaseId) => {
        if (!phaseId) {
            return _.sum(
                this.queryData.projects[0].phases.map((p) =>
                    this.getEditedRevenueInPeriod(period, p.id)
                )
            )
        }
        return this.editedRevenueTargets[this.project?.id][this.periodType][
            period + phaseId
        ]?.revenue
    })

    @action.bound
    setEditedRevenueInPeriod = (
        period,
        phaseId,
        value,
        updateExpensesWithRevenue
    ) => {
        if (!phaseId) {
            const existingRevenue = this.getRevenueInPeriod(period, null)
            const ratio = existingRevenue && value / existingRevenue
            this.queryData.projects[0].phases.forEach((p) => {
                const existingVal = this.getRevenueInPeriod(period, p.id)
                const revenue = ratio ? existingVal * ratio : value / p.fee
                this.editedRevenueTargets[this.project?.id][this.periodType][
                    period + p.id
                ] = {
                    period,
                    periodType: this.periodType,
                    phaseId: p.id,
                    revenue,
                }
            })
        } else {
            this.editedRevenueTargets[this.project?.id][this.periodType][
                period + phaseId
            ] = {
                periodType: this.periodType,
                period,
                phaseId,
                revenue: value,
            }
        }
        this.prepareToSave()
    }

    @action.bound
    setEditedRevenueProgressInPeriod = (period, phaseId, percent) => {
        if (!phaseId) {
            return this.queryData.projects[0].phases.forEach((p) => {
                this.setEditedRevenueProgressInPeriod(period, p.id, percent)
            })
        }
        const periodBefore = format(
            this.subPeriods(
                parse(period, this.periodFormat(), new Date(), {
                    weekStartsOn: 1,
                }),
                1
            ),
            this.periodFormat()
        )
        const revenueBeforePeriod = this.getRevenueToDateInPeriod(
            periodBefore,
            phaseId
        )

        const newRevenue = Math.max(
            percent * PhaseCollection.phasesById[phaseId].fee -
                revenueBeforePeriod,
            0
        )
        this.editedRevenueTargets[this.project?.id][this.periodType][
            period + phaseId
        ] = {
            period,
            periodType: this.periodType,
            phaseId,
            revenue: newRevenue,
        }
        this.prepareToSave()
    }

    getFee = (phaseId) => {
        if (!phaseId) {
            return _.sum(
                this.queryData.projects[0].phases.map((p) => this.getFee(p.id))
            )
        }
        return PhaseCollection.phasesById[phaseId]?.fee
    }

    getRevenueProgressInPeriod(period, phaseId) {
        return (
            this.getRevenueToDateInPeriod(period, phaseId) /
            this.getFee(phaseId)
        )
    }

    @action.bound
    async prepareToSave() {
        const DataStoreModule = await import('../../../State/DataStore')
        const DataStore = DataStoreModule.default
        const organisationId = SessionStore.organisationId
        const projectId = this.project.id
        const periodType = this.periodType
        const revenueTargets = Object.values(
            this.editedRevenueTargets[projectId][periodType]
        )
        const allocations = Object.values(
            this.editedAllocations[projectId][periodType]
        )
        const expenses = Object.values(
            this.editedExpenses[projectId][periodType]
        )
        DataStore.addSaveFunction(`${projectId}-forecasts`, () =>
            this.saveChanges({
                id: `${projectId}-forecasts`,
                organisationId,
                projectId,
                revenueTargets,
                allocations,
                expenses,
                periodType,
            })
        )
    }

    @action.bound
    async saveChanges({
        id,
        organisationId,
        projectId,
        revenueTargets,
        allocations,
        expenses,
        periodType,
    }) {
        if (this.savingIds.has(id)) return
        this.savingIds.add(id)
        return makeRequest({
            baseURL: process.env.REACT_APP_NODE_SERVER_URL,
            path: '/project-forecast/save',
            method: 'POST',
            data: {
                organisationId,
                projectId,
                revenueTargets,
                allocations,
                expenses,
                periodType,
            },
        }).then((res) => {
            this.savingIds.delete(id)
            return res
        })
    }

    getRevenueInPeriod = computedFn((period, phaseId) => {
        if (!period) return 0
        if (!phaseId) {
            return _.sum(
                this.queryData.projects[0].phases.map((p) =>
                    this.getRevenueInPeriod(period, p.id)
                )
            )
        }
        const thisPeriod = format(new Date(), this.periodFormat())
        const isPastPeriod = period < thisPeriod
        const isFuturePeriod = period > thisPeriod
        const isCurrentPeriod = period === thisPeriod
        if (isPastPeriod) {
            const invoiceRevenue = this.getInvoiceRevenueInPeriod(
                period,
                phaseId
            )
            const changeLogRevenue = this.getChangeLogRevenueInPeriod(
                period,
                phaseId
            )
            return invoiceRevenue + changeLogRevenue
        }
        if (isFuturePeriod) {
            const revenueTargets = this.getRevenueTargetsInPeriod(
                period,
                phaseId
            )
            const editedRevenue = this.getEditedRevenueInPeriod(period, phaseId)
            return editedRevenue ?? revenueTargets
        }
        if (isCurrentPeriod) {
            const revenueTargets = this.getRevenueTargetsInPeriod(
                period,
                phaseId
            )
            const editedRevenue = this.getEditedRevenueInPeriod(period, phaseId)
            const invoiceRevenue = this.getInvoiceRevenueInPeriod(
                period,
                phaseId
            )
            const changeLogRevenue = this.getChangeLogRevenueInPeriod(
                period,
                phaseId
            )
            return Math.max(
                editedRevenue ?? revenueTargets,
                invoiceRevenue + changeLogRevenue
            )
        }
        return 0
    })

    getRevenueToDateInPeriod = computedFn((period, phaseId) => {
        const periodsPrior = this.periods.filter((m) => m <= period)
        return _.sum(
            periodsPrior.map((m) => this.getRevenueInPeriod(m, phaseId))
        )
    })

    getTotalRevenue = computedFn((phaseId) => {
        return _.sum(
            this.periods.map((m) => this.getRevenueInPeriod(m, phaseId))
        )
    })

    getTimeEntryCostInPeriod = computedFn(
        (period, phaseId, roleId, staffId) => {
            return _.sum(
                this.queryData.timeEntries
                    .filter((te) => {
                        const staff =
                            StaffCollection.staffById[te.staffId] || noStaff
                        const role =
                            staff?.role ||
                            RoleCollection.rolesById[te.roleId] ||
                            noRole
                        return (
                            (!phaseId || te.phaseId === phaseId) &&
                            te[this.periodType] === period &&
                            (!staffId || staff?.id === staffId) &&
                            (!roleId || role?.id === roleId)
                        )
                    })
                    .map((te) => te.cost)
            )
        }
    )

    getEditedAllocationCostInPeriod = computedFn(
        (period, phaseId, roleId, staffId) => {
            if (!phaseId) {
                return _.sum(
                    this.queryData.projects[0].phases.map((p) =>
                        this.getEditedAllocationCostInPeriod(
                            period,
                            p.id,
                            roleId,
                            staffId
                        )
                    )
                )
            }
            return this.editedAllocations[this.project?.id][this.periodType][
                period + phaseId + roleId + staffId
            ]?.cost
        }
    )

    getLeafPhaseIds = computedFn((phaseId, roleId, staffId) => {
        const leafIds = []
        const phaseIds = phaseId
            ? [phaseId]
            : this.queryData.projects[0].phases.map((p) => p?.id)
        phaseIds.forEach((phId) => {
            const roleIds = roleId
                ? [roleId]
                : (this.phaseRoleRowsByPhaseId[phId] || []).map((r) => r?.id)
            roleIds.forEach((rId) => {
                const staffIds = staffId
                    ? [staffId]
                    : (this.phaseStaffRowsByPhaseRoleId[phId + rId] || []).map(
                          (s) => s?.id
                      )
                staffIds.forEach((sId) => {
                    leafIds.push([phId, rId, sId])
                })
            })
        })
        return leafIds
    })

    getLeafModels = computedFn((phaseId, roleId, staffId) => {
        const leafModels = []
        const phases = phaseId
            ? [PhaseCollection.phasesById[phaseId]]
            : this.queryData.projects[0].phases
        phases.forEach((ph) => {
            const roles = roleId
                ? [RoleCollection.rolesById[roleId]]
                : this.phaseRoleRowsByPhaseId[ph.id] || []
            roles.forEach((r) => {
                const staffs = staffId
                    ? [StaffCollection.staffById[staffId]]
                    : this.phaseStaffRowsByPhaseRoleId[ph.id + r.id] || []
                staffs.forEach((s) => {
                    leafModels.push({
                        project: this.project,
                        phase: ph,
                        status: ph.status,
                        role: r,
                        staff: s,
                    })
                })
            })
        })
        return leafModels
    })

    getLeafBudgetIds(phaseId, roleId, staffId) {
        const leafIds = []
        const phaseIds = phaseId
            ? [phaseId]
            : this.queryData.projects[0].phases.map((p) => p?.id)
        phaseIds.forEach((phId) => {
            const budgets =
                this.queryData.projects[0].phases.find((p) => p.id === phId)
                    ?.budgets || []
            const filteredBudgets = budgets.filter((b) => {
                const bStaff = StaffCollection.staffById[b.staffId]
                const bRoleId = bStaff?.roleId || b.roleId
                return (
                    (!roleId ||
                        (!bRoleId && roleId === 'norole') ||
                        bRoleId === roleId) &&
                    (!staffId ||
                        b.staffId === staffId ||
                        (!b.staffId && staffId === 'nostaff'))
                )
            })
            leafIds.push(
                ...filteredBudgets.map((b) => [phId, b.roleId, b.staffId])
            )
        })
        return leafIds
    }

    @action.bound
    setEditedCostInPeriod = (period, phaseId, roleId, staffId, value) => {
        const dateRange = [
            parse(period, this.periodFormat(), new Date(), { weekStartsOn: 1 }),
            this.endOfPeriod(
                parse(period, this.periodFormat(), new Date(), {
                    weekStartsOn: 1,
                })
            ),
        ]
        const ids = this.getLeafPhaseIds(phaseId, roleId, staffId).filter(
            ([p, r, s]) => {
                const staff = StaffCollection.staffById[s]
                return (
                    staff?.id ||
                    (staff?.isArchived === false &&
                        isFinite(
                            getCombinedRateInDateRange(
                                {
                                    project: this.project,
                                    phase: PhaseCollection.phasesById[p],
                                    staff: staff || noStaff,
                                    role: RoleCollection.rolesById[r] || noRole,
                                },
                                'cost',
                                dateRange
                            )
                        ))
                )
            }
        )
        const existingCost = this.getCostInPeriod(
            period,
            phaseId,
            roleId,
            staffId
        )
        const ratio = existingCost && value / existingCost
        ids.forEach(([p, r, s]) => {
            const models = {
                project: this.project,
                phase: PhaseCollection.phasesById[p],
                staff: StaffCollection.staffById[s] || noStaff,
                role: RoleCollection.rolesById[r] || noRole,
            }
            const existingVal = this.getCostInPeriod(period, p, r, s)
            const cost = ratio ? existingVal * ratio : value / ids.length
            const hours =
                value / getCombinedRateInDateRange(models, 'cost', dateRange)
            const pay =
                hours * getCombinedRateInDateRange(models, 'pay', dateRange)
            const chargeOut =
                hours *
                getCombinedRateInDateRange(models, 'chargeOut', dateRange)
            this.editedAllocations[this.project?.id][this.periodType][
                period + p + r + s
            ] = {
                period,
                periodType: this.periodType,
                phaseId: p,
                roleId: r,
                staffId: s,
                hours,
                pay,
                cost,
                chargeOut,
            }
        })
        this.prepareToSave()
    }

    setEditedCostProgressInPeriod = (
        period,
        phaseId,
        roleId,
        staffId,
        percent
    ) => {
        const dateRange = [
            parse(period, this.periodFormat(), new Date(), { weekStartsOn: 1 }),
            this.endOfPeriod(
                parse(period, this.periodFormat(), new Date(), {
                    weekStartsOn: 1,
                })
            ),
        ]
        const ids = this.getLeafPhaseIds(phaseId, roleId, staffId).filter(
            ([p, r, s]) => {
                const staff = StaffCollection.staffById[s]
                return (
                    staffId ||
                    (staff?.isArchived === false &&
                        isFinite(
                            getCombinedRateInDateRange(
                                {
                                    project: this.project,
                                    phase: PhaseCollection.phasesById[p],
                                    staff: staff || noStaff,
                                    role: RoleCollection.rolesById[r] || noRole,
                                },
                                'cost',
                                dateRange
                            )
                        ))
                )
            }
        )
        ids.forEach(([p, r, s]) => {
            const periodBefore = format(
                this.subPeriods(
                    parse(period, this.periodFormat(), new Date(), {
                        weekStartsOn: 1,
                    }),
                    1
                ),
                this.periodFormat()
            )
            const costBeforePeriod = this.getCostToDateInPeriod(
                periodBefore,
                p,
                r,
                s
            )
            const budget = this.getCostBudget(p, r, s)
            const newCost = Math.max(budget * percent - costBeforePeriod, 0)
            this.setEditedCostInPeriod(period, p, r, s, newCost)
        })
        this.prepareToSave()
    }

    getCostProgressInPeriod = (period, phaseId, roleId, staffId) => {
        return (
            this.getCostToDateInPeriod(period, phaseId, roleId, staffId) /
            this.getCostBudget(phaseId, roleId, staffId)
        )
    }

    getHoursProgressInPeriod = (period, phaseId, roleId, staffId) => {
        return (
            this.getHoursToDateInPeriod(period, phaseId, roleId, staffId) /
            this.getHoursBudget(phaseId, roleId, staffId)
        )
    }

    getAllocationCostInPeriod = computedFn(
        (period, phaseId, roleId, staffId) => {
            return _.sum(
                this.queryData.allocations
                    .filter((a) => {
                        const staff =
                            StaffCollection.staffById[a.staffId] || noStaff
                        const role =
                            staff?.role ||
                            RoleCollection.rolesById[a.roleId] ||
                            noRole
                        return (
                            (!phaseId || a.phaseId === phaseId) &&
                            a[this.periodType] === period &&
                            (!staffId || staff?.id === staffId) &&
                            (!roleId || role?.id === roleId)
                        )
                    })
                    .map((a) => a.cost)
            )
        }
    )

    getCostInPeriod = computedFn((period, phaseId, roleId, staffId) => {
        if (!period) return 0
        if (!staffId || !phaseId) {
            const ids = this.getLeafPhaseIds(phaseId, roleId, staffId)
            return _.sum(
                ids.map(([p, r, s]) => {
                    if (!p || !s) return 0
                    return this.getCostInPeriod(period, p, r || null, s)
                })
            )
        }
        const thisPeriod = format(new Date(), this.periodFormat())
        const isPastPeriod = period < thisPeriod
        const isFuturePeriod = period > thisPeriod
        const isCurrentPeriod = period === thisPeriod
        if (isPastPeriod) {
            return this.getTimeEntryCostInPeriod(
                period,
                phaseId,
                roleId,
                staffId
            )
        }
        if (isFuturePeriod) {
            const edits = this.getEditedAllocationCostInPeriod(
                period,
                phaseId,
                roleId,
                staffId
            )
            const allocations = this.getAllocationCostInPeriod(
                period,
                phaseId,
                roleId,
                staffId
            )
            return edits ?? allocations
        }
        if (isCurrentPeriod) {
            const edits = this.getEditedAllocationCostInPeriod(
                period,
                phaseId,
                roleId,
                staffId
            )
            const allocations = this.getAllocationCostInPeriod(
                period,
                phaseId,
                roleId,
                staffId
            )
            return Math.max(
                this.getTimeEntryCostInPeriod(period, phaseId, roleId, staffId),
                edits ?? allocations
            )
        }
        return 0
    })

    getCostToDateInPeriod = computedFn((period, phaseId, roleId, staffId) => {
        const periodsPrior = this.periods.filter((m) => m <= period)
        return _.sum(
            periodsPrior.map((m) =>
                this.getCostInPeriod(m, phaseId, roleId, staffId)
            )
        )
    })

    getTotalCost = computedFn((phaseId, roleId, staffId) => {
        return _.sum(
            this.periods.map((m) =>
                this.getCostInPeriod(m, phaseId, roleId, staffId)
            )
        )
    })

    getCostBudget = computedFn((phaseId, roleId, staffId) => {
        if (!phaseId || !(roleId || staffId)) {
            const ids = this.getLeafBudgetIds(phaseId, roleId, staffId)
            return _.sum(
                ids.map(([p, r, s]) => {
                    if (!p) return 0
                    return this.getCostBudget(p, r, s) || 0
                })
            )
        }
        const budget = this.queryData.projects[0].phases
            .find((p) => p.id === phaseId)
            ?.budgets.filter(
                (b) =>
                    (staffId && b.staffId === staffId) ||
                    (!staffId &&
                        roleId &&
                        (b.roleId === roleId ||
                            (!b.roleId && roleId === 'norole')))
            )
        if (!budget?.length) return 0
        return _.sum(
            budget.map(
                (b) =>
                    b.hours *
                    getCombinedRateInDateRange(
                        {
                            project: this.project,
                            phase: PhaseCollection.phasesById[b.phaseId],
                            staff: StaffCollection.staffById[b.staffId],
                            role: RoleCollection.rolesById[b.roleId],
                        },
                        'cost',
                        [
                            PhaseCollection.phasesById[b.phaseId].startDate,
                            PhaseCollection.phasesById[b.phaseId].endDate,
                        ]
                    )
            )
        )
    })

    getTimeEntryHoursInPeriod = computedFn(
        (period, phaseId, roleId, staffId) => {
            return _.sum(
                this.queryData.timeEntries
                    .filter((te) => {
                        const staff =
                            StaffCollection.staffById[te.staffId] || noStaff
                        const role =
                            staff?.role ||
                            RoleCollection.rolesById[te.roleId] ||
                            noRole
                        return (
                            (!phaseId || te.phaseId === phaseId) &&
                            te[this.periodType] === period &&
                            (!staffId || staff?.id === staffId) &&
                            (!roleId || role?.id === roleId)
                        )
                    })
                    .map((te) => te.hours)
            )
        }
    )

    getEditedAllocationHoursInPeriod = computedFn(
        (period, phaseId, roleId, staffId) => {
            return this.editedAllocations[this.project?.id][this.periodType][
                period + phaseId + roleId + staffId
            ]?.hours
        }
    )

    @action.bound
    setEditedHoursInPeriod = (period, phaseId, roleId, staffId, value) => {
        const dateRange = [
            parse(period, this.periodFormat(), new Date(), { weekStartsOn: 1 }),
            this.endOfPeriod(
                parse(period, this.periodFormat(), new Date(), {
                    weekStartsOn: 1,
                })
            ),
        ]
        const ids = this.getLeafPhaseIds(phaseId, roleId, staffId).filter(
            ([p, r, s]) => {
                const staff = StaffCollection.staffById[s]
                return staffId || staff?.isArchived === false
            }
        )
        const existingHours = this.getHoursInPeriod(
            period,
            phaseId,
            roleId,
            staffId
        )
        const ratio = existingHours && value / existingHours
        ids.forEach(([p, r, s]) => {
            const models = {
                project: this.project,
                phase: PhaseCollection.phasesById[p],
                staff: StaffCollection.staffById[s],
                role: RoleCollection.rolesById[r],
            }
            const existingVal = this.getHoursInPeriod(period, p, r, s)
            const hours = ratio ? existingVal * ratio : value / ids.length
            const cost =
                hours * getCombinedRateInDateRange(models, 'cost', dateRange)
            const pay =
                hours * getCombinedRateInDateRange(models, 'pay', dateRange)
            const chargeOut =
                hours *
                getCombinedRateInDateRange(models, 'chargeOut', dateRange)
            this.editedAllocations[this.project?.id][this.periodType][
                period + p + r + s
            ] = {
                period,
                periodType: this.periodType,
                phaseId: p,
                roleId: r,
                staffId: s,
                hours,
                pay,
                cost,
                chargeOut,
            }
        })
        this.prepareToSave()
    }

    setEditedHoursProgressInPeriod = (
        period,
        phaseId,
        roleId,
        staffId,
        percent
    ) => {
        const ids = this.getLeafPhaseIds(phaseId, roleId, staffId).filter(
            ([p, r, s]) => {
                const staff = StaffCollection.staffById[s]
                return staffId || staff?.isArchived === false
            }
        )
        ids.forEach(([p, r, s]) => {
            const periodBefore = format(
                this.subPeriods(
                    parse(period, this.periodFormat(), new Date(), {
                        weekStartsOn: 1,
                    }),
                    1
                ),
                this.periodFormat()
            )
            const hoursBeforePeriod = this.getHoursToDateInPeriod(
                periodBefore,
                p,
                r,
                s
            )
            const budget = this.getHoursBudget(p, r, s)
            const newHours = Math.max(budget * percent - hoursBeforePeriod, 0)
            this.setEditedHoursInPeriod(period, p, r, s, newHours)
        })
        this.prepareToSave()
    }

    getAllocationHoursInPeriod = computedFn(
        (period, phaseId, roleId, staffId) => {
            return _.sum(
                this.queryData.allocations
                    .filter((a) => {
                        const staff =
                            StaffCollection.staffById[a.staffId] || noStaff
                        const role =
                            staff?.role ||
                            RoleCollection.rolesById[a.roleId] ||
                            noRole
                        return (
                            (!phaseId || a.phaseId === phaseId) &&
                            a[this.periodType] === period &&
                            (!staffId || staff?.id === staffId) &&
                            (!roleId || role?.id === roleId)
                        )
                    })
                    .map((a) => a.hours)
            )
        }
    )

    getHoursInPeriod = computedFn((period, phaseId, roleId, staffId) => {
        if (!period) return 0
        if (!staffId || !phaseId) {
            const ids = this.getLeafPhaseIds(phaseId, roleId, staffId)
            return _.sum(
                ids.map(([p, r, s]) => {
                    if (!p || !s) return 0
                    return this.getHoursInPeriod(period, p, r || null, s)
                })
            )
        }
        const thisPeriod = format(new Date(), this.periodFormat())
        const isPastPeriod = period < thisPeriod
        const isFuturePeriod = period > thisPeriod
        const isCurrentPeriod = period === thisPeriod
        if (isPastPeriod) {
            return this.getTimeEntryHoursInPeriod(
                period,
                phaseId,
                roleId,
                staffId
            )
        }
        if (isFuturePeriod) {
            const edits = this.getEditedAllocationHoursInPeriod(
                period,
                phaseId,
                roleId,
                staffId
            )
            const allocations = this.getAllocationHoursInPeriod(
                period,
                phaseId,
                roleId,
                staffId
            )
            return edits ?? allocations
        }
        if (isCurrentPeriod) {
            const edits = this.getEditedAllocationHoursInPeriod(
                period,
                phaseId,
                roleId,
                staffId
            )
            const allocations = this.getAllocationHoursInPeriod(
                period,
                phaseId,
                roleId,
                staffId
            )
            return Math.max(
                this.getTimeEntryHoursInPeriod(
                    period,
                    phaseId,
                    roleId,
                    staffId
                ),
                edits ?? allocations
            )
        }
        return 0
    })

    getHoursToDateInPeriod = computedFn((period, phaseId, roleId, staffId) => {
        const periodsPrior = this.periods.filter((m) => m <= period)
        return _.sum(
            periodsPrior.map((m) =>
                this.getHoursInPeriod(m, phaseId, roleId, staffId)
            )
        )
    })

    getTotalHours = computedFn((phaseId, roleId, staffId) => {
        return _.sum(
            this.periods.map((m) =>
                this.getHoursInPeriod(m, phaseId, roleId, staffId)
            )
        )
    })

    getHoursBudget = computedFn((phaseId, roleId, staffId) => {
        if (!phaseId || !(roleId || staffId)) {
            const ids = this.getLeafBudgetIds(phaseId, roleId, staffId)
            return _.sum(
                ids.map(([p, r, s]) => {
                    if (!p) return 0
                    return this.getHoursBudget(p, r, s)
                })
            )
        }
        const budget = this.queryData.projects[0].phases
            .find((p) => p.id === phaseId)
            ?.budgets.filter(
                (b) =>
                    (staffId && b.staffId === staffId) ||
                    (!staffId &&
                        roleId &&
                        (b.roleId === roleId ||
                            (!b.roleId && roleId === 'norole')))
            )
        if (!budget?.length) return 0
        return _.sum(budget.map((b) => b.hours))
    })

    getExpenseIds = computedFn((phaseId, expenseName, includeClis) => {
        let ids = []
        const phaseIds = phaseId
            ? [phaseId]
            : this.queryData.projects[0].phases.map((p) => p.id)
        phaseIds.forEach((p) => {
            const phase = this.queryData.projects[0].phases.find(
                (ph) => ph.id === p
            )
            const changeLogItems = includeClis
                ? this.queryData.changeLog.filter(
                      (cli) => cli.phaseId === p && cli.expenses
                  )
                : []
            const expenseNames = expenseName
                ? [expenseName]
                : [
                      ...new Set([
                          ...(phase?.expenses.map((e) => e.name) || []),
                          ...(changeLogItems?.map((c) => c.name) || []),
                      ]),
                  ]
            expenseNames.forEach((e) => {
                ids.push([p, e])
            })
        })
        return ids
    })

    getAllocatedExpensesInPeriod = computedFn(
        (period, phaseId, expenseName) => {
            return _.sum(
                this.queryData.expenseAllocations
                    .filter((ea) => {
                        return (
                            (!phaseId || ea.phaseId === phaseId) &&
                            ea[this.periodType] === period &&
                            (!expenseName || ea?.name === expenseName)
                        )
                    })
                    .map((te) => te.amount)
            )
        }
    )

    getChangeLogExpensesInPeriod = computedFn(
        (period, phaseId, expenseName) => {
            return _.sum(
                this.queryData.changeLog
                    .filter((cli) => {
                        return (
                            (!phaseId || cli.phaseId === phaseId) &&
                            cli[this.periodType] === period &&
                            (!expenseName || cli?.name === expenseName)
                        )
                    })
                    .map((te) => te.expenses)
            )
        }
    )

    getEditedExpensesInPeriod = computedFn((period, phaseId, expenseName) => {
        return this.editedExpenses[this.project?.id][this.periodType][
            period + phaseId + expenseName
        ]?.amount
    })

    @action.bound
    setEditedExpensesInPeriod = (period, phaseId, expenseName, value) => {
        const ids = this.getExpenseIds(phaseId, expenseName, false).filter(
            ([p, e]) => {
                return this.queryData.projects[0].phases
                    .find((ph) => ph.id === p)
                    ?.expenses.find((ex) => ex.name === e)
            }
        )
        const existingExpenses = this.getExpensesInPeriod(
            period,
            phaseId,
            expenseName
        )
        const ratio = existingExpenses && value / existingExpenses
        ids.forEach(([p, e]) => {
            const existingVal = this.getExpensesInPeriod(period, p, e)
            const expenses = ratio ? existingVal * ratio : value / ids.length
            const matchingExpense = this.queryData.projects[0].phases
                .find((ph) => ph.id === p)
                ?.expenses.find((ex) => ex.name === e)
            this.editedExpenses[this.project?.id][this.periodType][
                period + p + e
            ] = {
                periodType: this.periodType,
                period,
                phaseId: p,
                expenseName: e,
                expenseId: matchingExpense?.id,
                amount: expenses,
            }
        })
        this.prepareToSave()
    }

    setEditedExpensesProgressInPeriod = (
        period,
        phaseId,
        expenseName,
        percent
    ) => {
        const ids = this.getExpenseIds(phaseId, expenseName, false)
        ids.forEach(([p, e]) => {
            const periodBefore = format(
                this.subPeriods(
                    parse(period, this.periodFormat(), new Date(), {
                        weekStartsOn: 1,
                    }),
                    1
                ),
                this.periodFormat()
            )
            const expensesBeforePeriod = this.getExpensesToDateInPeriod(
                periodBefore,
                p,
                e
            )
            const budget = this.getExpensesBudget(p, e)
            const cliExpenses = this.getChangeLogExpensesInPeriod(period, p, e)
            const newExpenses = Math.max(
                budget * percent - expensesBeforePeriod - cliExpenses,
                0
            )
            this.setEditedExpensesInPeriod(period, p, e, newExpenses)
        })
        this.prepareToSave()
    }

    getExpensesInPeriod = computedFn((period, phaseId, expenseName) => {
        if (!period) return 0
        const ids = this.getExpenseIds(phaseId, expenseName, true)
        return _.sum(
            ids.map(
                ([p, e]) =>
                    this.getEditedExpensesInPeriod(period, p, e) ||
                    this.getAllocatedExpensesInPeriod(period, p, e) +
                        this.getChangeLogExpensesInPeriod(period, p, e)
            )
        )
    })

    getExpensesToDateInPeriod = computedFn((period, phaseId, expenseName) => {
        const periodsPrior = this.periods.filter((m) => m <= period)
        return _.sum(
            periodsPrior.map((m) =>
                this.getExpensesInPeriod(m, phaseId, expenseName)
            )
        )
    })

    getTotalExpenses = computedFn((phaseId, expenseName) => {
        return _.sum(
            this.periods.map((m) =>
                this.getExpensesInPeriod(m, phaseId, expenseName)
            )
        )
    })

    getExpensesBudget = computedFn((phaseId, expenseName) => {
        const ids = this.getExpenseIds(phaseId, expenseName, false)
        return _.sum(
            ids.map(([p, e]) => {
                const phase = this.queryData.projects[0].phases.find(
                    (ph) => ph.id === p
                )
                const expense = phase.expenses.find((ex) => ex.name === e)
                return expense?.cost || 0
            })
        )
    })

    @action.bound
    setDisplayedProjectOrPhase(projectOrPhase) {
        this.displayedProjectOrPhase = projectOrPhase
    }

    @action.bound
    setGraphType(graphType) {
        this.graphType = graphType
        // this.updateColumns()
    }

    @action.bound
    setGraphData(graphData) {
        this.graphData = graphData
    }

    @computed
    get isCumulative() {
        return ['revenueVsExpenses', 'expenseBudget', 'hoursBudget'].includes(
            this.graphType
        )
    }

    @computed
    get labelColumns() {
        return [
            {
                id: 'label',
                label: 'Title',
                type: 'text',
                width: 18,
                editable: (row) => false,
                value: (row, stores) => {
                    if (
                        stores.row.group &&
                        stores.row.cells[stores.row.group].formattedValue
                    ) {
                        return stores.row.cells[stores.row.group].formattedValue
                    }
                    return (
                        row.label ||
                        row.title ||
                        row.name ||
                        row.expenseName ||
                        row.fullName ||
                        String(row)
                    )
                },
                component: ({ value, group, stores }) => {
                    const { row, table } = stores
                    return (
                        <div
                            style={{ paddingLeft: `${1 * row.groupLevel}rem` }}
                        >
                            {row.group !== 'project' && row.childRows.length ? (
                                <i
                                    className={`fa fa-${`caret-${
                                        row.expanded ? 'down' : 'right'
                                    }`} fa-fw`}
                                    onClick={() => table.toggleRowExpand(row)}
                                />
                            ) : null}
                            {value}
                        </div>
                    )
                },
            },
        ]
    }

    @computed
    get cellType() {
        return ['hoursBudget', 'hoursBudgetMonthly'].includes(this.graphType)
            ? 'number'
            : 'currency'
    }

    @computed
    get budgetColumns() {
        const isExpenseRow = (row) => row instanceof ExpenseRowModel
        const isStaffPhaseRow = (row) => row instanceof ResourceRowModel
        const isRevenueRow = (row) => row instanceof RevenueRowModel
        const getNumerator = (row, table) => {
            if (isExpenseRow(row)) {
                return row.getTotalExpense(this.graphData)
            }
            if (isStaffPhaseRow(row)) {
                return this.cellType === 'currency'
                    ? row.getTotalCost()
                    : row.getTotalHours()
            }
            if (isRevenueRow(row)) {
                return row.getTotalRevenue()
            }
            return 0
        }
        const getDenominator = (row, table) => {
            if (isExpenseRow(row)) {
                return row.expenseBudget
            }
            if (isStaffPhaseRow(row) && this.cellType === 'currency') {
                return row.resource ? row.costBudget : row.phase.expenseBudget
            }
            if (isStaffPhaseRow(row) && this.cellType === 'number') {
                return row.resource ? row.hoursBudget : row.phase.hoursBudget
            }
            if (isRevenueRow(row)) {
                return row.fee
            }
            return 0
        }
        return [
            {
                id: 'budgetUse',
                label: (
                    <span>
                        Total
                        <span
                            style={{ fontSize: '1.5em', float: 'right' }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <i
                                className={`fa fa-caret-left`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.shiftDates(-1)}
                            />
                            <i
                                className={`fa fa-caret-right`}
                                style={{ marginRight: 0, cursor: 'pointer' }}
                                onClick={() => this.shiftDates(1)}
                            />
                        </span>
                    </span>
                ),
                type: 'progress',
                width: 18,
                editable: (row) => false,
                format:
                    this.cellType === 'currency'
                        ? Formatter.currency
                        : Formatter.number,
                value: (row, stores) => ({
                    numerator: getNumerator(row, stores.table),
                    denominator: getDenominator(row, stores.table),
                }),
                style: (row) => ({
                    textAlign: 'left',
                    justifyContent: 'flex-start',
                    // color: getNumerator(row) > getDenominator(row) ? 'red' : '',
                }),
            },
        ]
    }

    getExpenseCellValue({ row, periodStart, periodEnd }) {
        if (!periodStart) return 0
        return row.getExpensesInPeriod(
            format(periodStart, this.periodFormat()),
            this.graphData
        )
    }

    getStaffCellValue({ row, periodStart, periodEnd }) {
        if (!periodStart) return 0
        return row[
            this.cellType === 'currency'
                ? 'getCostInPeriod'
                : 'getHoursInPeriod'
        ](format(periodStart, this.periodFormat()))
    }

    updateRevenueValue({ model, value, stores, periodStart, periodEnd }) {
        editRevenueTargetsInDateRange(
            [
                ...model.revenueTargets,
                ...model.invoiceLineItems,
                ...model.changeLog.filter((cli) => cli.revenue),
            ],
            [periodStart, periodEnd],
            value,
            this.cellType === 'currency' ? 'cost' : 'hours'
        )
    }

    updateStaffValue({ model, value, stores, periodStart, periodEnd }) {
        editAllocationsInDateRange(
            stores.row.descendants.map((r) => r.rowObject),
            [periodStart, periodEnd],
            value,
            this.cellType === 'currency' ? 'cost' : 'hours'
        )
    }

    updateExpenseValue({ model, value, stores, periodStart, periodEnd }) {
        const expenses = ['project', 'phase'].includes(model.modelType)
            ? model.expenses
            : model.modelType === 'projectExpense'
              ? [model]
              : []
        const totalExpense = _.sum(
            expenses.map((ex) =>
                _.sum(
                    ex.allocations
                        .filter(
                            (exAl) =>
                                exAl.date >= periodStart &&
                                exAl.date <= periodEnd
                        )
                        .map((exAl) => exAl.amount)
                )
            )
        )
        if (expenses.length && totalExpense) {
            const revenueRatio = value / totalExpense
            expenses.forEach((ex) =>
                ex.allocations
                    .filter(
                        (exAl) =>
                            exAl.date >= periodStart && exAl.date <= periodEnd
                    )
                    .forEach((exAl) =>
                        exAl.update({ amount: exAl.amount * revenueRatio })
                    )
            )
        } else if (expenses.length && !totalExpense) {
            const splitAmount = value / expenses.length
            expenses.forEach((ex) => {
                const allocations = ex.allocations.filter(
                    (exAl) => exAl.date >= periodStart && exAl.date <= periodEnd
                )
                if (allocations.length) {
                    const subSplitAmount = splitAmount / allocations.length
                    allocations.forEach((exAl) =>
                        exAl.update({ amount: subSplitAmount })
                    )
                } else {
                    ProjectExpenseAllocationCollection.add({
                        projectId: ex.projectId,
                        phaseId: ex.phaseId,
                        expenseId: ex.id,
                        amount: splitAmount,
                        date: periodStart,
                    })
                }
            })
        } else {
            // this should not be possible
            // there should always be an expense
        }
    }

    @computed
    get periodColumns() {
        const project =
            this.displayedProjectOrPhase?.project ||
            this.displayedProjectOrPhase
        const user = SessionStore.user
        const cols = [...Array(12)].map((v, i) => {
            const currentPeriodStart = this.startOfPeriod(new Date())
            const periodStart = this.addPeriods(this.startDate, i)
            const periodEnd = this.endOfPeriod(periodStart)
            const periodType =
                periodStart.getTime() === currentPeriodStart.getTime()
                    ? 'current'
                    : periodStart.getTime() > currentPeriodStart.getTime()
                      ? 'future'
                      : 'past'
            const isPastPeriod = periodType === 'past'
            const isCurrentPeriod = periodType === 'current'
            const isFuturePeriod = periodType === 'future'
            const isExpenseRow = (row) => row instanceof ExpenseRowModel
            const isRevenueRow = (tableStore) =>
                tableStore === this.revenueTableStore
            const periodId = format(periodStart, this.periodFormat())
            return {
                id: periodStart.getTime(),
                label: periodId,
                type: this.cellType,
                width: 8,
                editable: (row, stores) => {
                    if (
                        isExpenseRow(row) &&
                        canEditProjectExpenses(user, project)
                    )
                        return isCurrentPeriod || isFuturePeriod
                    if (
                        isStaffPhaseRow(row) &&
                        ((['hoursBudget', 'hoursBudgetMonthly'].includes(
                            this.graphType
                        ) &&
                            canEditStaffAllocations) ||
                            ([
                                'revenueVsExpenses',
                                'revenueVsExpensesMonthly',
                                'expenseBudget',
                                'expenseBudgetMonthly',
                            ].includes(this.graphType) &&
                                canEditStaffAllocations(
                                    SessionStore.user,
                                    project
                                ) &&
                                canViewStaffCostRate(
                                    SessionStore.user,
                                    project
                                )))
                    )
                        return isCurrentPeriod || isFuturePeriod
                    if (
                        isRevenueRow(row) &&
                        canEditRevenueTargets(user, project)
                    )
                        return isCurrentPeriod || isFuturePeriod
                    return false
                },
                style: (row, stores) => {
                    const hasDatesInRange =
                        row?.startDate &&
                        row?.endDate &&
                        periodStart >= this.startOfPeriod(row.startDate) &&
                        periodStart <= this.endOfPeriod(row.endDate)
                    return {
                        ...(isPastPeriod
                            ? {
                                  backgroundImage: `url(${new URL(
                                      '~/public/diag.png',
                                      import.meta.url
                                  )})`,
                              }
                            : {}),
                        ...(stores?.cell?.value > 0
                            ? { borderBottom: '5px gold solid' }
                            : hasDatesInRange
                              ? { borderBottom: '3px #d7d7d7 solid' }
                              : {}),
                    }
                },
                value: (row, stores) => {
                    if (isExpenseRow(row))
                        return this.getExpenseCellValue({
                            row,
                            periodStart,
                            periodEnd,
                        })
                    if (isStaffPhaseRow(stores.table)) {
                        return this.getCostInPeriod(
                            periodId,
                            row.id,
                            null,
                            null
                        )
                    }
                    if (
                        isStaffPhaseRow(stores.table) &&
                        this.cellType === 'number'
                    )
                        return this.getCostInPeriod(
                            periodId,
                            row.id,
                            null,
                            null
                        )
                    if (isRevenueRow(stores.table))
                        return this.getRevenueInPeriod(periodId, row.id)
                },
                onChange: (r, stores) => (v) => {
                    v = v || 0
                    if (isRevenueRow(r)) {
                        r.setRevenueInPeriod(
                            format(periodStart, this.periodFormat()),
                            v
                        )
                    }
                    if (isStaffPhaseRow(r) && this.cellType === 'number')
                        r.setHoursInPeriod(
                            format(periodStart, this.periodFormat()),
                            v
                        )
                    if (isStaffPhaseRow(r) && this.cellType === 'currency')
                        r.setCostInPeriod(
                            format(periodStart, this.periodFormat()),
                            v
                        )
                    if (isExpenseRow(r)) {
                        r.setExpenseInPeriod(
                            format(periodStart, this.periodFormat()),
                            v,
                            this.graphData
                        )
                    }
                },
                onClick: (r, stores) => () => {
                    if (r instanceof ExpenseRowModel) return
                    this.selectCell(periodStart, r, stores.table)
                },
                selected: (r, stores) => {
                    return (
                        this.selectedObject === r &&
                        format(this.selectedPeriod, this.periodFormat()) ===
                            format(periodStart, this.periodFormat()) &&
                        this.selectedTable === stores.table
                    )
                },
            }
        })
        return cols
    }

    @action.bound
    selectCell(
        selectedPeriod,
        selectedObject,
        selectedTable,
        selectedProjectOrPhase
    ) {
        this.selectedPeriod = selectedPeriod
        this.selectedObject = selectedObject
        this.selectedTable = selectedTable
        this.selectedProjectOrPhase = selectedProjectOrPhase
        LayoutStore.showSidebar = !!(selectedPeriod && selectedObject)
    }
    @computed
    get graphRevenue() {
        return this.periodColumns.map((c) => {
            const periodStart = new Date(c.id)
            const periodEnd = this.endOfPeriod(periodStart)
            const periodId = format(periodStart, this.periodFormat())
            if (this.graphType === 'revenueVsExpenses') {
                return _.sum(
                    this.revenueTableRows.map((r) =>
                        this.getRevenueToDateInPeriod(periodId, r?.id)
                    )
                )
            }
            if (this.graphType === 'expenseBudget') {
                return _.sum(
                    this.staffPhaseTableRows.map((r) =>
                        this.getCostBudget(r?.id, null, null)
                    )
                )
            }
            if (this.graphType === 'hoursBudget') {
                return _.sum(
                    this.staffPhaseTableRows.map((r) =>
                        this.getHoursBudget(r?.id, null, null)
                    )
                )
            }
            if (this.graphType === 'revenueVsExpensesMonthly') {
                return _.sum(
                    this.revenueTableRows.map((r) =>
                        this.getRevenueInPeriod(periodId, r?.id)
                    )
                )
            }
            if (this.graphType === 'expenseBudgetMonthly') {
                return (
                    _.sum(
                        this.staffPhaseTableRows.map((r) =>
                            this.getCostBudget(r?.id, null, null)
                        )
                    ) -
                    _.sum(
                        this.staffPhaseTableRows.map((r) =>
                            this.getCostToDateInPeriod(
                                periodId,
                                r?.id,
                                null,
                                null
                            )
                        )
                    ) -
                    _.sum(
                        this.expenseTableRows.map((r) =>
                            this.getExpensesToDateInPeriod(
                                format(periodStart, this.periodFormat()),
                                r?.id,
                                null
                            )
                        )
                    )
                )
            }
            if (this.graphType === 'hoursBudgetMonthly') {
                return (
                    _.sum(
                        this.staffPhaseTableRows.map((r) =>
                            this.getHoursBudget(r?.id, null, null)
                        )
                    ) -
                    _.sum(
                        this.staffPhaseTableRows.map((r) =>
                            this.getHoursToDateInPeriod(
                                periodId,
                                r?.id,
                                null,
                                null
                            )
                        )
                    )
                )
            }
            return null
        })
    }
    @computed
    get graphExpenses() {
        const currentPeriodStart = this.startOfPeriod(new Date())
        const currentPeriodEnd = this.endOfPeriod(new Date())
        return this.periodColumns.map((c) => {
            const periodStart = new Date(c.id)
            const periodEnd = this.endOfPeriod(periodStart)
            const periodId = format(periodStart, this.periodFormat())
            if (
                ['revenueVsExpenses', 'expenseBudget'].includes(this.graphType)
            ) {
                return (
                    _.sum(
                        this.staffPhaseTableRows.map((r) =>
                            this.getCostToDateInPeriod(
                                periodId,
                                r?.id,
                                null,
                                null
                            )
                        )
                    ) +
                    _.sum(
                        this.expenseTableRows.map((r) =>
                            this.getExpensesToDateInPeriod(
                                format(periodStart, this.periodFormat()),
                                r?.id,
                                null
                            )
                        )
                    )
                )
            }
            if (this.graphType === 'hoursBudget') {
                return _.sum(
                    this.staffPhaseTableRows.map((r) =>
                        this.getHoursToDateInPeriod(periodId, r?.id, null, null)
                    )
                )
            }
            if (
                ['revenueVsExpensesMonthly', 'expenseBudgetMonthly'].includes(
                    this.graphType
                )
            ) {
                return (
                    _.sum(
                        this.staffPhaseTableRows.map((r) =>
                            this.getCostInPeriod(periodId, r?.id, null, null)
                        )
                    ) +
                    _.sum(
                        this.expenseTableRows.map((r) =>
                            this.getExpensesInPeriod(periodId, r?.id, null)
                        )
                    )
                )
            }
            if (this.graphType === 'hoursBudgetMonthly') {
                return _.sum(
                    this.staffPhaseTableRows.map((r) =>
                        this.getHoursInPeriod(periodId, r?.id, null, null)
                    )
                )
            }
            return null
        })
    }
    @computed
    get showRevenueTable() {
        return (
            ['revenueVsExpenses', 'revenueVsExpensesMonthly'].includes(
                this.graphType
            ) &&
            canViewRevenueTargets(
                SessionStore.user,
                this.displayedProjectOrPhase?.project ||
                    this.displayedProjectOrPhase
            )
        )
    }
    @computed
    get showStaffTable() {
        const project =
            this.displayedProjectOrPhase?.project ||
            this.displayedProjectOrPhase
        return (
            ['hoursBudget', 'hoursBudgetMonthly'].includes(this.graphType) ||
            ([
                'revenueVsExpenses',
                'revenueVsExpensesMonthly',
                'expenseBudget',
                'expenseBudgetMonthly',
            ].includes(this.graphType) &&
                canViewStaffAllocations(SessionStore.user, project) &&
                canViewStaffCostRate(SessionStore.user, project))
        )
    }
    @computed
    get showExpenseTable() {
        return (
            [
                'revenueVsExpenses',
                'revenueVsExpensesMonthly',
                'expenseBudget',
                'expenseBudgetMonthly',
            ].includes(this.graphType) &&
            canViewProjectExpenses(
                SessionStore.user,
                this.displayedProjectOrPhase?.project ||
                    this.displayedProjectOrPhase
            )
        )
    }
    @computed
    get revenueTableColumns() {
        return [
            spacerColumn,
            ...this.labelColumns,
            {
                id: 'budgetUse',
                label: (
                    <span>
                        Total
                        <span
                            style={{ fontSize: '1.5em', float: 'right' }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <i
                                className={`fa fa-caret-left`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.shiftDates(-1)}
                            />
                            <i
                                className={`fa fa-caret-right`}
                                style={{ marginRight: 0, cursor: 'pointer' }}
                                onClick={() => this.shiftDates(1)}
                            />
                        </span>
                    </span>
                ),
                type: 'progress',
                width: 18,
                editable: (row) => false,
                format:
                    this.cellType === 'currency'
                        ? Formatter.currency
                        : Formatter.number,
                value: (row, stores) => {
                    return {
                        numerator: this.getTotalRevenue(row?.id),
                        denominator: this.getFee(row?.id),
                    }
                },
                format: (v) => FormatCurrency(v, { decimals: 0 }),
                style: (row) => ({
                    textAlign: 'left',
                    justifyContent: 'flex-start',
                    color:
                        row &&
                        Math.round(this.getTotalRevenue(row?.id)) >
                            Math.round(this.getFee(row?.id))
                            ? 'red'
                            : '',
                }),
            },
            ...[...Array(12)].map((v, i) => {
                const currentPeriodStart = this.startOfPeriod(new Date())
                const periodStart = this.addPeriods(this.startDate, i)
                const periodId = format(periodStart, this.periodFormat())
                const project =
                    this.displayedProjectOrPhase?.project ||
                    this.displayedProjectOrPhase
                const periodType =
                    periodStart.getTime() === currentPeriodStart.getTime()
                        ? 'current'
                        : periodStart.getTime() > currentPeriodStart.getTime()
                          ? 'future'
                          : 'past'
                const user = SessionStore.user
                const isPastPeriod = periodType === 'past'
                const isCurrentPeriod = periodType === 'current'
                const isFuturePeriod = periodType === 'future'
                return {
                    id: periodStart.getTime(),
                    label: format(periodStart, this.periodDisplayFormat()),
                    type: this.cellType,
                    width: 8,
                    editable: (row, stores) => {
                        return (
                            canEditRevenueTargets(user, project) &&
                            (isCurrentPeriod || isFuturePeriod)
                        )
                    },
                    style: (row, stores) => {
                        const hasDatesInRange =
                            row?.startDate &&
                            row?.endDate &&
                            periodStart >= this.startOfPeriod(row.startDate) &&
                            periodStart <= this.endOfPeriod(row.endDate)
                        return {
                            ...(isPastPeriod
                                ? {
                                      backgroundImage: `url(${new URL(
                                          '~/public/diag.png',
                                          import.meta.url
                                      )})`,
                                  }
                                : {}),
                            ...(stores?.cell?.value > 0
                                ? { borderBottom: '5px gold solid' }
                                : hasDatesInRange
                                  ? { borderBottom: '3px #d7d7d7 solid' }
                                  : {}),
                        }
                    },
                    value: (row, stores) => {
                        return this.getRevenueInPeriod(periodId, row.id)
                    },
                    format: (v) => FormatCurrency(v, { decimals: 0 }),
                    onChange: (r, stores) => (v) => {
                        this.setEditedRevenueInPeriod(periodId, r?.id, v)
                    },
                    onClick: (r, stores) => () => {
                        this.selectCell(
                            periodStart,
                            r?.id || this.project.id,
                            stores.table,
                            PhaseCollection.phasesById[r?.id] || this.project
                        )
                    },
                    selected: (r, stores) => {
                        return (
                            this.selectedObject ===
                                (r?.id || this.project.id) &&
                            format(this.selectedPeriod, this.periodFormat()) ===
                                format(periodStart, this.periodFormat()) &&
                            this.selectedTable === stores.table
                        )
                    },
                }
            }),
            {
                id: 'project',
                label: 'Project',
                type: 'project',
                width: 25,
                visible: false,
                value: (row) => this.project,
            },
        ]
    }

    staffTableColumns = computedFn((getIds = (row) => [row.id, null, null]) => {
        const showCost = this.cellType === 'currency'
        return [
            ...this.labelColumns,
            {
                id: 'budgetUse',
                label: (
                    <span>
                        Total
                        <span
                            style={{ fontSize: '1.5em', float: 'right' }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <i
                                className={`fa fa-caret-left`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.shiftDates(-1)}
                            />
                            <i
                                className={`fa fa-caret-right`}
                                style={{
                                    marginRight: 0,
                                    cursor: 'pointer',
                                }}
                                onClick={() => this.shiftDates(1)}
                            />
                        </span>
                    </span>
                ),
                type: 'progress',
                width: 18,
                editable: (row) => false,
                format: (v) =>
                    showCost
                        ? FormatCurrency(v, { decimals: 0 })
                        : FormatNumber(v, { decimals: 0 }),
                value: (row, stores) => {
                    if (row === this.project) {
                        row = null
                    }
                    return {
                        numerator: showCost
                            ? this.getTotalCost(...getIds(row))
                            : this.getTotalHours(...getIds(row)),
                        denominator: showCost
                            ? this.getCostBudget(...getIds(row))
                            : this.getHoursBudget(...getIds(row)),
                    }
                },
                style: (row) => ({
                    textAlign: 'left',
                    justifyContent: 'flex-start',
                    color:
                        (row &&
                            showCost &&
                            Math.round(this.getTotalCost(...getIds(row))) >
                                Math.round(
                                    this.getCostBudget(...getIds(row))
                                )) ||
                        (row &&
                            !showCost &&
                            Math.round(this.getTotalHours(...getIds(row))) >
                                Math.round(this.getHoursBudget(...getIds(row))))
                            ? 'red'
                            : '',
                }),
            },
            ...[...Array(12)].map((v, i) => {
                const currentPeriodStart = this.startOfPeriod(new Date())
                const periodStart = this.addPeriods(this.startDate, i)
                const periodId = format(periodStart, this.periodFormat())
                const project =
                    this.displayedProjectOrPhase?.project ||
                    this.displayedProjectOrPhase
                const periodType =
                    periodStart.getTime() === currentPeriodStart.getTime()
                        ? 'current'
                        : periodStart.getTime() > currentPeriodStart.getTime()
                          ? 'future'
                          : 'past'
                const user = SessionStore.user
                const isPastPeriod = periodType === 'past'
                const isCurrentPeriod = periodType === 'current'
                const isFuturePeriod = periodType === 'future'
                return {
                    id: periodStart.getTime(),
                    label: format(periodStart, this.periodDisplayFormat()),
                    type: this.cellType,
                    format: (v) =>
                        showCost
                            ? FormatCurrency(v, { decimals: 0 })
                            : FormatNumber(v, { decimals: 0 }),
                    width: 8,
                    editable: (row, stores) => {
                        return (
                            (isCurrentPeriod || isFuturePeriod) &&
                            canEditStaffAllocations(
                                SessionStore.user,
                                project
                            ) &&
                            (!showCost || canViewStaffCostRate(user, project))
                        )
                    },
                    style: (row, stores) => {
                        const hasDatesInRange =
                            row?.startDate &&
                            row?.endDate &&
                            periodStart >= this.startOfPeriod(row.startDate) &&
                            periodStart <= this.endOfPeriod(row.endDate)
                        return {
                            ...(isPastPeriod
                                ? {
                                      backgroundImage: `url(${new URL(
                                          '~/public/diag.png',
                                          import.meta.url
                                      )})`,
                                  }
                                : {}),
                            ...(stores?.cell?.value > 0
                                ? { borderBottom: '5px gold solid' }
                                : hasDatesInRange
                                  ? { borderBottom: '3px #d7d7d7 solid' }
                                  : {}),
                        }
                    },
                    value: (row, stores) => {
                        return showCost
                            ? this.getCostInPeriod(periodId, ...getIds(row))
                            : this.getHoursInPeriod(periodId, ...getIds(row))
                    },
                    onChange: (row, stores) => (v) => {
                        showCost
                            ? this.setEditedCostInPeriod(
                                  periodId,
                                  ...getIds(row),
                                  v
                              )
                            : this.setEditedHoursInPeriod(
                                  periodId,
                                  ...getIds(row),
                                  v
                              )
                    },
                    onClick: (r, stores) => () => {
                        this.selectCell(
                            periodStart,
                            r?.id || this.project.id,
                            stores.table,
                            PhaseCollection.phasesById[getIds(r)[0]] ||
                                this.project
                        )
                    },
                    selected: (r, stores) => {
                        return (
                            this.selectedObject ===
                                (r?.id || this.project.id) &&
                            format(this.selectedPeriod, this.periodFormat()) ===
                                format(periodStart, this.periodFormat()) &&
                            this.selectedTable === stores.table
                        )
                    },
                }
            }),
            {
                id: 'project',
                label: 'Project',
                type: 'project',
                width: 25,
                visible: false,
                value: (row) => this.project,
            },
            {
                id: 'phase',
                label: 'Phase',
                type: 'phase',
                width: 25,
                visible: false,
                value: (row) => row.phase,
            },
            {
                id: 'staff',
                label: 'Staff',
                type: 'staff',
                width: 25,
                visible: false,
                value: (row) => row.staff,
            },
            {
                id: 'role',
                label: 'Role',
                type: 'role',
                width: 25,
                visible: false,
                value: (row) => row.role,
            },
        ]
    })

    expenseTableColumns(getIds = (phase) => [phase.id, null]) {
        return [
            ...this.labelColumns,
            {
                id: 'budgetUse',
                label: (
                    <span>
                        Total
                        <span
                            style={{ fontSize: '1.5em', float: 'right' }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <i
                                className={`fa fa-caret-left`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.shiftDates(-1)}
                            />
                            <i
                                className={`fa fa-caret-right`}
                                style={{ marginRight: 0, cursor: 'pointer' }}
                                onClick={() => this.shiftDates(1)}
                            />
                        </span>
                    </span>
                ),
                type: 'progress',
                width: 18,
                editable: (row) => false,
                format: (v) => FormatCurrency(v, { decimals: 0 }),
                value: (row, stores) => {
                    return {
                        numerator: this.getTotalExpenses(...getIds(row)),
                        denominator: this.getExpensesBudget(...getIds(row)),
                    }
                },
                style: (row) => {
                    return {
                        textAlign: 'left',
                        justifyContent: 'flex-start',
                        color:
                            row &&
                            Math.round(this.getTotalExpenses(...getIds(row))) >
                                Math.round(
                                    this.getExpensesBudget(...getIds(row))
                                )
                                ? 'red'
                                : '',
                    }
                },
            },
            ...[...Array(12)].map((v, i) => {
                const currentPeriodStart = this.startOfPeriod(new Date())
                const periodStart = this.addPeriods(this.startDate, i)
                const periodId = format(periodStart, this.periodFormat())
                const project =
                    this.displayedProjectOrPhase?.project ||
                    this.displayedProjectOrPhase
                const periodType =
                    periodStart.getTime() === currentPeriodStart.getTime()
                        ? 'current'
                        : periodStart.getTime() > currentPeriodStart.getTime()
                          ? 'future'
                          : 'past'
                const user = SessionStore.user
                const isPastPeriod = periodType === 'past'
                const isCurrentPeriod = periodType === 'current'
                const isFuturePeriod = periodType === 'future'
                return {
                    id: periodStart.getTime(),
                    label: format(periodStart, this.periodDisplayFormat()),
                    type: this.cellType,
                    width: 8,
                    editable: (row, stores) => {
                        return (
                            canEditProjectExpenses(user, project) &&
                            (isCurrentPeriod || isFuturePeriod)
                        )
                    },
                    style: (row, stores) => {
                        const hasDatesInRange =
                            row?.startDate &&
                            row?.endDate &&
                            periodStart >= this.startOfPeriod(row.startDate) &&
                            periodStart <= this.endOfPeriod(row.endDate)
                        return {
                            ...(isPastPeriod
                                ? {
                                      backgroundImage: `url(${new URL(
                                          '~/public/diag.png',
                                          import.meta.url
                                      )})`,
                                  }
                                : {}),
                            ...(stores?.cell?.value > 0
                                ? { borderBottom: '5px gold solid' }
                                : hasDatesInRange
                                  ? { borderBottom: '3px #d7d7d7 solid' }
                                  : {}),
                        }
                    },
                    value: (row, stores) => {
                        return this.getExpensesInPeriod(
                            periodId,
                            ...getIds(row)
                        )
                    },
                    format: (v) => FormatCurrency(v, { decimals: 0 }),
                    onChange: (row, stores) => (v) => {
                        this.setEditedExpensesInPeriod(
                            periodId,
                            ...getIds(row),
                            v
                        )
                    },
                    onClick: (r, stores) => () => {
                        this.selectCell(
                            periodStart,
                            r?.id || r || this.project.id,
                            stores.table,
                            PhaseCollection.phasesById[r?.id] || this.project
                        )
                    },
                    selected: (r, stores) => {
                        return (
                            this.selectedObject ===
                                (r?.id || r || this.project.id) &&
                            format(this.selectedPeriod, this.periodFormat()) ===
                                format(periodStart, this.periodFormat()) &&
                            this.selectedTable === stores.table
                        )
                    },
                }
            }),
            {
                id: 'project',
                label: 'Project',
                type: 'project',
                width: 25,
                visible: false,
                value: (row) => this.project,
            },
            {
                id: 'phase',
                label: 'Phase',
                type: 'phase',
                width: 25,
                visible: false,
                value: (row) => row,
            },
        ]
    }

    @computed
    get revenueTableRows() {
        if (!this.queryData) return []
        return [...this.queryData.projects[0].phases]
            .sort(sortPhases)
            .filter(
                (ph) =>
                    !ph?.isRootPhase &&
                    (this.filterByPhase && !this.filterByPhase.isRootPhase
                        ? ph.id === this.filterByPhase.id
                        : true)
            )
    }

    @computed
    get staffPhaseTableRows() {
        if (!this.queryData) return []
        return [...this.queryData.projects[0].phases]
            .sort(sortPhases)
            .filter(
                (ph) =>
                    !ph?.isRootPhase &&
                    (this.filterByPhase && !this.filterByPhase.isRootPhase
                        ? ph.id === this.filterByPhase.id
                        : true)
            )
    }

    @computed
    get expenseTableRows() {
        if (!this.queryData) return []
        return [...this.queryData.projects[0].phases]
            .sort(sortPhases)
            .filter(
                (ph) =>
                    !ph?.isRootPhase &&
                    (this.filterByPhase && !this.filterByPhase.isRootPhase
                        ? ph.id === this.filterByPhase.id
                        : true)
            )
    }

    expensePhaseTableRows = computedFn((phaseId) => {
        if (!this.queryData) return []
        const phase = this.queryData.projects[0].phases.find(
            (ph) => ph.id === phaseId
        )
        const expenses = phase?.expenses || []
        const changeLogItems = this.queryData.changeLog.filter(
            (cli) => cli?.phaseId === phaseId && cli?.expenses
        )
        return [
            ...new Set([
                ...expenses.map((e) => e?.name),
                ...changeLogItems.map((cli) => cli?.name),
            ]),
        ].filter((r) => r)
    })

    @action.bound
    shiftDates(amount) {
        this.startDate = this.addPeriods(this.startDate, amount)
        this.endDate = this.addPeriods(this.endDate, amount)
        // this.updateColumns()
    }

    @action.bound
    shiftSelectedDates(amount) {
        this.selectedPeriod = this.startOfPeriod(
            this.addPeriods(this.selectedPeriod, amount)
        )
        if (amount < 0 && this.selectedPeriod < this.startDate) {
            this.shiftDates(amount)
        } else if (amount > 0 && this.selectedPeriod > this.endDate) {
            this.shiftDates(amount)
        }
    }

    @computed
    get totalsLabelColumn() {
        const titleLookup = {
            revenueVsExpenses: 'Profit',
            expenseBudget: 'Remaining Budget',
            hoursBudget: 'Remaining Budget',
        }
        return {
            id: 'label',
            label: 'Title',
            type: 'text',
            width: 18,
            editable: (row) => false,
            value: (row, stores) => {
                return titleLookup[this.graphType.replace('Monthly', '')]
            },
            component: ({ value, group, stores }) => {
                const { row, table } = stores
                return (
                    <div style={{ paddingLeft: `${1 * row.groupLevel}rem` }}>
                        {row.group !== 'project' && row.childRows.length ? (
                            <i
                                className={`fa fa-${`caret-${
                                    row.expanded ? 'down' : 'right'
                                }`} fa-fw`}
                                onClick={() => table.toggleRowExpand(row)}
                            />
                        ) : null}
                        {value}
                    </div>
                )
            },
        }
    }

    @computed
    get totalsTotalColumn() {
        const totalValueLookup = {
            revenueVsExpenses: (row, stores) => {
                return {
                    numerator:
                        _.sum(
                            this.revenueTableRows.map((r) =>
                                this.getTotalRevenue(r.id)
                            )
                        ) -
                        _.sum(
                            this.staffPhaseTableRows.map((r) =>
                                this.getTotalCost(r.id, null, null)
                            )
                        ) -
                        _.sum(
                            this.expenseTableRows.map((r) =>
                                this.getTotalExpenses(r.id, null)
                            )
                        ),
                    denominator: _.sum(
                        this.revenueTableRows.map((r) =>
                            this.getTotalRevenue(r.id)
                        )
                    ),
                }
            },
            expenseBudget: (row, stores) => {
                return {
                    numerator:
                        _.sum(
                            this.staffPhaseTableRows.map((r) =>
                                this.getTotalCost(r.id, null, null)
                            )
                        ) +
                        _.sum(
                            this.expenseTableRows.map((r) =>
                                this.getTotalExpenses(r.id, null)
                            )
                        ),
                    denominator: this.getCostBudget(null, null, null),
                }
            },
            hoursBudget: (row, stores) => {
                return {
                    numerator: _.sum(
                        this.staffPhaseTableRows.map((r) =>
                            r.getTotalHours(r.id, null, null)
                        )
                    ),
                    denominator: this.getHoursBudget(null, null, null),
                }
            },
        }
        const showCost = this.cellType === 'currency'
        return {
            id: 'total',
            label: 'Total',
            type: 'progress',
            format: (v) =>
                showCost
                    ? FormatCurrency(v, { decimals: 0 })
                    : FormatNumber(v, { decimals: 0 }),
            width: 18,
            editable: (row) => false,
            value: totalValueLookup[this.graphType.replace('Monthly', '')],
        }
    }

    @computed
    get totalsPeriodColumns() {
        const getValueLookup = {
            revenueVsExpenses: (period) => {
                return (
                    _.sum(
                        this.revenueTableRows.map((r) =>
                            this.getRevenueToDateInPeriod(period, r?.id)
                        )
                    ) -
                    _.sum(
                        this.staffPhaseTableRows.map((r) =>
                            this.getCostToDateInPeriod(
                                period,
                                r?.id,
                                null,
                                null
                            )
                        )
                    ) -
                    _.sum(
                        this.expenseTableRows.map((r) =>
                            this.getExpensesToDateInPeriod(period, r?.id, null)
                        )
                    )
                )
            },
            expenseBudget: (period) => {
                return (
                    _.sum(
                        this.staffPhaseTableRows.map((r) =>
                            this.getCostBudget(r?.id, null, null)
                        )
                    ) -
                    _.sum(
                        this.staffPhaseTableRows.map((r) =>
                            this.getCostToDateInPeriod(
                                period,
                                r?.id,
                                null,
                                null
                            )
                        )
                    ) -
                    _.sum(
                        this.expenseTableRows.map((r) =>
                            this.getExpensesToDateInPeriod(period, r?.id, null)
                        )
                    )
                )
            },
            hoursBudget: (period) => {
                return (
                    _.sum(
                        this.staffPhaseTableRows.map((r) =>
                            this.getHoursBudget(r?.id, null, null)
                        )
                    ) -
                    _.sum(
                        this.staffPhaseTableRows.map((r) =>
                            this.getHoursToDateInPeriod(
                                period,
                                r?.id,
                                null,
                                null
                            )
                        )
                    )
                )
            },
            revenueVsExpensesMonthly: (period) => {
                return (
                    _.sum(
                        this.revenueTableRows.map((r) =>
                            this.getRevenueInPeriod(period, r?.id)
                        )
                    ) -
                    _.sum(
                        this.staffPhaseTableRows.map((r) =>
                            this.getCostInPeriod(period, r?.id, null, null)
                        )
                    ) -
                    _.sum(
                        this.expenseTableRows.map((r) =>
                            this.getExpensesInPeriod(period, r?.id, null)
                        )
                    )
                )
            },
            expenseBudgetMonthly: (period) => {
                return (
                    -_.sum(
                        this.staffPhaseTableRows.map((r) =>
                            this.getCostInPeriod(period, r?.id, null, null)
                        )
                    ) -
                    _.sum(
                        this.expenseTableRows.map((r) =>
                            this.getExpensesInPeriod(period, r?.id, null)
                        )
                    )
                )
            },
            hoursBudgetMonthly: (period) => {
                return -_.sum(
                    this.staffPhaseTableRows.map((r) =>
                        this.getHoursInPeriod(period, r?.id, null, null)
                    )
                )
            },
        }
        const showCost = this.cellType === 'currency'
        return [...Array(12)].map((v, i) => {
            const periodStart = this.addPeriods(this.startDate, i)
            const periodEnd = this.endOfPeriod(periodStart)
            const periodType =
                periodStart.getTime() ===
                this.startOfPeriod(new Date()).getTime()
                    ? 'current'
                    : periodStart.getTime() >
                        this.startOfPeriod(new Date()).getTime()
                      ? 'future'
                      : 'past'
            const isPastPeriod = periodType === 'past'
            const isCurrentPeriod = periodType === 'current'
            const isFuturePeriod = periodType === 'future'
            return {
                id: periodStart.getTime(),
                label: format(periodStart, this.periodDisplayFormat()),
                type: this.cellType,
                width: 8,
                editable: (row, stores) => {
                    return false
                },
                value: (row, stores) => {
                    return getValueLookup[this.graphType](
                        format(periodStart, this.periodFormat())
                    )
                },
                format: (v) =>
                    showCost
                        ? FormatCurrency(v, { decimals: 0 })
                        : FormatNumber(v, { decimals: 0 }),
            }
        })
    }

    @computed
    get totalsTableColumns() {
        return [
            spacerColumn,
            this.totalsLabelColumn,
            this.totalsTotalColumn,
            ...this.totalsPeriodColumns,
        ]
    }

    @computed
    get profitMarginLabelColumn() {
        return {
            id: 'label',
            label: 'Title',
            type: 'text',
            width: 18,
            editable: (row) => false,
            value: (row, stores) => {
                return 'Profit Margin'
            },
            component: ({ value, group, stores }) => {
                const { row, table } = stores
                return (
                    <div style={{ paddingLeft: `${1 * row.groupLevel}rem` }}>
                        {row.group !== 'project' && row.childRows.length ? (
                            <i
                                className={`fa fa-${`caret-${
                                    row.expanded ? 'down' : 'right'
                                }`} fa-fw`}
                                onClick={() => table.toggleRowExpand(row)}
                            />
                        ) : null}
                        {value}
                    </div>
                )
            },
        }
    }

    @computed
    get profitMarginTotalColumn() {
        return {
            id: 'total',
            label: 'Total',
            type: 'percent',
            width: 18,
            editable: (row) => false,
            value: () => {
                return (
                    (_.sum(
                        this.revenueTableRows.map((r) =>
                            this.getTotalRevenue(r.id)
                        )
                    ) -
                        _.sum(
                            this.staffPhaseTableRows.map((r) =>
                                this.getTotalCost(r.id, null, null)
                            )
                        ) -
                        _.sum(
                            this.expenseTableRows.map((r) =>
                                this.getTotalExpenses(r.id, null)
                            )
                        )) /
                    _.sum(
                        this.revenueTableRows.map((r) =>
                            this.getTotalRevenue(r.id)
                        )
                    )
                )
            },
        }
    }

    @computed
    get profitMarginPeriodColumns() {
        return [...Array(12)].map((v, i) => {
            const periodStart = this.addPeriods(this.startDate, i)
            const periodEnd = this.endOfPeriod(periodStart)
            const periodType =
                periodStart.getTime() ===
                this.startOfPeriod(new Date()).getTime()
                    ? 'current'
                    : periodStart.getTime() >
                        this.startOfPeriod(new Date()).getTime()
                      ? 'future'
                      : 'past'
            const period = format(periodStart, this.periodFormat())
            return {
                id: periodStart.getTime(),
                label: format(periodStart, this.periodDisplayFormat()),
                type: 'percent',
                width: 8,
                editable: (row, stores) => {
                    return false
                },
                value: (row, stores) => {
                    return (
                        (_.sum(
                            this.revenueTableRows.map((r) =>
                                this.getRevenueToDateInPeriod(period, r?.id)
                            )
                        ) -
                            _.sum(
                                this.staffPhaseTableRows.map((r) =>
                                    this.getCostToDateInPeriod(
                                        period,
                                        r?.id,
                                        null,
                                        null
                                    )
                                )
                            ) -
                            _.sum(
                                this.expenseTableRows.map((r) =>
                                    this.getExpensesToDateInPeriod(
                                        period,
                                        r?.id,
                                        null
                                    )
                                )
                            )) /
                        _.sum(
                            this.revenueTableRows.map((r) =>
                                this.getRevenueToDateInPeriod(period, r?.id)
                            )
                        )
                    )
                },
            }
        })
    }

    @computed
    get profitMarginTableColumns() {
        return [
            spacerColumn,
            this.profitMarginLabelColumn,
            this.profitMarginTotalColumn,
            ...this.profitMarginPeriodColumns,
        ]
    }
}

export default new ProjectForecastStore()
