import { createFileRoute, notFound } from '@tanstack/react-router'
import InvoicePdfPage from '../../Pages/InvoicePdfPage/InvoicePdfPage'
import PageBody from '../../Pages/Layout/PageBody'
import PageSidebar from '../../Pages/Layout/PageSidebar'
import InvoicePdfSidebar from '../../Pages/InvoicePdfPage/InvoicePdfSidebar'
import fetchData from '../../Queries/fetchData'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import { canViewRoute } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import { idf } from '../../Queries/queryFormatter'
import InvoiceCollection from '../../State/Collections/InvoiceCollection'

export const Route = createFileRoute('/_private/invoices/$id/pdf')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'invoicePdf', params))
            throw new Error('Permission Denied')
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loader: async ({ params }) => await loader(params.id),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
})

const loader = async (id) => {
    const collections = [
        {
            collection: 'invoices',
            fields: [
                'ref',
                'projectId',
                'dueDate',
                'startDate',
                'endDate',
                'issueDate',
                'contactId',
                'taxRatePercent',
                'accountingSystemId',
                'accountingSystemInvoiceId',
                'cachedData',
            ],
            filters: [idf(id)],
            chain: [
                {
                    collection: 'invoiceLineItems',
                    join: { invoices: 'id', invoiceLineItems: 'invoiceId' },
                    fields: [
                        'description',
                        'invoiceId',
                        'projectId',
                        'phaseId',
                        'billingType',
                        'lineItemType',
                        'unitCost',
                        'unitQuantity',
                        'isTaxed',
                        'staffIds',
                        'timesheetIds',
                        'expenseId',
                    ],
                },
                {
                    collection: 'projects',
                    join: { invoices: 'projectId', projects: 'id' },
                    fields: [
                        'jobNumber',
                        'name',
                        'address',
                        'invoiceOpen',
                        'invoiceClose',
                        'rootPhaseId',
                    ],
                },
                {
                    collection: 'phases',
                    join: { invoices: 'projectId', phases: 'projectId' },
                    fields: [
                        'jobNumber',
                        'name',
                        'projectId',
                        'startDate',
                        'fee',
                        'isRootPhase',
                    ],
                },
                {
                    collection: 'projectExpenses',
                    join: {
                        invoices: 'projectId',
                        projectExpenses: 'projectId',
                    },
                    fields: ['name', 'projectId', 'phaseId'],
                },
                {
                    collection: 'contacts',
                    join: {
                        invoices: 'contactId',
                        contacts: 'id',
                    },
                    fields: [
                        'firstName',
                        'lastName',
                        'organisationName',
                        'address',
                    ],
                },
            ],
        },
    ].filter(Boolean)
    const data = await Promise.all(collections.map(fetchData))
    const invoice =
        InvoiceCollection.invoicesById[id] ||
        InvoiceCollection.invoicesByOldId[id]
    if (!invoice) {
        throw notFound()
    }
    return data
}

function Wrapper() {
    const { id } = Route.useParams()
    return (
        <>
            <PageBody>
                <InvoicePdfPage id={id} />
            </PageBody>

            <PageSidebar>
                <InvoicePdfSidebar id={id} />
            </PageSidebar>
        </>
    )
}
