import React, { useState } from 'react'
import { Button } from '@2/components/ui/button'
import { Input } from '@2/components/ui/input'
import { MarqueeButton } from '@2/components/ui/marquee-button'
import { Avatar, AvatarFallback, AvatarImage } from '@2/components/ui/avatar'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@2/components/ui/dropdown-menu'
import { ScrollArea } from '@2/components/ui/scroll-area'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@2/components/ui/tooltip'
import {
    Building2,
    FolderKanban,
    Database,
    Users,
    Truck,
    FileText,
    Clock,
    CreditCard,
    ChevronLeft,
    MoreVertical,
    GanttChart,
    GanttChartSquare,
    PencilRulerIcon,
    Building,
    BuildingIcon,
    BookUserIcon,
    UsersIcon,
    Receipt,
    ReceiptIcon,
    ClockIcon,
    FileTextIcon,
    TruckIcon,
    ReceiptTextIcon,
    Home,
    List,
    Settings,
    Link,
    UserPlus,
    BarChart2,
    PieChart,
    ChevronDown,
    ChevronRight,
    Search,
    CheckSquare,
    GitCommit,
    DollarSign,
    StickyNote,
    ActivitySquare,
    Plus,
    PlusCircle,
} from 'lucide-react'
import { cn } from '@2/lib/utils'
import ReportCollection from '@/State/Collections/ReportCollection'
import ProjectCollection from '@/State/Collections/ProjectCollection'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { NavButton } from './components/navigation-button'
import { ExpandableList } from './components/expandable-list'

const ProjectsSubMenu = ({ setSubMenu }) => {
    const [expandedList, setExpandedList] = useState('reports')

    const lists = [
        {
            id: 'reports',
            title: 'Project Reports',
            items: (ReportCollection.reportsByType['project'] || [])
                .map((r) => ({
                    label: r.name,
                    value: r.id,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            id: 'active',
            title: 'Active Projects',
            items: ProjectCollection.projects
                .filter((p) => p.status === 'active')
                .map((pr) => ({
                    label: pr.title,
                    value: pr.id,
                    route: `/projects/${pr.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            id: 'prospective',
            title: 'Prospective Projects',
            items: ProjectCollection.projects
                .filter((p) => p.status === 'prospective')
                .map((pr) => ({
                    label: pr.title,
                    value: pr.id,
                    route: `/projects/${pr.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            id: 'onhold',
            title: 'On Hold Projects',
            items: ProjectCollection.projects
                .filter((p) => p.status === 'onHold')
                .map((pr) => ({
                    label: pr.title,
                    value: pr.id,
                    route: `/projects/${pr.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            id: 'archived',
            title: 'Archived Projects',
            items: ProjectCollection.projects
                .filter((p) => p.status === 'archived')
                .map((pr) => ({
                    label: pr.title,
                    value: pr.id,
                    route: `/projects/${pr.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
    ]

    return (
        <div className="flex flex-col h-full">
            <div className="flex justify-between items-center mb-4">
                <h4 className="m-0">Projects</h4>
                <TooltipProvider>
                    <Tooltip delayDuration={0}>
                        <TooltipTrigger asChild>
                            <Button
                                variant="outline"
                                size="icon"
                                className="h-6 w-6"
                                onClick={() => {
                                    // Add your create project logic here
                                    console.log('Create Project clicked')
                                }}
                            >
                                <Plus className="h-4 w-4" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>Create Project</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </div>
            {lists.map((list) => (
                <ExpandableList
                    key={list.id}
                    title={list.title}
                    items={list.items}
                    isExpanded={expandedList === list.id}
                    onToggle={() =>
                        setExpandedList(
                            expandedList === list.id ? null : list.id
                        )
                    }
                />
            ))}
        </div>
    )
}

const ProjectSubMenu = ({ setSubMenu, title }) => {
    return (
        <div className="flex flex-col h-full space-y-1">
            <h4>{title}</h4>
            <Button
                variant={'ghost'}
                className="w-full justify-start text-foreground hover:bg-accent hover:text-accent-foreground"
            >
                <FileText className="mr-2 h-4 w-4" />
                Details
            </Button>
            <Button
                variant={'ghost'}
                className="w-full justify-start text-foreground hover:bg-accent hover:text-accent-foreground"
            >
                <BarChart2 className="mr-2 h-4 w-4" />
                Forecasts
            </Button>
            <Button
                variant={'ghost'}
                className="w-full justify-start text-foreground hover:bg-accent hover:text-accent-foreground"
            >
                <CheckSquare className="mr-2 h-4 w-4" />
                Tasks
            </Button>
            <Button
                variant={'ghost'}
                className="w-full justify-start text-foreground hover:bg-accent hover:text-accent-foreground"
            >
                <GitCommit className="mr-2 h-4 w-4" />
                ChangeLog
            </Button>
            <Button
                variant={'ghost'}
                className="w-full justify-start text-foreground hover:bg-accent hover:text-accent-foreground"
            >
                <DollarSign className="mr-2 h-4 w-4" />
                Rates
            </Button>
            <Button
                variant={'ghost'}
                className="w-full justify-start text-foreground hover:bg-accent hover:text-accent-foreground"
            >
                <StickyNote className="mr-2 h-4 w-4" />
                Notes
            </Button>
            <Button
                variant={'ghost'}
                className="w-full justify-start text-foreground hover:bg-accent hover:text-accent-foreground"
            >
                <Clock className="mr-2 h-4 w-4" />
                History
            </Button>
            <Button
                variant={'ghost'}
                className="w-full justify-start text-foreground hover:bg-accent hover:text-accent-foreground"
            >
                <ActivitySquare className="mr-2 h-4 w-4" />
                Status
            </Button>
        </div>
    )
}

const OrgSubMenu = () => {
    const [expandedList, setExpandedList] = useState('reports')

    const lists = [
        {
            id: 'reports',
            title: 'Org Reports',
            items: ReportCollection.reportsByType['project'],
        },
        {
            id: 'active',
            title: 'Active Orgs',
            items: ProjectCollection.projects.filter(
                (p) => p.status === 'active'
            ),
        },
        {
            id: 'prospective',
            title: 'Prospective Orgs',
            items: ProjectCollection.projects.filter(
                (p) => p.status === 'prospective'
            ),
        },
        {
            id: 'onhold',
            title: 'On Hold Orgs',
            items: ProjectCollection.projects.filter(
                (p) => p.status === 'onHold'
            ),
        },
        {
            id: 'archived',
            title: 'Archived Orgs',
            items: ProjectCollection.projects.filter(
                (p) => p.status === 'archived'
            ),
        },
    ]

    return (
        <div className="flex flex-col h-full">
            {lists.map((list) => (
                <ExpandableList
                    key={list.id}
                    title={list.title}
                    items={list.items}
                    isExpanded={expandedList === list.id}
                    onToggle={() =>
                        setExpandedList(
                            expandedList === list.id ? null : list.id
                        )
                    }
                />
            ))}
        </div>
    )
}
export const NavigationSidebar = () => {
    const [subMenu, setSubMenu] = useState(null)
    const [parent, enableAnimations] = useAutoAnimate(/* optional config */)
    return (
        <div ref={parent} className="flex h-screen">
            <MainNavigationSidebar setSubMenu={setSubMenu} subMenu={subMenu} />
            {subMenu && (
                <SubNavigationSidebar setSubMenu={setSubMenu}>
                    {subMenu}
                </SubNavigationSidebar>
            )}
        </div>
    )
}

export const MainNavigationSidebar = ({ subMenu, setSubMenu }) => {
    const isExpanded = !subMenu

    return (
        <nav
            className={cn(
                'relative flex flex-col h-full bg-background transition-all duration-300',
                isExpanded ? 'w-60' : 'w-16'
            )}
        >
            <div className="flex grow flex-col gap-y-6 overflow-hidden p-4">
                <div
                    className={cn(
                        'flex items-center',
                        isExpanded
                            ? 'justify-start space-x-2'
                            : 'justify-center'
                    )}
                >
                    <div className="w-7 h-7 bg-yellow-400 rounded-full flex-shrink-0"></div>
                    {isExpanded && (
                        <span className="text-lg font-semibold">coincraft</span>
                    )}
                </div>

                <ScrollArea className="flex-1 -mx-4 px-4">
                    <div className="space-y-1">
                        <NavButton
                            isExpanded={isExpanded}
                            icon={BuildingIcon}
                            label="Organisation"
                            onClick={() =>
                                setSubMenu(
                                    <OrgSubMenu setSubMenu={setSubMenu} />
                                )
                            }
                        />
                        <NavButton
                            isExpanded={isExpanded}
                            icon={PencilRulerIcon}
                            label="Projects"
                            onClick={() =>
                                setSubMenu(
                                    <ProjectsSubMenu setSubMenu={setSubMenu} />
                                )
                            }
                        />
                        <NavButton
                            isExpanded={isExpanded}
                            icon={UsersIcon}
                            label="Resources"
                            onClick={() => setSubMenu(null)}
                        />
                        <NavButton
                            isExpanded={isExpanded}
                            icon={BookUserIcon}
                            label="Clients"
                            onClick={() => setSubMenu(null)}
                        />
                        <NavButton
                            isExpanded={isExpanded}
                            icon={TruckIcon}
                            label="Suppliers"
                            onClick={() => setSubMenu(null)}
                        />
                        <NavButton
                            isExpanded={isExpanded}
                            icon={FileTextIcon}
                            label="Invoicing"
                            onClick={() => setSubMenu(null)}
                        />
                        <NavButton
                            isExpanded={isExpanded}
                            icon={ClockIcon}
                            label="Time"
                            onClick={() => setSubMenu(null)}
                        />
                        <NavButton
                            isExpanded={isExpanded}
                            icon={ReceiptTextIcon}
                            label="Expenses"
                            onClick={() => setSubMenu(null)}
                        />
                    </div>
                </ScrollArea>

                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button
                            variant="ghost"
                            className={cn(
                                'w-full justify-between text-foreground hover:bg-accent hover:text-accent-foreground',
                                !isExpanded && 'justify-center p-2'
                            )}
                        >
                            <div className="flex items-center gap-2">
                                <Avatar className="h-8 w-8">
                                    <AvatarFallback className="bg-primary text-primary-foreground">
                                        LC
                                    </AvatarFallback>
                                </Avatar>
                                {isExpanded && <span>Le Corbusier</span>}
                            </div>
                            {isExpanded && <MoreVertical className="h-4 w-4" />}
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-[200px]">
                        <DropdownMenuItem>Profile</DropdownMenuItem>
                        <DropdownMenuItem>Settings</DropdownMenuItem>
                        <DropdownMenuItem>Logout</DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </nav>
    )
}

export const SubNavigationSidebar = ({ children }) => {
    return (
        <nav className="flex flex-col w-64 h-full border-l border-border bg-background  transition-all duration-300">
            <div className="flex flex-col h-full p-4 overflow-y-auto overflow-x-hidden relative">
                <div>{children}</div>
            </div>
        </nav>
    )
}
