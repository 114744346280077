import { addDays, differenceInDays, format, parse } from 'date-fns'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import InvoiceCollection from '../../State/Collections/InvoiceCollection'
import LayoutStore from '../../State/LayoutStore'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import SessionStore from '../../State/SessionStore'
import { columnDefs } from './PdfTableColumnDefs'
import { canEditProjectInvoices } from '../../State/Permissions/HasPermissions'
import RenderOnQueries from '../Layout/RenderOnQueries'
import { qf } from '../../Queries/queryFormatter'

export default observer(({ id }) => {
    const invoice = InvoiceCollection.invoicesById[id]
    const template = SessionStore.organisation.defaultInvoiceTemplate
    useEffect(() => {
        if (canEditProjectInvoices(SessionStore.user, invoice.project))
            LayoutStore.toggleSidebar(true)
    }, [])
    if (!invoice) return null
    return (
        <RenderOnQueries
            key={invoice.id}
            queryIds={[
                {
                    id: 'te' + new Date(),
                    collection: 'timeEntries',
                    fields: [
                        'projectId',
                        'numMinutes',
                        ['chargeOut', 'chargeOut::numeric/100'],
                        'phaseId',
                        'staffId',
                        'date',
                        'isVariation',
                        'isBillable',
                        'beenInvoiced',
                        'taskId',
                        'notes',
                    ],
                    filters: [
                        `projectId == "${invoice.project.id}"`,
                        `date >= ${qf(invoice.startDate)}`,
                        `date <= ${qf(invoice.endDate)}`,
                    ],
                    staleTime: 0,
                },
            ]}
        >
            <PaperPages invoice={invoice} template={template} />
        </RenderOnQueries>
    )
})

const PaperPages = observer(({ invoice, template }) => {
    const defaultTitle = useRef(document.title)
    useEffect(() => {
        document.title = invoice.ref
        return () => {
            document.title = defaultTitle.current
        }
    }, [])
    const [numPages, setNumPages] = useState(1)
    const [pageContents, setPageContents] = useState([
        {
            showStart: true,
            showEnd: true,
            showTable: true,
            lineItems: [0, invoice.lineItems.length - 1],
            showTotals: true,
        },
    ])
    const addPageOverflow = useCallback(
        (index, content, overflowContent) => {
            if (
                (pageContents[index].showStart !== content.showStart ||
                    pageContents[index].showEnd !== content.showEnd ||
                    pageContents[index].showTable !== content.showTable ||
                    pageContents[index].lineItems[0] !== content.lineItems[0] ||
                    pageContents[index].lineItems[1] !== content.lineItems[1] ||
                    pageContents[index].showTotals !== content.showTotals) &&
                content.lineItems[1] > 0
            ) {
                let newPageContents = [...pageContents]
                newPageContents[index] = {
                    ...content,
                    lineItems: [...content.lineItems],
                }
                if (
                    overflowContent.showStart ||
                    overflowContent.showEnd ||
                    overflowContent.showTable
                )
                    newPageContents[index + 1] = {
                        ...overflowContent,
                        lineItems: [...overflowContent.lineItems],
                    }
                setPageContents(newPageContents)
                setNumPages(newPageContents.length)
            }
        },
        [pageContents]
    )
    const pages = [...Array(numPages).keys()].map((i) => (
        <PaperPage
            key={i}
            index={i}
            content={pageContents[i]}
            addPageOverflow={addPageOverflow}
            invoice={invoice}
            invoiceTemplate={template}
        />
    ))
    return <div className="flex text-center flex-col mx-auto">{pages}</div>
})

const PaperPage = observer(
    ({ content, index, addPageOverflow, invoice, invoiceTemplate }) => {
        const pageRef = useRef()
        const projectAddress = invoice.project.address // observer hack
        useEffect(() => {
            const pageEl = pageRef.current
            let newContent = {
                ...content,
                lineItems: [...content.lineItems],
            }
            const maxHeight = Math.ceil(
                parseFloat(getComputedStyle(pageEl).getPropertyValue('height'))
            )
            const removeInvoiceRow = () => {
                if (newContent.lineItems[1] === invoice.lineItems.length - 1) {
                    for (let el of pageEl.getElementsByClassName(
                        'invoice-table-totals'
                    )) {
                        el.style.display = 'none'
                    }
                    newContent.showTotals = false
                }
                const rows = [
                    ...pageEl.getElementsByClassName('invoice-table-row'),
                ].filter((el) => el.style.display !== 'none')
                if (rows.length) {
                    rows[rows.length - 1].style.display = 'none'
                    newContent.lineItems[1]--
                    if (newContent.lineItems[1] === 0) {
                        for (let el of pageEl.getElementsByClassName(
                            'invoice-table'
                        )) {
                            el.style.display = 'none'
                        }
                        newContent.showTable = false
                    }
                }
            }
            if (pageEl.scrollHeight > maxHeight) {
                for (let el of pageEl.getElementsByClassName('content-end')) {
                    el.style.display = 'none'
                }
                newContent.showEnd = false
            }
            const prevScrollHeight = null
            while (pageEl.scrollHeight > maxHeight) {
                removeInvoiceRow()
            }
            const overflowContent = {
                showStart: false,
                showEnd: !newContent.showEnd,
                showTable:
                    newContent.lineItems[1] < invoice.lineItems.length - 1,
                lineItems: [
                    Math.min(
                        invoice.lineItems.length - 1,
                        newContent.lineItems[1] + 1
                    ),
                    invoice.lineItems.length - 1,
                ],
                showTotals: true,
            }
            addPageOverflow(index, newContent, overflowContent)
        }, [content, index, invoiceTemplate])
        return (
            <div className="invoice-page">
                <div className="header">
                    <div
                        className="logo"
                        style={{
                            height: `${invoiceTemplate.logoSize}em`,
                        }}
                    >
                        <img
                            src={invoiceTemplate.logoUrl}
                            alt="logo"
                            style={{ height: '100%' }}
                        />
                    </div>
                    {invoiceTemplate.getProjectText(invoice) && (
                        <div className="project-info">
                            <span className="inv-bold">Project</span>{' '}
                            {invoiceTemplate.getProjectText(invoice)}
                        </div>
                    )}
                    <div className="client-info">
                        <span className="inv-bold">Client</span>{' '}
                        {invoiceTemplate.getClientText(invoice)}
                    </div>
                    <div className="issue-date">
                        <span className="inv-bold">Issued</span>{' '}
                        {format(invoice.issueDate, 'dd MMM yyyy', new Date())}
                    </div>
                    <div className="inv-highlight inv-number">
                        <div className="label">Tax Invoice</div>
                        <div className="value">{invoice.ref}</div>
                    </div>
                    <div className="inv-highlight due-date">
                        <div className="label">Due Date</div>
                        <div className="value">
                            {format(invoice.dueDate, 'dd MMM yyyy', new Date())}
                        </div>
                    </div>
                    <div
                        className="inv-highlight amount-due"
                        style={{ textAlign: 'right' }}
                    >
                        <div className="label">Amount Due</div>
                        <div className="value">
                            {FormatCurrency(invoice.amount + invoice.tax)}
                        </div>
                    </div>
                </div>

                <div
                    ref={pageRef}
                    className="content"
                    style={{
                        flex: '1 1 auto',
                        padding: '0.5em',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {content.showStart &&
                        invoiceTemplate.getOpeningText(invoice) && (
                            <div
                                className="content-start"
                                style={{
                                    flex: '0 0 auto',
                                    lineHeight: '1.5em',
                                    marginBottom: '0.5em',
                                }}
                            >
                                {invoiceTemplate.getOpeningText(invoice)}
                            </div>
                        )}
                    <div
                        className="content-mid"
                        style={{
                            flex: '1 1 auto',
                        }}
                    >
                        {content.showTable && (
                            <InvTable
                                invoice={invoice}
                                invoiceTemplate={invoiceTemplate}
                                lineItems={invoice.lineItems
                                    ?.sort((a, b) => {
                                        // Prioritize root phases to come last
                                        if (
                                            a.phase?.isRootPhase &&
                                            !b.phase?.isRootPhase
                                        ) {
                                            return 1
                                        } else if (
                                            !a.phase?.isRootPhase &&
                                            b.phase?.isRootPhase
                                        ) {
                                            return -1
                                        }

                                        // Compare by startDate if both are not root phases
                                        if (
                                            !a.phase?.isRootPhase &&
                                            !b.phase?.isRootPhase
                                        ) {
                                            if (
                                                a.phase?.startDate &&
                                                b.phase?.startDate
                                            ) {
                                                const startDateComparison =
                                                    new Date(
                                                        a.phase?.startDate
                                                    ) -
                                                    new Date(b.phase?.startDate)
                                                if (startDateComparison !== 0) {
                                                    return startDateComparison
                                                }
                                            } else if (a.phase?.startDate) {
                                                return -1
                                            } else if (b.phase?.startDate) {
                                                return 1
                                            }
                                        }

                                        // Compare by title if dates are equal or absent
                                        const titleComparison =
                                            a.phase?.title && b.phase?.title
                                                ? a.phase?.title?.localeCompare?.(
                                                      b.phase?.title
                                                  )
                                                : 0
                                        if (titleComparison !== 0) {
                                            return titleComparison
                                        }

                                        // Lastly, compare by createdAt
                                        if (
                                            (a.createdAt || a.initiatedAt) &&
                                            (b.createdAt || b.initiatedAt)
                                        ) {
                                            const createdAtComparison =
                                                new Date(
                                                    a.createdAt || a.initiatedAt
                                                ) -
                                                new Date(
                                                    b.createdAt || b.initiatedAt
                                                )
                                            if (createdAtComparison !== 0) {
                                                return createdAtComparison
                                            } else {
                                                // If createdAt dates are the same, compare by timeEntryStartDate
                                                if (
                                                    a.timeEntryStartDate &&
                                                    b.timeEntryStartDate
                                                ) {
                                                    return (
                                                        new Date(
                                                            a.timeEntryStartDate
                                                        ) -
                                                        new Date(
                                                            b.timeEntryStartDate
                                                        )
                                                    )
                                                } else if (
                                                    a.timeEntryStartDate
                                                ) {
                                                    return -1
                                                } else if (
                                                    b.timeEntryStartDate
                                                ) {
                                                    return 1
                                                }
                                            }
                                        } else if (
                                            a.createdAt ||
                                            a.initiatedAt
                                        ) {
                                            return -1
                                        } else if (
                                            b.createdAt ||
                                            b.initiatedAt
                                        ) {
                                            return 1
                                        }

                                        // If all else is equal, maintain original order
                                        return 0
                                    })
                                    .slice(
                                        content.lineItems[0],
                                        content.lineItems[1] + 1
                                    )}
                                showTotals={content.showTotals}
                            />
                        )}
                    </div>
                    {content.showEnd &&
                        invoiceTemplate.getClosingText(invoice) && (
                            <div
                                className="content-end"
                                style={{
                                    flex: '0 0 auto',
                                    lineHeight: '1.5em',
                                }}
                            >
                                {invoiceTemplate.getClosingText(invoice)}
                            </div>
                        )}
                </div>
                <div
                    style={{
                        flex: '0 0 auto',
                        paddingTop: '0.625em',
                        borderTop: 'solid 2px #444',
                        marginTop: '0.5em',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ textAlign: 'left', lineHeight: '1.5em' }}>
                        {invoiceTemplate.footerLeft}
                    </div>
                    <div style={{ textAlign: 'right', lineHeight: '1.5em' }}>
                        {invoiceTemplate.footerRight}
                    </div>
                </div>
            </div>
        )
    }
)

const InvTable = observer(
    ({ lineItems, invoice, invoiceTemplate, showTotals }) => {
        const columns = [
            ...invoiceTemplate.columns,
            ...(!invoiceTemplate.columns.includes('amount') ? ['amount'] : []),
        ]
        let cells = [
            <div
                key={'h0'}
                className="invoice-table-header"
                style={{ display: 'contents' }}
            >
                {columns.map((c, i) => {
                    return (
                        <div
                            key={i}
                            className={`header cell ${columnDefs[c].align}`}
                        >
                            {columnDefs[c].label}
                        </div>
                    )
                })}
            </div>,
        ]
        lineItems.forEach((line, index) => {
            const lineClass =
                index !== lineItems.length - 1 ? 'cell' : 'cell last'
            cells.push(
                <div
                    key={'r' + index}
                    className="invoice-table-row"
                    style={{ display: 'contents' }}
                >
                    {columns.map((c, i) => {
                        return (
                            <div
                                key={index + i}
                                className={`${lineClass} ${columnDefs[c].align}`}
                            >
                                {columnDefs[c].value(line)}
                            </div>
                        )
                    })}
                </div>
            )
        })
        return (
            <>
                <div
                    className="invoice-table"
                    style={{
                        gridTemplateColumns: columns
                            .map((c) => `${columnDefs[c].width}fr`)
                            .join(' '),
                    }}
                >
                    {cells}
                </div>
                <div
                    className="invoice-table invoice-table-totals"
                    style={{
                        gridTemplateColumns: '3fr 1fr 1fr 1fr',
                    }}
                >
                    <div className="cell right total label">Sub total</div>
                    <div className="cell right total amount">
                        {FormatCurrency(invoice.amount)}
                    </div>
                    <div className="cell right total label">
                        {invoiceTemplate.taxName}
                    </div>
                    <div className="cell right total amount">
                        {FormatCurrency(invoice.tax)}
                    </div>
                    <div className="cell right total label last">
                        Amount Due
                    </div>
                    <div className="cell right total amount last">
                        {FormatCurrency(invoice.amount + invoice.tax)}
                    </div>
                </div>
            </>
        )
    }
)
