import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import {
    ContactSelector,
    CostCentreSelector,
    PhaseSelector,
    StaffOrRoleSelector,
    StaffSelector,
    Selector,
    SupplierSelector,
} from '../../Components/Selector'
import Table from '../../Components/Table'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import ProjectExpenseCollection from '../../State/Collections/ProjectExpenseCollection'
import BudgetCollection from '../../State/Collections/BudgetCollection'
import useHover from '@react-hook/hover'
import useMouse from '@react-hook/mouse-position'
import { observable, makeObservable, action, computed, toJS } from 'mobx'
import _ from 'lodash'
import {
    canEditPhasesAndTasks,
    canEditProject,
    canEditProjectDates,
    canEditProjectExpenseBudgets,
    canEditProjectExpenses,
    canEditProjectFees,
    canEditProjectHoursBudgets,
    canEditProjectStaffBudgets,
    canEditRevenueTargets,
    canEditSuppliers,
    canViewContacts,
    canViewCostCentres,
    canViewExpenseTracking,
    canViewPrimaryContact,
    canViewProjectDates,
    canViewProjectDetails,
    canViewProjectExpenseBudgets,
    canViewProjectExpenses,
    canViewProjectFees,
    canViewProjectStaffBudgets,
    canViewProjectStaffChargeOut,
    canViewRevenueTargets,
    canViewStaffChargeOutRate,
    canViewStaffCostRate,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import BudgetModel from '../../State/Models/BudgetModel'
import CellComponent from '../../Components/CellComponent'
import sortPhases from '../../Utils/sortPhases'

const projectColumns = [
    {
        id: 'projectCode',
        label: 'Project Code',
        width: 8,
        type: 'text',
        editable: (r) => canEditProject(SessionStore.user, r),
        value: (r) => r.jobNumber,
        onChange: (r) => (v) => r.update({ jobNumber: v }),
    },
    {
        id: 'projectName',
        label: 'Project Name',
        width: 22,
        type: 'text',
        editable: (r) => canEditProject(SessionStore.user, r),
        value: (p) => {
            return p.name
        },
        onChange: (r) => (v) => r.update({ name: v }),
    },
    {
        id: 'startDate',
        label: 'Start Date',
        width: 11,
        type: 'date',
        editable: (r) => canEditProjectDates(SessionStore.user, r),
        permissions: (r) => canViewProjectDates(SessionStore.user, r),
        value: (p) => p.startDate,
        onChange: (r) => (v) => {
            const newData = { startDate: v }
            if (r.endDate && r.endDate < v) {
                newData.endDate = v
            }
            r.scaleDatesAcrossPhases(newData)
        },
    },
    {
        id: 'endDate',
        label: 'End Date',
        width: 11,
        type: 'date',
        editable: (r) => canEditProjectDates(SessionStore.user, r),
        permissions: (r) => canViewProjectDates(SessionStore.user, r),
        value: (p) => p.endDate,
        onChange: (r) => (v) => {
            const newData = { endDate: v }
            if (r.startDate && r.startDate > v) {
                newData.startDate = v
            }
            r.scaleDatesAcrossPhases(newData)
        },
    },
    {
        id: 'Duration',
        label: 'Duration',
        width: 16,
        type: 'duration',
        editable: (r) => canEditProjectDates(SessionStore.user, r),
        permissions: (r) => canViewProjectDates(SessionStore.user, r),
        value: (p) => {
            return { value: p.duration, unit: p.durationUnit }
        },
        onChange: (r) => (v) => {
            if (v.value && v.value !== r.duration) {
                r.scaleDatesAcrossPhases({
                    duration: { ...v, unit: r.durationUnit },
                })
            }
            if (v.unit && v.unit !== r.durationUnit) {
                r.update({ durationUnit: v.unit })
            }
        },
    },
    {
        id: 'fee',
        label: 'Fee',
        width: 10,
        type: 'currency',
        editable: (r) => canEditProjectFees(SessionStore.user, r),
        permissions: (r) => canViewProjectFees(SessionStore.user, r),
        value: (p) => p.fee,
        onChange: (r) => (v) => {
            v = Number(v) || 0.001
            r.scaleValueAcrossPhases('fee', v)
            r.phases.forEach((ph) => ph.updateBudgets('fee'))
        },
    },
    {
        id: 'expenseBudget',
        label: 'Expense Budget',
        width: 10,
        type: 'currency',
        editable: (r) => canEditProjectExpenseBudgets(SessionStore.user, r),
        permissions: (r) => canViewProjectExpenseBudgets(SessionStore.user, r),
        value: (p) => {
            return (
                p.expenseBudget +
                _.sum(
                    p.expenses
                        .filter(
                            (e) =>
                                !e.billable &&
                                (!e.phase || e.phase?.isRootPhase)
                        )
                        .map((e) => e.cost)
                )
            )
        },
        onChange: (r) => (v) => {
            v = Number(v) || 0.001
            v -= _.sum(
                r.expenses
                    .filter(
                        (e) => !e.billable && (!e.phase || e.phase?.isRootPhase)
                    )
                    .map((e) => e.cost)
            )
            r.scaleValueAcrossPhases('expenseBudget', v)
            r.phases.forEach((ph) => ph.updateBudgets('expenseBudget'))
        },
    },
    {
        id: 'hoursBudget',
        label: 'Hours Budget',
        width: 10,
        type: 'number',
        editable: (r) => canEditProjectHoursBudgets(SessionStore.user, r),
        onChange: (r) => (v) => {
            v = Number(v) || 0.001
            r.scaleValueAcrossPhases('hoursBudget', v)
            r.phases.forEach((ph) => ph.updateBudgets('hoursBudget'))
        },
    },
    {
        id: 'profit',
        label: 'Profit',
        width: 10,
        type: 'currency',
        value: (p) =>
            p.fee -
            p.expenseBudget -
            _.sum(
                p.expenses
                    .filter(
                        (e) => !e.billable && (!e.phase || e.phase?.isRootPhase)
                    )
                    .map((e) => e.cost)
            ),
        permissions: (r) =>
            canViewProjectFees(SessionStore.user, r) &&
            canViewProjectExpenseBudgets(SessionStore.user, r),
        onChange: (r) => (v) => null,
        style: (r) => {
            if (!r) return {}
            const profit =
                r.fee -
                r.expenseBudget -
                _.sum(
                    r.expenses
                        .filter(
                            (e) =>
                                !e.billable &&
                                (!e.phase || e.phase?.isRootPhase)
                        )
                        .map((e) => e.cost)
                )
            return profit < 0 ? { color: 'red' } : {}
        },
    },
    {
        id: 'profitMargin',
        label: '',
        width: 6,
        type: 'percent',
        value: (p) =>
            p.fee
                ? (p.fee -
                      p.expenseBudget -
                      _.sum(
                          p.expenses
                              .filter(
                                  (e) =>
                                      !e.billable &&
                                      (!e.phase || e.phase?.isRootPhase)
                              )
                              .map((e) => e.cost)
                      )) /
                  p.fee
                : null,
        permissions: (r) =>
            canViewProjectFees(SessionStore.user, r) &&
            canViewProjectExpenseBudgets(SessionStore.user, r),
        onChange: (r) => (v) => null,
        style: (r) => {
            if (!r) return {}
            const profit =
                r.fee -
                r.expenseBudget -
                _.sum(
                    r.expenses
                        .filter(
                            (e) =>
                                !e.billable &&
                                (!e.phase || e.phase?.isRootPhase)
                        )
                        .map((e) => e.cost)
                )
            return profit < 0 ? { color: 'red' } : {}
        },
    },
    {
        label: '',
        width: 4,
        type: 'text',
        value: (p) => null,
        onClick: (r) => (v) => null,
    },
]

const phaseColumns = (store) => [
    {
        id: 'phaseCode',
        label: 'Phase Code',
        width: 8,
        type: 'text',
        editable: (r) => canEditProject(SessionStore.user, r.project),
        value: (p) => p.jobNumber,
        onChange: (r) => (v) => r.update({ jobNumber: v }),
    },
    {
        id: 'phaseName',
        label: 'Phase Name',
        width: 19,
        type: 'text',
        editable: (r) => canEditProject(SessionStore.user, r.project),
        value: (p) => p.name,
        onChange: (r) => (v) => r.update({ name: v }),
    },
    {
        id: 'startDate',
        label: 'Start Date',
        width: 11,
        type: 'date',
        editable: (r) => canEditProjectDates(SessionStore.user, r.project),
        permissions: (r) => canViewProjectDates(SessionStore.user, r.project),
        value: (p) => p.startDate,
        onChange: (r) => (v) => {
            const newData = { startDate: v }
            if (r.endDate && r.endDate < v) {
                newData.endDate = v
            }
            r.update(newData)
        },
    },
    {
        id: 'endDate',
        label: 'End Date',
        width: 11,
        type: 'date',
        editable: (r) => canEditProjectDates(SessionStore.user, r.project),
        permissions: (r) => canViewProjectDates(SessionStore.user, r.project),
        value: (p) => p.endDate,
        onChange: (r) => (v) => {
            const newData = { endDate: v }
            if (r.startDate && r.startDate > v) {
                newData.startDate = v
            }
            r.update(newData)
        },
    },
    {
        id: 'duration',
        label: 'Duration',
        width: 16,
        type: 'duration',
        editable: (r) => canEditProjectDates(SessionStore.user, r.project),
        permissions: (r) => canViewProjectDates(SessionStore.user, r.project),
        value: (p) => ({ value: p.duration, unit: p.durationUnit }),
        onChange: (r) => (v) => {
            if (v.value && v.value !== r.duration) {
                r.updateDuration({ ...v, unit: r.durationUnit })
            }
            if (v.unit && v.unit !== r.durationUnit) {
                r.update({ durationUnit: v.unit })
            }
        },
    },
    {
        id: 'fee',
        label: 'Fee',
        width: 10,
        type: 'currency',
        editable: (r) => canEditProjectFees(SessionStore.user, r.project),
        permissions: (r) => canViewProjectFees(SessionStore.user, r.project),
        value: (r) => store.getPhaseFee(r),
        onChange: (r) => (v) => {
            v = Number(v) || 0.001
            r.update({ fee: v })
            r.updateBudgets('fee')
        },
    },
    {
        id: 'expenseBudget',
        label: 'Expense Budget',
        width: 10,
        type: 'currency',
        editable: (r) =>
            canEditProjectExpenseBudgets(SessionStore.user, r.project),
        permissions: (r) =>
            canViewProjectExpenseBudgets(SessionStore.user, r.project),
        value: (r) => store.getPhaseExpenseBudget(r),
        onChange: (r) => (v) => {
            v = Number(v) || 0.001
            r.update({ expenseBudget: v })
            r.updateBudgets('expenseBudget')
        },
    },
    {
        id: 'hoursBudget',
        label: 'Hours Budget',
        width: 10,
        type: 'number',
        editable: (r) =>
            canEditProjectHoursBudgets(SessionStore.user, r.project),
        value: (r) => store.getPhaseHoursBudget(r),
        onChange: (r) => (v) => {
            v = Number(v) || 0.001
            r.update({ hoursBudget: v })
            r.updateBudgets('hoursBudget')
        },
    },
    {
        id: 'profit',
        label: 'Profit',
        width: 10,
        type: 'currency',
        value: (p) => p.fee - p.expenseBudget,
        permissions: (r) =>
            canViewProjectFees(SessionStore.user, r.project) &&
            canViewProjectExpenseBudgets(SessionStore.user, r.project),
        onChange: (r) => (v) => null,
        style: (r) => {
            if (!r) return {}
            const profit = r.fee - r.expenseBudget
            return profit < 0 ? { color: 'red' } : {}
        },
    },
    {
        id: 'profitMargin',
        label: '',
        width: 6,
        type: 'percent',
        value: (p) => (p.fee ? (p.fee - p.expenseBudget) / p.fee : null),
        permissions: (r) =>
            canViewProjectFees(SessionStore.user, r.project) &&
            canViewProjectExpenseBudgets(SessionStore.user, r.project),
        onChange: (r) => (v) => null,
        style: (r) => {
            if (!r) return {}
            const profit = r.fee - r.expenseBudget
            return profit < 0 ? { color: 'red' } : {}
        },
    },
    {
        id: 'button',
        label: '',
        width: 4,
        type: 'button',
        editable: (r) => canEditPhasesAndTasks(SessionStore.user, r.project),
        value: (p) =>
            canEditPhasesAndTasks(SessionStore.user, p.project) ? (
                <i className="fa fa-times" style={{ marginRight: 0 }} />
            ) : null,
        onClick: (r) => () => {
            r.update({ deletedAt: new Date() })
        },
    },
    {
        id: 'createdAt',
        label: 'Created At',
        width: 18,
        type: 'number',
        editable: (r) => false,
        value: (e) => Math.round(e.initiatedAt.getTime() / 10),
        visible: false,
    },
]

const expenseColumns = () => [
    ...(canViewExpenseTracking()
        ? [
              {
                  label: '',
                  width: 3,
                  type: 'text',
                  value: (r) => '',
                  onChange: (r) => (v) => null,
                  component: (props) => null,
              },
          ]
        : []),
    {
        id: 'name',
        label: 'Name',
        width: canViewExpenseTracking() ? 27 : 30,
        type: 'text',
        editable: (r) => canEditProjectExpenses(SessionStore.user, r.project),
        value: (e) => e.name,
        onChange: (r) => (v) => r.update({ name: v }),
    },
    ...(canViewExpenseTracking()
        ? [
              {
                  id: 'supplier',
                  label: 'Supplier',
                  width: 29,
                  type: 'supplier',
                  editable: (r) => canEditSuppliers(SessionStore.user),
                  value: (e) => {
                      return e.supplier
                  },
                  onChange: (r) => (v) => {
                      r.update({ supplierId: v.id })
                  },
                  component: ({ value, onChange, stores, editable }) => {
                      return editable ? (
                          <SupplierSelector
                              {...{
                                  selectedSupplier: value,
                                  onChange,
                                  nullable: true,
                              }}
                              variant="secondary"
                          />
                      ) : (
                          value?.name || 'No Supplier'
                      )
                  },
              },
              {
                  label: '',
                  width: 19,
                  type: 'text',
                  value: (r) => '',
                  onChange: (r) => (v) => null,
                  component: (props) => null,
              },
          ]
        : [
              {
                  id: 'phase',
                  label: 'Phase',
                  width: canViewExpenseTracking() ? 20 : 28,
                  type: 'phase',
                  editable: (r) =>
                      canEditProjectExpenses(SessionStore.user, r.project),
                  value: (e) => {
                      return e.phase
                  },
                  onChange: (r) => (v) => {
                      r.update({ phaseId: v.id })
                  },
                  component: ({ value, onChange, stores, editable }) => {
                      const project = stores.row.rowObject.project
                      return editable && project ? (
                          <PhaseSelector
                              options={project.phases}
                              {...{ selectedPhase: value, onChange }}
                              variant="secondary"
                              className="!border-none !bg-transparent [&_span]:!text-xs !shadow-[inset_6px_6px_13px_-10px_rgba(0,0,0,0.22)] !h-full !rounded-none"
                          />
                      ) : (
                          value?.title || 'No Phase'
                      )
                  },
              },
          ]),
    {
        id: 'phaseStartDate',
        label: 'Start Date',
        visible: false,
        type: 'date',
        value: (e) => e.startDate || e.phase.startDate,
    },
    ...(!canViewExpenseTracking()
        ? [
              {
                  id: 'startDate',
                  label: 'Start Date',
                  width: 12,
                  type: 'date',
                  editable: (r) =>
                      canEditProjectExpenses(SessionStore.user, r.project),
                  value: (e) => e.startDate,
                  onChange: (r) => (v) => r.update({ startDate: v }),
              },
              {
                  id: 'endDate',
                  label: 'End Date',
                  width: 12,
                  type: 'date',
                  editable: (r) =>
                      canEditProjectExpenses(SessionStore.user, r.project),
                  value: (e) => e.endDate,
                  onChange: (r) => (v) => r.update({ endDate: v }),
              },
          ]
        : []),
    {
        id: 'cost',
        label: 'Cost',
        width: canViewExpenseTracking() ? 20 : 12,
        type: 'currency',
        editable: (r) => canEditProjectExpenses(SessionStore.user, r.project),
        value: (e) => e.cost || e.quantity * e.unitCost,
        onChange: (r) => (v) => {
            r.update({ cost: v })
            if (r.phase && r.phase.expenseBudgetLinked) {
                r.phase.updateFromBudgets()
            }
        },
    },
    {
        id: 'billable',
        label: 'Billable',
        width: 8,
        type: 'checkbox',
        editable: (r) => canEditProjectExpenses(SessionStore.user, r.project),
        value: (e) => e.billable,
        onChange: (r) => (v) => {
            r.update({ billable: v })
            if (r.phase && r.phase.expenseBudgetLinked) {
                r.phase.updateFromBudgets()
            }
        },
    },
    {
        id: 'invoiced',
        label: 'Invoiced',
        width: 8,
        type: 'checkbox',
        editable: (r) =>
            r.billable &&
            r.cost &&
            r.remainingCost > 0 &&
            canEditProjectExpenses(SessionStore.user, r.project),
        value: (e) =>
            e.beenInvoiced || (e.billable && e.cost && e.remainingCost <= 0),
        onChange: (r) => (v) => r.update({ beenInvoiced: v }),
    },
    {
        id: 'button',
        label: '',
        width: 4,
        type: 'button',
        editable: (r) => canEditProjectExpenses(SessionStore.user, r.project),
        permissions: (r) =>
            canEditProjectExpenses(SessionStore.user, r.project),
        value: (p) => <i className="fa fa-times" style={{ marginRight: 0 }} />,
        onClick: (r) => () => {
            r.update({ deletedAt: new Date() })
        },
    },
    {
        id: 'createdAt',
        label: 'Created At',
        width: 18,
        type: 'number',
        editable: (r) => false,
        value: (e) => (e.createdAt || e.initiatedAt).getTime(),
        visible: false,
    },
]

const phaseBudgetColumns = (store) => [
    {
        label: '',
        width: 3,
        type: 'text',
        value: (r) => '',
        onChange: (r) => (v) => null,
        component: (props) => null,
    },
    {
        id: 'staffOrRole',
        label: 'Staff Member / Role',
        width: 27,
        type: 'staffOrRole',
        editable: (r) =>
            r instanceof BudgetModel &&
            canEditProjectStaffBudgets(SessionStore.user, r.project),
        value: (r) => r.resource,
        onChange: (r) => (v) => {
            if (v.modelType === 'staff') {
                r.update({ staffId: v.id, roleId: null })
            } else if (v.modelType === 'role') {
                r.update({ roleId: v.id, staffId: null })
            }
        },
        component: (props) => {
            const isBudget = props.stores.row.rowObject instanceof BudgetModel
            if (isBudget) {
                return CellComponent.staffOrRole(props)
            } else {
                return null
            }
        },
    },
    {
        label: '',
        width: 38,
        type: 'text',
        value: (r) => '',
        onChange: (r) => (v) => null,
        component: (props) => {
            const isBudget = props.stores.row.rowObject instanceof BudgetModel
            if (isBudget) {
                return null
            } else {
                return (
                    <div style={{ textAlign: 'right', width: '100%' }}>
                        {'Totals'}
                    </div>
                )
            }
        },
    },
    {
        id: 'chargeOut',
        label: 'Charge Out',
        width: 10,
        type: 'currency',
        editable: (r) =>
            r instanceof BudgetModel &&
            canEditProjectStaffBudgets(SessionStore.user, r.project) &&
            canViewStaffChargeOutRate(SessionStore.user),
        permissions: (r) => canViewStaffChargeOutRate(SessionStore.user),
        value: (r) => store.getBudgetChargeOut(r),
        onChange: (r) => (v) => {
            r.rateValueToHours(v, 'chargeOut')
            r.phase.updateFromBudgets()
        },
    },
    {
        id: 'expense',
        label: 'Expense',
        width: 10,
        type: 'currency',
        editable: (r) =>
            r instanceof BudgetModel &&
            canEditProjectStaffBudgets(SessionStore.user, r.project) &&
            canViewStaffCostRate(SessionStore.user),
        permissions: (r) => canViewStaffCostRate(SessionStore.user),
        value: (r) => store.getBudgetCost(r),
        onChange: (r) => (v) => {
            r.rateValueToHours(v, 'cost')
            r.phase.updateFromBudgets()
        },
    },
    {
        id: 'hours',
        label: 'Hours',
        width: 10,
        type: 'number',
        editable: (r) => {
            return (
                r instanceof BudgetModel &&
                canEditProjectStaffBudgets(SessionStore.user, r.project)
            )
        },
        value: (r) => store.getBudgetHours(r),
        onChange: (r) => (v) => {
            r.update({ hours: v })
            r.phase.updateFromBudgets()
        },
    },
    {
        label: '',
        width: 16,
        type: 'text',
        value: (r) => '',
        onChange: (r) => (v) => null,
        component: (props) => null,
    },
    {
        label: '',
        width: 4,
        type: 'button',
        editable: (r) => true,
        permissions: (r) => canEditPhasesAndTasks(SessionStore.user, r),
        value: (p) => <i className="fa fa-times" style={{ marginRight: 0 }} />,
        onClick: (r) => () => {
            r.update({ deletedAt: new Date() })
        },
        component: (props) => {
            const isBudget = props.stores.row.rowObject instanceof BudgetModel
            if (isBudget) {
                return CellComponent.button(props)
            } else {
                return null
            }
        },
    },
    {
        id: 'createdAt',
        label: 'Created At',
        width: 18,
        type: 'number',
        editable: (r) => false,
        value: (e) => (e.createdAt || e.initiatedAt).getTime(),
        visible: false,
    },
]

const phaseBudgetLinkColumns = (store) => [
    {
        label: '',
        width: 68,
        type: 'text',
        value: (r) => '',
        onChange: (r) => (v) => null,
    },
    {
        id: 'linkChargeOut',
        label: 'Charge Out',
        width: 10,
        type: 'phase',
        value: (r) => r,
        component: ({ value, group, stores }) => {
            return (
                <div
                    style={{
                        height: '2em',
                        paddingLeft: '3em',
                        fontSize: '0.9em',
                    }}
                >
                    <VerticalLink
                        linked={value.feeLinked}
                        onOverUp={() => store.setFeeLinkDirection('up', value)}
                        onOverDown={() =>
                            store.setFeeLinkDirection('down', value)
                        }
                        onOut={() => store.setFeeLinkDirection(null, null)}
                        onLinkUp={() => {
                            value.update({ feeLinked: true })
                            value.updateBudgets('fee')
                        }}
                        onLinkDown={() => {
                            value.update({ feeLinked: true })
                            value.updateFromBudgets()
                        }}
                        onUnlink={() => value.update({ feeLinked: false })}
                    />
                </div>
            )
        },
    },
    {
        id: 'linkExpense',
        label: 'Expense',
        width: 10,
        type: 'phase',
        value: (r) => r,
        component: ({ value, group, stores }) => {
            return (
                <div
                    style={{
                        height: '2em',
                        paddingLeft: '3em',
                        fontSize: '0.9em',
                    }}
                >
                    <VerticalLink
                        linked={value.expenseBudgetLinked}
                        onOverUp={() =>
                            store.setExpenseLinkDirection('up', value)
                        }
                        onOverDown={() =>
                            store.setExpenseLinkDirection('down', value)
                        }
                        onOut={() => store.setExpenseLinkDirection(null, null)}
                        onLinkUp={() => {
                            value.update({ expenseBudgetLinked: true })
                            value.updateBudgets('expenseBudget')
                        }}
                        onLinkDown={() => {
                            value.update({ expenseBudgetLinked: true })
                            value.updateFromBudgets()
                        }}
                        onUnlink={() =>
                            value.update({ expenseBudgetLinked: false })
                        }
                    />
                </div>
            )
        },
    },
    {
        id: 'linkHours',
        label: 'Hours',
        width: 10,
        type: 'phase',
        value: (r) => r,
        component: ({ value, group, stores }) => {
            return (
                <div
                    style={{
                        height: '2em',
                        paddingLeft: '3em',
                        fontSize: '0.9em',
                    }}
                >
                    <VerticalLink
                        linked={value.hoursBudgetLinked}
                        onOverUp={() =>
                            store.setHoursLinkDirection('up', value)
                        }
                        onOverDown={() =>
                            store.setHoursLinkDirection('down', value)
                        }
                        onOut={() => store.setHoursLinkDirection(null, null)}
                        onLinkUp={() => {
                            value.update({ hoursBudgetLinked: true })
                            value.updateBudgets('hoursBudget')
                        }}
                        onLinkDown={() => {
                            value.update({ hoursBudgetLinked: true })
                            value.updateFromBudgets()
                        }}
                        onUnlink={() =>
                            value.update({ hoursBudgetLinked: false })
                        }
                    />
                </div>
            )
        },
    },
    {
        label: '',
        width: 20,
        type: 'text',
        value: (r) => '',
        onChange: (r) => (v) => null,
    },
]

const phaseBudgetAddColumns = [
    {
        label: '',
        width: 3,
        type: 'text',
        value: (r) => '',
        onChange: (r) => (v) => null,
    },
    {
        id: 'add',
        label: 'Add Resource',
        width: 27,
        type: 'phase',
        value: (r) => r,
        component: ({ value, group, stores }) => (
            <button
                className="btn btn-default"
                style={{
                    margin: '0 2em',
                    fontSize: '0.9em',
                    padding: '0.3em 3em',
                    backgroundColor: '#fcfcfc',
                }}
                onClick={() => {
                    const b = BudgetCollection.add(
                        { projectId: value.projectId, phaseId: value.id },
                        { trackUpdates: true }
                    )
                    b.position = value.budgets.length
                }}
            >
                + Add Resource
            </button>
        ),
    },
    {
        label: '',
        width: 88,
        type: 'text',
        value: (r) => '',
        onChange: (r) => (v) => null,
    },
]

const expensesAddColumns = [
    {
        label: '',
        width: 3,
        type: 'text',
        value: (r) => '',
        onChange: (r) => (v) => null,
    },
    {
        id: 'add',
        label: 'Add Expense',
        width: 27,
        type: 'phase',
        value: (r) => r,
        component: ({ value, group, stores }) => {
            return (
                <button
                    className="btn btn-default "
                    style={{
                        margin: '0 2em',
                        fontSize: '0.9em',
                        padding: '0.3em 3em',
                        backgroundColor: '#fcfcfc',
                        border: '1px solid #ccc',
                    }}
                    onClick={() => {
                        const e = ProjectExpenseCollection.add(
                            {
                                projectId: value.projectId,
                                phaseId: value.id,
                                name: '',
                            },
                            { trackUpdates: true }
                        )
                        e.isNew = true
                        e.position = value.expenses.length
                    }}
                >
                    + Add Expense
                </button>
            )
        },
    },
    {
        label: '',
        width: 88,
        type: 'text',
        value: (r) => '',
        onChange: (r) => (v) => null,
    },
]
class ProjectDetailsStore {
    @observable feeLinkDirection = null
    @observable expenseLinkDirection = null
    @observable hoursLinkDirection = null
    @observable feeLinkPhase = null
    @observable expenseLinkPhase = null
    @observable hoursLinkPhase = null
    constructor() {
        makeObservable(this)
    }
    @action.bound
    setFeeLinkDirection(d, phase) {
        this.feeLinkDirection = d
        this.feeLinkPhase = phase
    }
    @action.bound
    setExpenseLinkDirection(d, phase) {
        this.expenseLinkDirection = d
        this.expenseLinkPhase = phase
    }
    @action.bound
    setHoursLinkDirection(d, phase) {
        this.hoursLinkDirection = d
        this.hoursLinkPhase = phase
    }
    getPhaseFee(phase) {
        if (this.feeLinkPhase !== phase || this.feeLinkDirection === 'up') {
            return phase.fee
        } else {
            return _.sum(phase.budgets.map((b) => b.chargeOut))
        }
    }
    getPhaseExpenseBudget(phase) {
        if (
            this.expenseLinkPhase !== phase ||
            this.expenseLinkDirection === 'up'
        ) {
            return phase.expenseBudget
        } else {
            return (
                _.sum(phase.budgets.map((b) => b.cost)) +
                _.sum(
                    phase.expenses.filter((e) => !e.billable).map((e) => e.cost)
                )
            )
        }
    }
    getPhaseHoursBudget(phase) {
        if (this.hoursLinkPhase !== phase || this.hoursLinkDirection === 'up') {
            return phase.hoursBudget
        } else {
            return _.sum(phase.budgets.map((b) => b.hours))
        }
    }
    @computed
    get adjustBudgets() {
        return [
            this.feeLinkDirection,
            this.expenseLinkDirection,
            this.hoursLinkDirection,
        ].includes('up')
    }
    getBudgetRatio(budget) {
        const phase = budget.phase
        if (this.feeLinkDirection && this.feeLinkPhase === phase) {
            return (
                phase.fee /
                (_.sum(phase.budgets.map((b) => b.chargeOut)) ||
                    _.sum(phase.budgets.map((b) => b.chargeOutRate)))
            )
        } else if (
            this.expenseLinkDirection &&
            this.expenseLinkPhase === phase
        ) {
            return (
                (phase.expenseBudget -
                    _.sum(
                        phase.expenses
                            .filter((e) => !e.billable)
                            .map((e) => e.cost)
                    )) /
                (_.sum(phase.budgets.map((b) => b.cost)) ||
                    _.sum(phase.budgets.map((b) => b.costRate)))
            )
        } else if (this.hoursLinkDirection && this.hoursLinkPhase === phase) {
            return (
                phase.hoursBudget /
                (_.sum(phase.budgets.map((b) => b.hours)) ||
                    phase.budgets.length)
            )
        } else {
            return 1
        }
    }
    getBudgetChargeOut(budget) {
        if (!this.adjustBudgets) {
            return budget.chargeOut
        } else {
            return (
                this.getBudgetRatio(budget) *
                (budget.chargeOut || budget.chargeOutRate)
            )
        }
    }
    getBudgetCost(budget) {
        if (!this.adjustBudgets) {
            return budget.cost
        } else {
            return (
                this.getBudgetRatio(budget) * (budget.cost || budget.costRate)
            )
        }
    }
    getBudgetHours(budget) {
        if (!this.adjustBudgets) {
            return budget.hours
        } else {
            return this.getBudgetRatio(budget) * (budget.hours || 1)
        }
    }
}

export default observer(({ id }) => {
    const [store, setStore] = useState(new ProjectDetailsStore())
    const project = ProjectCollection.projectsById[id]
    const [projectExpenses, setProjectExpenses] = useState([
        ...(project?.expenses.sort((a, b) => {
            // Sort by e.startDate
            const startDateComparison = a?.startDate - b?.startDate
            if (startDateComparison !== 0) {
                return startDateComparison
            }

            // If startDate is the same, sort by e.phase.startDate
            const phaseStartDateComparison =
                a?.phase?.startDate - b?.phase?.startDate
            if (phaseStartDateComparison !== 0) {
                return phaseStartDateComparison
            }

            // If both startDate and phase.startDate are the same, sort by e.name
            return (a.name || '')?.localeCompare?.(b.name || '')
        }) || []),
    ])
    useEffect(() => {
        setProjectExpenses([
            ...(project?.expenses?.sort((a, b) => {
                // Sort by e.startDate
                const startDateComparison = a?.startDate - b?.startDate
                if (startDateComparison !== 0) {
                    return startDateComparison
                }

                // If startDate is the same, sort by e.phase.startDate
                const phaseStartDateComparison =
                    a?.phase?.startDate - b?.phase?.startDate
                if (phaseStartDateComparison !== 0) {
                    return phaseStartDateComparison
                }

                // If both startDate and phase.startDate are the same, sort by e.name
                return (a.name || '')?.localeCompare?.(b.name || '')
            }) || []),
        ])
    }, [project?.id])
    const [projectPhases, setProjectPhases] = useState([
        ...(project?.phases
            ?.filter((ph) => !ph?.isRootPhase)
            ?.sort(sortPhases) || []),
    ])
    useEffect(() => {
        setProjectPhases([
            ...(project?.phases
                ?.filter((ph) => !ph?.isRootPhase)
                ?.sort(sortPhases) || []),
        ])
    }, [project?.id])
    const isAtLeastPM = true
    const isAtLeastAdmin = true
    if (!project) {
        return <div> Project not Found...</div>
    }
    if (!canViewProjectDetails(SessionStore.user, project)) {
        return <div>You don't have permissions to view this project.</div>
    }
    // useEffect(() => {
    //     project.expenses.forEach((pe, i) =>
    //         pe.update({ initiatedAt: new Date(pe.initiatedAt.getTime() + i) })
    //     )
    // }, [])
    return (
        <div>
            <div>
                <div className="flex justify-between items-end">
                    <h3
                        style={{
                            fontSize: '2.2em',
                            display: 'inline-block',
                        }}
                    >
                        Project Details
                    </h3>
                    <div>
                        <div
                            className="flex flex-space-between flex-align-items-center"
                            style={{ margin: '1em' }}
                        >
                            {canViewContacts(SessionStore.user) ? (
                                <div
                                    style={{
                                        display: 'inline-block',
                                        margin: '0 1em',
                                    }}
                                >
                                    <div style={{ fontWeight: 'bold' }}>
                                        Client
                                    </div>
                                    {canEditProject(
                                        SessionStore.user,
                                        project
                                    ) ? (
                                        <ContactSelector
                                            onChange={(contact) =>
                                                project.update({
                                                    contactId: contact.id,
                                                })
                                            }
                                            selectedContact={project.contact}
                                            variant="secondary"
                                        />
                                    ) : (
                                        <span>
                                            {project.contact != null
                                                ? project.contact.label
                                                : '(No contact)'}
                                        </span>
                                    )}
                                </div>
                            ) : null}
                            {canViewPrimaryContact() ? (
                                <div
                                    style={{
                                        display: 'inline-block',
                                        margin: '0 1em',
                                    }}
                                >
                                    <div style={{ fontWeight: 'bold' }}>
                                        Primary Contact
                                    </div>
                                    {canEditProject(
                                        SessionStore.user,
                                        project
                                    ) ? (
                                        <ContactSelector
                                            selectedContact={
                                                project.primaryContact
                                            }
                                            onChange={(contact) =>
                                                project.update({
                                                    primaryContactId:
                                                        contact.id,
                                                })
                                            }
                                            variant="secondary"
                                        />
                                    ) : (
                                        <span>
                                            {project.invoiceContact != null
                                                ? project.invoiceContact
                                                      .displayName
                                                : '(No contact)'}
                                        </span>
                                    )}
                                </div>
                            ) : null}
                            {canViewCostCentres(SessionStore.user) ? (
                                <div
                                    style={{
                                        display: 'inline-block',
                                        margin: '0 1em',
                                    }}
                                    className="project-form__cost-centre-select"
                                >
                                    <div style={{ fontWeight: 'bold' }}>
                                        Cost Centre
                                    </div>
                                    {canEditProject(
                                        SessionStore.user,
                                        project
                                    ) ? (
                                        <CostCentreSelector
                                            selectedCostCentre={
                                                project.costCentre
                                            }
                                            onChange={(costCentre) =>
                                                project.update({
                                                    costCentreId: costCentre.id,
                                                })
                                            }
                                            isEditable={isAtLeastAdmin}
                                            dropLeft={true}
                                            variant="secondary"
                                        />
                                    ) : (
                                        <span>
                                            {project.costCentre != null
                                                ? project.costCentre.name
                                                : '(No Cost Centre)'}
                                        </span>
                                    )}
                                </div>
                            ) : null}
                            {canViewRevenueTargets(
                                SessionStore.user,
                                project
                            ) ? (
                                <div
                                    style={{
                                        display: 'inline-block',
                                        margin: '0 1em',
                                    }}
                                >
                                    <div style={{ fontWeight: 'bold' }}>
                                        Bill on
                                    </div>
                                    {canEditRevenueTargets(
                                        SessionStore.user,
                                        project
                                    ) ? (
                                        <Selector
                                            isEditable={isAtLeastAdmin}
                                            options={[
                                                {
                                                    label: 'End of Each Month',
                                                    value: 'monthly',
                                                },
                                                {
                                                    label: 'End of Phase',
                                                    value: 'manual',
                                                },
                                            ]}
                                            value={project.milestoneType}
                                            onChange={(milestoneType) =>
                                                project.update({
                                                    milestoneType,
                                                })
                                            }
                                            dropLeft={true}
                                            variant="secondary"
                                        />
                                    ) : (
                                        <span>
                                            {
                                                {
                                                    monthly:
                                                        'End of Each Month',
                                                    manual: 'End of Phase',
                                                }[project.milestoneType]
                                            }
                                        </span>
                                    )}
                                </div>
                            ) : null}
                            <div
                                style={{
                                    display: 'inline-block',
                                    margin: '0 1em',
                                }}
                            >
                                <div style={{ fontWeight: 'bold' }}>Owner</div>
                                {canEditProject(SessionStore.user, project) ? (
                                    <StaffSelector
                                        selectedStaffMember={project.owner}
                                        onChange={(staffMember) =>
                                            project.update({
                                                ownerId: staffMember.id,
                                            })
                                        }
                                        placeholderLabel="Select Project Owner..."
                                        dropLeft={true}
                                        variant="secondary"
                                    />
                                ) : (
                                    <span>
                                        {project.owner != null
                                            ? project.owner.fullName
                                            : '(No Owner)'}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Table
                    id="project"
                    showHeader={true}
                    columns={projectColumns}
                    rows={[project]}
                />
            </div>
            <div>
                <h4
                    style={{
                        fontSize: '1.8em',
                        margin: '1em 0 0.5em 0.5em',
                        display: 'inline-block',
                    }}
                >
                    Phases
                </h4>
                <Table
                    id="project-phases"
                    showHeader={true}
                    columns={phaseColumns(store)}
                    rows={projectPhases.filter((ph) => !ph.deletedAt)}
                    getChildComponent={(r) =>
                        canViewExpenseTracking() ? (
                            <PhaseDetails phase={r} store={store} />
                        ) : (
                            <PhaseBudgets phase={r} store={store} />
                        )
                    }
                />
                {canEditPhasesAndTasks(SessionStore.user, project) ? (
                    <button
                        className="btn btn-default plus-btn"
                        style={{ marginTop: '2em', border: '1px solid #ccc' }}
                        onClick={() => {
                            const ph = PhaseCollection.createPhase(project.id)
                            ph.isNew = true
                            setProjectPhases([...projectPhases, ph])
                        }}
                    >
                        + Add Phase
                    </button>
                ) : null}
            </div>
            {canViewProjectExpenses(SessionStore.user, project) ? (
                <div>
                    <h4
                        style={{
                            fontSize: '1.8em',
                            margin: '1em 0 0.5em 0.5em',
                            display: 'inline-block',
                        }}
                    >
                        {canViewExpenseTracking()
                            ? 'General Expenses'
                            : 'Expenses'}
                    </h4>
                    <Table
                        id="expenses"
                        showHeader={true}
                        columns={expenseColumns()}
                        rows={projectExpenses.filter(
                            (e) =>
                                !e.deletedAt &&
                                (!canViewExpenseTracking() ||
                                    !e.phaseId ||
                                    e.phase?.isRootPhase)
                        )}
                        // sortBy={[['createdAt', 'asc']]}
                    />
                    {canEditProjectExpenses(SessionStore.user, project) ? (
                        <button
                            className="btn btn-default plus-btn"
                            style={{
                                marginTop: '2em',
                                border: '1px solid #ccc',
                            }}
                            onClick={() => {
                                const e = ProjectExpenseCollection.add(
                                    {
                                        projectId: project.id,
                                        phaseId: project.rootPhaseId,
                                        name: '',
                                    },
                                    { trackUpdates: true }
                                )
                                e.isNew = true
                                setProjectExpenses([...projectExpenses, e])
                            }}
                        >
                            + Add Expense
                        </button>
                    ) : null}
                </div>
            ) : null}
        </div>
    )
})

const PhaseBudgets = observer(({ phase, store }) => {
    const project = phase.project
    return (
        <>
            {canEditProjectStaffBudgets(SessionStore.user, project) ? (
                <Table
                    id="budget-links"
                    showHeader={false}
                    columns={phaseBudgetLinkColumns(store)}
                    rows={[phase]}
                    className="coincraft-table-sub-section"
                    style={{ borderBottom: 'none', backgroundColor: '#f7f7f7' }}
                />
            ) : null}
            <Table
                id="budgets"
                showHeader={true}
                columns={phaseBudgetColumns(store)}
                rows={phase.budgets.sort((a, b) => {
                    if (a.resource && b.resource) {
                        return (
                            a.resource?.fullName ||
                            a?.resource?.name ||
                            ''
                        )?.localeCompare?.(
                            b.resource?.fullName || b.resource?.name || ''
                        )
                    } else {
                        return 0
                    }
                })}
                className="coincraft-table-sub-section"
                style={{
                    boxShadow: 'none',
                    border: 'solid 1px #ddd',
                    backgroundColor: '#f7f7f7',
                }}
                headerStyle={{
                    fontSize: '0.9em',
                    fontWeight: 'bold',
                    backgroundColor: '#f7f7f7',
                    borderBottom: 'solid 1px #ddd',
                }}
                showTotals={true}
                totalsRowStyles={{
                    fontSize: '1.1rem',
                    fontStyle: 'italic',
                    // textDecoration: 'underline',
                }}
                totalsCellStyles={{ padding: '0.5rem' }}
            />
            {canEditProjectStaffBudgets(SessionStore.user, project) ? (
                <Table
                    id="budget-button"
                    showHeader={false}
                    columns={phaseBudgetAddColumns}
                    rows={[phase]}
                    className="coincraft-table-sub-section"
                    style={{
                        borderTop: 'none',
                        boxShadow: 'none',
                        backgroundColor: '#f7f7f7',
                    }}
                />
            ) : null}
        </>
    )
})

const VerticalLink = observer(
    ({
        onOverUp,
        onOverDown,
        onOut,
        onLinkUp,
        onLinkDown,
        onUnlink,
        linked,
    }) => {
        const target = React.useRef(null)
        const isHovering = useHover(target)
        const mouse = useMouse(target)
        const hoverPosition =
            isHovering && mouse.y / mouse.elementHeight < 0.5 ? 'up' : 'down'
        const [latestHoverCall, setLatestHoverCall] = useState(null)
        return (
            <div style={{ position: 'relative' }}>
                <div
                    ref={target}
                    onPointerMove={() => {
                        if (
                            !linked &&
                            hoverPosition === 'up' &&
                            latestHoverCall !== 'up'
                        ) {
                            onOverUp()
                            setLatestHoverCall('up')
                        } else if (
                            !linked &&
                            hoverPosition === 'down' &&
                            latestHoverCall !== 'down'
                        ) {
                            onOverDown()
                            setLatestHoverCall('down')
                        }
                    }}
                    onPointerLeave={() => {
                        if (!linked) {
                            onOut()
                            setLatestHoverCall(null)
                        }
                    }}
                    onClick={() => {
                        if (!linked && hoverPosition === 'up') {
                            onLinkUp()
                            setLatestHoverCall(null)
                        } else if (!linked && hoverPosition === 'down') {
                            onLinkDown()
                            setLatestHoverCall(null)
                        } else if (linked) {
                            onUnlink()
                        }
                    }}
                    style={{
                        display: 'inline-block',
                        border: linked ? 'solid 1px #999' : 'solid 1px #ccc',
                        backgroundColor:
                            (linked && !isHovering) || (!linked && isHovering)
                                ? '#e2e2e2'
                                : 'white',
                        boxShadow:
                            linked || (!linked && isHovering)
                                ? 'rgba(0, 0, 0, 0.2) 1px 1px 1px 0px inset'
                                : 'inset rgba(0, 0, 0, 0.2) -0.5px -0.5px 1px 0px',
                    }}
                >
                    <i className="fa fa-link rotate-45" style={{ margin: 0 }} />
                </div>
                {isHovering ? (
                    <>
                        <div
                            style={{
                                position: 'absolute',
                                left: '2em',
                                top: 0,
                                color: '#666',
                                fontWeight: 600,
                                lineHeight: '1em',
                                width: '5em',
                                fontSize: '0.9em',
                            }}
                        >
                            {linked
                                ? 'Unlink'
                                : hoverPosition === 'up'
                                  ? 'Link to Phase'
                                  : 'Link to Resources'}
                        </div>
                        {!linked ? (
                            <div
                                style={{
                                    position: 'absolute',
                                    right: '100%',
                                    top: '25%',
                                    textAlign: 'center',
                                    height: '50%',
                                    paddingRight: '0.5em',
                                    fontSize: '0.9em',
                                }}
                            >
                                {hoverPosition === 'up' ? (
                                    <i
                                        className="fa fa-chevron-up"
                                        style={{ margin: 0, display: 'block' }}
                                    />
                                ) : (
                                    <i
                                        className="fa fa-chevron-down"
                                        style={{ margin: 0, display: 'block' }}
                                    />
                                )}
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>
        )
    }
)

const PhaseDetails = observer(({ phase, store }) => {
    const project = phase.project
    return (
        <div
            style={{
                backgroundColor: '#f7f7f7',
                border: 'solid 1px #ddd',
            }}
        >
            {canEditProjectStaffBudgets(SessionStore.user, project) ? (
                <Table
                    id="budget-links"
                    showHeader={false}
                    columns={phaseBudgetLinkColumns(store)}
                    rows={[phase]}
                    className="coincraft-table-sub-section"
                    style={{ borderBottom: 'none', backgroundColor: '#f7f7f7' }}
                />
            ) : null}
            <Table
                id="budgets"
                showHeader={true}
                columns={phaseBudgetColumns(store)}
                rows={phase.budgets.sort((a, b) => {
                    if (a.resource && b.resource) {
                        const aFullName =
                            a.resource.fullName || a.resource.name || ''
                        const bFullName =
                            b.resource.fullName || b.resource.name || ''
                        return aFullName.localeCompare(bFullName)
                    } else {
                        return 0
                    }
                })}
                className="coincraft-table-sub-section"
                sortBy={[['createdAt', 'asc']]}
                style={{
                    boxShadow: 'none',
                    border: 'solid 1px #ddd',
                    backgroundColor: '#f7f7f7',
                }}
                headerStyle={{
                    fontSize: '0.9em',
                    fontWeight: 'bold',
                    backgroundColor: '#f7f7f7',
                    borderBottom: 'solid 1px #ddd',
                }}
                showTotals={true}
                totalsRowStyles={{
                    fontSize: '1.1rem',
                    fontStyle: 'italic',
                    // textDecoration: 'underline',
                }}
                totalsCellStyles={{ padding: '0.5rem' }}
            />
            {canEditProjectStaffBudgets(SessionStore.user, project) ? (
                <Table
                    id="budget-button"
                    showHeader={false}
                    columns={phaseBudgetAddColumns}
                    rows={[phase]}
                    className="coincraft-table-sub-section"
                    style={{
                        border: 'solid 1px #ddd',
                        boxShadow: 'none',
                        backgroundColor: '#f7f7f7',
                    }}
                />
            ) : null}
            <Table
                id="expenses"
                showHeader={true}
                columns={expenseColumns(store)}
                rows={phase.expenses}
                className="coincraft-table-sub-section"
                sortBy={[['createdAt', 'asc']]}
                style={{
                    boxShadow: 'none',
                    border: 'solid 1px #ddd',
                    backgroundColor: '#f7f7f7',
                    marginTop: '1.5em',
                    borderTop: 'none',
                }}
                headerStyle={{
                    fontSize: '0.9em',
                    fontWeight: 'bold',
                    backgroundColor: '#f7f7f7',
                    borderBottom: 'solid 1px #ddd',
                }}
                // showTotals={true}
                // totalsRowStyles={{
                //     fontSize: '1.1rem',
                //     fontStyle: 'italic',
                //     // textDecoration: 'underline',
                // }}
                // totalsCellStyles={{ padding: '0.5rem' }}
            />
            {canEditProjectStaffBudgets(SessionStore.user, project) ? (
                <Table
                    id="budget-button"
                    showHeader={false}
                    columns={expensesAddColumns}
                    rows={[phase]}
                    className="coincraft-table-sub-section"
                    style={{
                        borderTop: 'none',
                        boxShadow: 'none',
                        backgroundColor: '#f7f7f7',
                    }}
                />
            ) : null}
        </div>
    )
})
