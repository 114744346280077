import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import SessionStore from '../../State/SessionStore'
import { differenceInDays, differenceInMonths, format } from 'date-fns'
import posthog from 'posthog-js'
import { FormatDate } from '../../Utils/Localisation/DateFormatter'
import {
    canViewRoute,
    isMasterAdmin,
} from '../../State/Permissions/HasPermissions'
import { makeRequest } from '../../Queries/makeRequest'
import { useNavigate } from '@tanstack/react-router'

export default observer(() => {
    const navigate = useNavigate()
    const subscription = SessionStore.subscription
    const [hasMigrated, setHasMigrated] = useState(true)
    if (!subscription) return null
    useEffect(() => {
        makeRequest({
            path: process.env.REACT_APP_NODE_SERVER_URL + '/flags/has-flag',
            method: 'POST',
            data: {
                organisationId: SessionStore.organisation.id,
                email: SessionStore.user?.email,
                flag: 'has-migrated',
            },
        }).then((res) => {
            setHasMigrated(res.data)
        })
    }, [])
    if (subscription.hasTrialExpired) {
        return (
            <Banner>
                Your trial has expired. If you wish to continue using Coincraft
                please subscribe below.
            </Banner>
        )
    }
    if (
        !subscription.hasSubscription &&
        subscription.trialEndDate > new Date()
    ) {
        return (
            <Banner>
                Your Trial Ends {dateToString(subscription.trialEndDate)}
                <BannerButton onClick={() => navigate({ to: '/billing' })}>
                    Click Here to Subscribe!
                </BannerButton>
            </Banner>
        )
    }
    if (
        subscription.hasCreditCardExpired &&
        canViewRoute(SessionStore.user, 'billing')
    ) {
        return (
            <Banner>
                Your card has expired{' '}
                <BannerButton onClick={() => navigate({ to: '/billing' })}>
                    Click Here to Update!
                </BannerButton>
            </Banner>
        )
    }
    if (
        subscription.hasOverdueInvoices &&
        canViewRoute(SessionStore.user, 'billing')
    ) {
        return (
            <Banner>
                You currently have overdue invoices. If you wish to continue
                using Coincraft please pay below.
            </Banner>
        )
    }
    if (
        subscription.hasUnpaidInvoices &&
        canViewRoute(SessionStore.user, 'billing')
    ) {
        return (
            <Banner>
                You currently have unpaid invoices due{' '}
                {dateToString(subscription.earliestUnpaidInvoiceDate)}
                <BannerButton onClick={() => navigate({ to: '/billing' })}>
                    Pay Here!
                </BannerButton>
            </Banner>
        )
    }
    // if (
    //     subscription.willCreditCardExpireInLessThanAMonth &&
    //     canViewRoute(SessionStore.user, 'billing')
    // ) {
    //     return (
    //         <Banner>
    //             Your card will expire {dateToString(subscription.cardExpDate)}
    //             <BannerButton onClick={() => navigate({ to: '/billing' })}>
    //                 Click Here to Update!
    //             </BannerButton>
    //         </Banner>
    //     )
    // }
    return null
})

export const UpdateBanner = observer(() => {
    return SessionStore.updateAvailable ? (
        <Banner style={{ background: '#0000ff', color: '#fff' }}>
            <div style={{ padding: '0.5em 0 0' }}>
                An Update for Coincraft is available.{' '}
                <BannerButton
                    style={{ background: '#fff', color: '#0000ff' }}
                    onClick={() => {
                        let url = window.location.href
                        url += `${
                            url.indexOf('?') > -1 ? '&' : '?'
                        }ts=${Date.now()}`
                        window.location.href = url
                    }}
                >
                    Get Latest Improvements!
                </BannerButton>
            </div>
        </Banner>
    ) : null
})

const Banner = observer(({ children, style }) => {
    return (
        <div
            className="dont-print dont-print text-center bg-[#f15a29] text-[#444] font-semibold text-[0.9em] shadow-[inset_0px_2px_8px_1px_rgba(0,0,0,0.2)]"
            style={{ padding: '0.5em', ...style }}
        >
            {children}
        </div>
    )
})

const BannerButton = observer(({ children, onClick, style }) => {
    return (
        <div
            className="inline-block mx-[1em] text-[0.95em] pt-[0.2em] pb-[0.1em] px-[1em] text-white bg-[#444] rounded"
            style={{ margin: '0 1em', ...style }}
            onClick={onClick}
        >
            {children}
        </div>
    )
})

function dateToString(m) {
    const n = differenceInDays(m, new Date())
    if (n === 0) {
        return 'Today'
    } else if (n === 1) {
        return 'Tomorrow'
    } else {
        return `in ${n} Days`
    }
}
